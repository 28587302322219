<template>
    <div class="chooser">
        <div class="body" v-if="display">
            <div class="cols">
                <router-link :to="backRoute" class="back"><i class="fas fa-arrow-left"></i></router-link>
                <div class="inner">
                    <div class="header">
                        <el-logo text="Student Login"></el-logo>
                        <products></products>
                    </div>
                    <h1 class="ul-title cols">
                        <span>Select your product:</span>
                        <span 
                            class="font-weight-light grey--text text--darken-3"
                            v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}" 
                            content="Your Name"
                            ref="yourName"
                        >                            
                            {{studentName}}
                        </span>
                    </h1>
                    <v-layout class="product-list" v-if="!doesNotHaveProduct && products.length">
                        <button v-for="productId in products" class="text-xs-center radius-circle" :key="productId" @click="selectProduct(productId)">
                                <span class="product"  :class="{'preferred':isPreferredProduct(productId)}">                                    
                                        <span class="product-inner">
                                            <img :src="productImgPath(productId)" :alt="productDisplayName(productId)+' Logo'"/>
                                            <span class="product-name">{{productSettings[productId].displayName}}</span>
                                        </span>                                    
                                </span>
                        </button>
                    </v-layout>
                    <v-flex v-else class="text-xs-center mb-5">
                        <h1 class="ul-title">
                            <span>Sorry, we are unable to log you in right now...</span>
                        </h1>
                        <p>Please ask your teacher to set an assignment for you.</p>
                    </v-flex>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import APIs from 'mixins/APIs'
    import elLogo from 'components/login/EL-Logo'
    import EnvironmentDetector from 'mixins/EnvironmentDetector'
    import Products from 'components/login/Products'
    import ProductInfo from 'mixins/ProductInfo'
	export default {
		name: 'SelectStudent',
		metaInfo : {
			title : 'Product'
		},
		components: { elLogo, Products },
        mixins: [ APIs, EnvironmentDetector, ProductInfo ],
        data(){
            return {
                backRoute: {name: 'ChooseStudent'},
                display: false,
                doesNotHaveProduct: false
            }
        },
		computed: {
			studentName(){
                return this.$store.state.studentLogin.student.name
			},
            products(){
                let products = []
                if(this.$store.state.studentLogin.student.licenses){
                    this.productDisplayOrder.forEach(prodId=>{
                        if(this.$store.state.studentLogin.student.licenses.includes(prodId)){
                            products.push(prodId)
                        }
                    })
                }
                return products
            }
		},
		methods: {
            isPreferredProduct(productId){
                return this.productSettings[productId].environment==this.currentEnvironment
            },
			selectProduct : function(productId){
                this.$store.commit('setProductId', productId)
                let environment = this.productEviroment(productId)
                this.$router.push({ name: 'StudentPassword', params: {environment}})
			},
            UrlFriendlyProductName(productID){
                return this.productEviroment(productID)
            }
		},
		created(){
			if(!this.studentName){
				this.$router.replace(this.backRoute)
			}
            if(this.products.length==1 && this.isPreferredProduct(this.products[0])){
                this.selectProduct(this.products[0])
            } else {
                this.display = true
            }
		},
        mounted(){
            //this is here to reset the tab order for keyboard users
            this.$nextTick(() => {
                this.$refs.yourName.focus();
                this.$refs.yourName.blur();
            });            
        }        
	}
</script>

<style lang="less" scoped>
	.product-list {
        width: 100%;
        display: grid;
        gap: 1.2em;
        grid-auto-columns: 1fr;
        grid-template-columns: repeat( auto-fit, minmax(150px, .33fr) );
        justify-content: center;
    }

    .radius-circle {
        border-radius: 50%;
    }
    .product {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #eee;
        border-radius: 50%;
        cursor: pointer;
        overflow: visible;
        position: relative;
        transition: .2s all;
        img {
            height: 55%;
            max-height: 200px;
            display: block;
            margin: 0 auto 10px;
        }
        .product-name {
            font-size: 1.11em;;
            font-weight: 600;
            color: #333;
        }
        .product-inner{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding-top: 22%;
        }
        &:hover {
            background: #ddd;
        }
        &:before {
            content: "";
            display: inline-block;
            width: 1px;
            height: 0;
            padding-bottom: calc(100%);
        }
    }

    .radius-circle:focus {
        box-shadow: 0 0 2px 2px #0072B4;
        transition: box-shadow .2s ease;
    }

    .preferred {
        box-shadow: 0 0 2px 2px #F58220;
    }

    .radius-circle:focus-visible {
        .preferred {
            box-shadow: none;   
        }            
    }
</style>
