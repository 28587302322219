<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.regCodeHeader') + ' ' + $tc('classMgmt.' + userType.toLowerCase(), 2)"
        :subHeadline="subHeadline"
        size="540"
        @closeDialogs="closeDialogs()"
    >
        <v-alert type="warning" :value="rosteredInviteWarning" class="mb-0" style="margin-top:-1px;">
            <p class="my-0">
                {{$t('classMgmt.setUpForAutoRostering')}}
            </p>
        </v-alert>
        <v-flex v-if="rosteredInviteWarning && !ssoInviteBypass">
            <v-card-text class="text-xs-center">
                <v-btn
                    color="primary"
                    flat 
                    @click="ssoInviteBypass=true" 
                    class="elevation-0"
                >
                    {{$t('classMgmt.addNonRosteredTeachers')}}
                </v-btn>
            </v-card-text>
        </v-flex>
        <v-flex v-else-if="multiProduct & !displayCode">
            <v-card-text class="pb-0">
                <p>{{$t('classMgmt.selectWhichProductsToInvite')}} {{$tc('classMgmt.' + userType.toLowerCase(), 2).toLowerCase()}} {{$t('classMgmt.to')}}</p>
                <v-select
                    v-model="selectedRegCodeProducts"
                    :items="subscriptions"
                    item-text="productName"
                    item-value="productId"
                    label="Product(s)"
                    :menu-props="{top:true}"
                    multiple
                    chips
                    deletable-chips
                    clearable
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                    color="primary" 
                    @click="displayCode=true"
                    :disabled="!selectedRegCodeProducts.length"
                    class="elevation-0">
                    <v-icon>check</v-icon>&nbsp;{{$t('classMgmt.accept')}}
                </v-btn>
            </v-card-actions>
        </v-flex>
        <v-flex v-else>
            <v-card-text>
                <p v-if="!rosteredInviteWarning">{{$tc('classMgmt.invite',1)}} {{$tc('classMgmt.' + userType.toLowerCase(), 2).toLowerCase()}} {{$t('classMgmt.toCreateANewAccount')}}</p>
                <v-layout wrap>
                    <v-flex xs12 class="subheading font-weight-medium mb-1">
                        <span v-if="userType=='Teachers'">{{productDisplayName(regCodeForSelectedProducts.productID,'+')}}</span> {{$t('classMgmt.invitationLink')}} <span v-if="rosteredInviteWarning">{{$t('classMgmt.forNonRegisteredTeachers')}}</span>
                        <span class="caption grey--text text--darken-1 ml-2">{{$t('classMgmt.clickToCopyLink')}}</span>
                    </v-flex>
                    <v-flex xs12 class="mb-1" style="word-break:break-all;">
                        <a @click="writeToClipboard(regURL(regCodeForSelectedProducts.regCode),$t('classMgmt.clickToCopyLinkMsg'))">
                            {{regURL(regCodeForSelectedProducts.regCode)}}
                        </a>
                    </v-flex>
                    <v-flex xs12 class="caption grey--text text--darken-3">
                        {{$t('classMgmt.expiresOn')}} {{new Date(regCodeForSelectedProducts.expiresOn).toLocaleDateString()}} 
                        &bullet;&nbsp;<a @click="createRegCode(regCodeForSelectedProducts.productID)" class="red--text text--darken-3">{{$t('classMgmt.generateANewLink')}}</a>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-flex v-if="userType=='Administrators' || !multiProduct" class="ml-3">
                    <a :href="helpCenterURL(regCodeForSelectedProducts.productID, userType)" target="_blank">
                        {{$t('classMgmt.needHelp')}}
                    </a>
                </v-flex>
                <v-flex v-else>
                    <v-btn
                        color="error"
                        flat
                        @click="displayCode=false"
                    >
                        <v-icon>cancel</v-icon>&nbsp;{{$t('classMgmt.cancel')}}
                    </v-btn>
                </v-flex>
                <v-spacer></v-spacer>
                <v-btn 
                    color="primary"
                    flat
                    :href="'mailto:'+inviteInstructions(regCodeForSelectedProducts.productID, userType, regURL(regCodeForSelectedProducts.regCode), true)" 
                    class="elevation-0"
                    target="_blank"
                >
                    <v-icon>send</v-icon>&nbsp;{{$t('classMgmt.composeEmail')}}
                </v-btn>
                <v-btn 
                    color="primary" 
                    @click="writeToClipboard(inviteInstructions(regCodeForSelectedProducts.productID, userType, regURL(regCodeForSelectedProducts.regCode), false),$t('classMgmt.copyInstructionsMsg'))" 
                    class="elevation-0">
                    <v-icon>content_copy</v-icon>&nbsp;{{$t('classMgmt.copyInstructions')}}
                </v-btn>
            </v-card-actions>
        </v-flex>
    </UserManagmentModal>
</template>

<script>
    import APIs from 'mixins/APIs'
    import ProductInfo from 'mixins/ProductInfo'
    import UserManagmentModal from 'components/layout/UserManagmentModal'

	export default {
        name: 'RegCodeModal',
        components: { UserManagmentModal },
        mixins: [APIs, ProductInfo],
        props: {
            institutionID: Number,
            multiProduct: Boolean,
            regCodes: Array,
            subHeadline: String,
            subscriptionIds: Array,
            subscriptions: Array,
            teachers: Array,
            userType: String,
            value: Boolean,
        },
        data() {
            return {
                deleteStudents: false,
                displayCode: false,
                regCodeProductID: 0,
                selectedRegCodeProducts: [],
                ssoInviteBypass: false,
            }
        },
		computed : {
            activeRegCodes(){
                let now = new Date()
                return this.regCodes.filter(function(code){
                    let expirationDate = new Date(code.expiresOn)
                    return now<expirationDate && code.isValid && !code.email
                })
            },
            activeRegCodesForAccountType(){
                let regTypeIdMap = {
                    Students : null, 
                    Teachers : 5,
                    Administrators : 8,
                }
                let regTypeID = regTypeIdMap[this.userType]
                if(!regTypeID){
                    return []
                }
                return this.activeRegCodes.filter(function(code){
                    return regTypeID == code.regTypeID
                })
            }, 
            platform(){
                 return this.$store.state.platform
            },
            regCodeForSelectedProducts(){
                let selectedProducts = this.selectedRegCodeProducts
                let codesForSelectedProducts = this.activeRegCodesForAccountType.filter(function(code){
                    return selectedProducts.includes(code.productID)
                }) 
                this.ssoInviteBypass = false
                if(codesForSelectedProducts.length>1){
                    let combinedCode = {
                        productID : [],
                        expiresOn : new Date(codesForSelectedProducts[0].expiresOn),
                        regCode : ''
                    }
                    codesForSelectedProducts.forEach(code=>{
                        combinedCode.productID.push(code.productID)
                        let expirationDate = new Date(code.expiresOn)
                        if(expirationDate<combinedCode.expiresOn){
                            combinedCode.expiresOn = expirationDate
                        }
                        combinedCode.regCode = combinedCode.regCode + ' ' + code.regCode
                    })
                    combinedCode.regCode = combinedCode.regCode.trim()
                    combinedCode.regCode = combinedCode.regCode.replaceAll(' ','-')
                    return combinedCode
                } else if(codesForSelectedProducts.length==0){
                    return {}
                } else {
                    return codesForSelectedProducts[0]
                }
            },
            rosteredInviteWarning(){
                return this.userType=='Teachers' && this.isSubscriptionRostered
            },
            validInviteCodeForAccountType(){
                let regTypeIdMap = {
                    Students : null, 
                    Teachers : 5,
                    Administrators : 8,
                }
                let regTypeID = regTypeIdMap[this.userType]
                if(!regTypeID){
                    return {}
                }
                let regCodeProductID = this.regCodeProductID
                let codesOfCorrectType = this.regCodes.filter(function(code){
                    return regTypeID == code.regTypeID && code.productID == regCodeProductID
                })
                let now = new Date()
                let nonExpireCodes = codesOfCorrectType.filter(function(code){
                    let expirationDate = new Date(code.expiresOn)
                    return now<expirationDate
                })
                let activeCodes = nonExpireCodes.filter(function(code){
                    return code.isActive
                })
                if(activeCodes.length){
                    return activeCodes[0]
                } else {
                    return {}
                }
            }
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs')
            },
            async createRegCode(productId,hideToast){
                if(Array.isArray(productId)){
                    let self = this
                    productId.forEach(pID=>{
                        self.createRegCode(pID,true)
                    })
                    this.$emit('handleSuccess', this.$t('classMgmt.regCodeToast'))
                    return
                }
                let institutionId = this.institutionID
                let subscriptionId = this.getSubscriptionByProductCode(this.subscriptions,productId).id
                let roleIdMap = {
                    Teachers : 3,
                    Administrators : 5
                }
                let roleId = roleIdMap[this.userType]
                let maxUses = 100
                if(roleId == 5){
                    maxUses = 5
                }
                await this.platform.createRegCode({institutionId,subscriptionId,productId,roleId,maxUses})
                let subscriptionIds = this.subscriptionIds
                let createdRegCode = await this.platform.fetchRegCodes({institutionId,subscriptionIds})
                this.$emit('writeToRegCodes', createdRegCode)
                if(!hideToast){
                    this.$emit('handleSuccess', this.$t('classMgmt.regCodeToast'))
                }
            },
            getSubscriptionByProductCode(subscriptions,productID){
                let correctSub = {}
                subscriptions.forEach(sub => {
                    let prodId = sub.productID || sub.productId
                    if(prodId==productID){
                        correctSub = sub
                    }
                })
                return correctSub
            },
            helpCenterURL(productID, userType){
                if(Array.isArray(productID)){
                    productID = productID[0]
                }
                if(productID == 1){
                    if(userType=='Administrators'){
                        return this.getHelpCenterURL('article/Inviting-Additional-Reflex-Administrators')
                    } else if(userType=='Teachers'){
                        return this.getHelpCenterURL('article/Inviting-Teachers-to-Create-Reflex-Accounts')
                    }
                } else if (productID==2){
                    if(userType=='Administrators'){
                        return this.getHelpCenterURL('article/Inviting-Additional-Administrators-to-Create-Frax-Admin-Accounts')
                    } else if(userType=='Teachers'){
                        return getHelpCenterURL('article/Inviting-Teachers-to-Create-Frax-Accounts')
                    }
                } else {
                    return getHelpCenterURL()
                }
            },
            inviteInstructions(productID, userType, registrationURL,emailFormat){
                let product = this.productDisplayName(productID,' and ')
                userType = userType.slice(0,-1)
                let carriageReturn = '\n'
                let supportURL = 'https://accounts.explorelearning.com/el/support'

                let subject = 'Your '+product+' '+userType+' Account'
                let body =  'Welcome to '+product+'!'
                body += carriageReturn + carriageReturn
                body += 'You have been invited to set up your '+userType.toLowerCase()+' account in '+product+'.' 
                body += carriageReturn + carriageReturn
                body += 'To set up your account, click on the link below (or copy and paste it into your web browser):'
                body += carriageReturn + registrationURL
                body += carriageReturn + carriageReturn
                body += 'If you need help, you can contact '+product+' customer support:'
                body += carriageReturn + supportURL
                
                if(emailFormat){
                    return encodeURI('?subject='+subject+'&body='+body)
                } else {
                    return body
                }
            },
            regURL(regCode){
                return window.location.origin + process.env.rootPath + '/el/reg/' + regCode
            },
            writeToClipboard(text, msg){
                navigator.clipboard.writeText(text)
                this.$emit('handleSuccess', msg)
            },
        },
        watch: {
            value(val) {
                if (val) {
                    this.subscriptions.forEach(sub=>{
                        let regCode = this.activeRegCodesForAccountType.find(code=>{
                            return sub.productId == code.productID && Boolean(code.maxUses-code.timesUsed)
                        })
                        if(!regCode){
                            this.createRegCode(sub.productId, true)
                        }
                    })
                    if(this.userType=="Administrators"){
                        this.displayCode = true
                        let allProducts = []
                        this.subscriptions.forEach(sub=>{
                            allProducts.push(sub.productId)
                        })
                        this.selectedRegCodeProducts = allProducts
                    } else if(this.multiProduct){
                        this.displayCode = false
                        this.selectedRegCodeProducts = []
                    } else {
                        this.displayCode = true
                        this.selectedRegCodeProducts = [this.subscriptions[0].productId]
                    }
                }
            }
        }
	}
</script>

<style lang="less" scoped>
    .inlineIcon{
        margin-bottom: -4px;;
    }
</style>