<template>
	<div>
		<form @submit.prevent="submitIssue">
			<v-layout wrap>
				<Headline :logo="isEL?'el':''" :headline="pageTitle"></Headline>
				<Messaging outline></Messaging>
				<v-flex xs12>
					<v-text-field
						v-model="email"
						:label="$t('form.email')"
						v-validate="'required|email|max:100|is_not:user@explorelearning.com'"
						name="email"
						:error-messages="errors.collect('email')">
					</v-text-field>
				</v-flex>
				<v-flex v-if="isEL && !isAdminContact" >
					<v-select
						v-model="productID"
						:items="availableProducts"
						item-text="displayName"
						item-value="id"
						:label="$t('support.product')"
						v-validate="'required'"
						name="product"
						:error-messages="errors.collect('product')"
					>
					</v-select>
				</v-flex>
				<v-flex v-if="!isAdminContact" :xs6="isEL" :xs12="!isEL">
					<v-select
						v-model="inquiryType"
						:items="inquiryTypes"
						item-value="type"
						item-text="label"
						:label="$t('support.inquiryType')"
						v-validate="'required'"
						name='inquiry type'
						:error-messages="errors.collect('inquiry type')"
					>
					</v-select>
				</v-flex>
				<v-flex xs12 id="message" class="my-3 px-4 py-3">
					<v-text-field
						v-model="subject"
						v-if="!isAdminContact"
						:label="$t('support.subject')"
						v-validate="'required|max:200'"
						name="subject"
						:error-messages="errors.collect('subject')"
						:disabled="isAdminContact"
					>
						<template v-slot:append-outer>
							<v-icon large class="grey--text text--lighten-1 ml-2">mail_outline</v-icon>
						</template>
					</v-text-field>
					<p v-if="isAdminContact" class="title">{{ subject }}</p>
					<p v-if="isAdminContact" class="subheading">Let us know who will be in charge of providing teachers and school administrators with their Gizmos accounts.</p>
					<v-textarea
						v-model="msg"
						:label="isAdminContact ? null : $t('support.msg')"
						v-validate="'required'"
						name="message"
						:error-messages="errors.collect('message')"
						:placeholder="msgPlaceholder">
					</v-textarea>
				</v-flex>
				<v-flex xs12 v-if="inquiryType=='technical'">
					<v-checkbox
						v-model="thisComputer"
						:label="$t('support.thisComputer')">
					</v-checkbox>
				</v-flex>
				<v-flex xs6 class="pt-4">
					<router-link v-if="!isAdminContact" :to="{name:'Quote'}" >{{$t('support.quote')}}</router-link>
				</v-flex>
				<v-flex class="text-xs-right">
					<v-btn color="primary" large raised type="submit" :disabled="submitted" class="mr-0">
						{{$t('form.submit')}}
					</v-btn>
				</v-flex>
			</v-layout>
		</form>
		<v-footer absolute>
			<div class="center">
				<v-icon color="black" small>phone</v-icon>&nbsp;<a href="tel:+1-866-882-4141">866-882-4141</a>
				<span v-if="!isAdminContact" class="px-2">•</span>
				<a v-if="!isAdminContact" :href="generalHelpCenterURL" target="_blank">Help Center</a>
			</div>
		</v-footer>
	</div>
</template>

<script>
	import APIs from 'mixins/APIs'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import Headline from 'components/layout/Headline'
	import Messaging from 'components/layout/Messaging'
	import ProductInfo from 'mixins/ProductInfo'
	import AutoFill from 'mixins/AutoFill'
	import { mapGetters } from 'vuex'
	export default {
		name: 'SupportForm',
		metaInfo : {
			title : 'Details'
		},
		components: {
			Headline,
			Messaging
		},
		mixins : [APIs, EnvironmentDetector, ProductInfo, AutoFill],
		data () {
			return {
				inquiryType: '',
				inquiryTypes: [
					{label: this.$t('support.technical'), type: 'technical'},
					{label: this.$t('support.feedback'), type: 'feedback'},
					{label: this.$t('support.sales'), type: 'sales'},
					{label: this.$t('support.comment'), type: 'comment'},
					{label: this.$t('support.billing'), type: 'billing'},
				],
				email: '',
				productID: null,
				msg : '',
				subject : '',
				submitted : false,
				thisComputer : true
			}
		},
		computed : {
			...mapGetters('platformData/profile', ['profile']),
			isAdminContact(){
				return this.flow=='admin-contact'
			},
			isEL(){
				return this.currentEnvironment=='el'
			},
			msgPlaceholder(){
				if(this.isAdminContact){
					return "Please include the Administrator’s full name, email address (if different from your email address above), school/district, and Gizmos order number(s). If submitting multiple contacts on this form, provide the necessary details for each."
				} else {
					return this.$t('support.msgPlaceholder')
				}
			},
			pageTitle(){
				if(this.isAdminContact){
					return "Gizmos Admin Contact Update"
				} else {
					return this.$t('support.header')
				}
			}
		},
		methods : {
			submitIssue: async function(){
				var valid = await this.$validator.validateAll()
				if(valid){
					this.submitted=true
					this.$store.commit('setMessageSuccess', '')
					this.$store.commit('setMessageError', '')
					
					let supportInfo = {
						supportType: this.inquiryType,
						emailAddress: this.email.trim(),
						message: this.msg,
						systemInfo: {
							tags: [],
							info: [
								'Raw Client Info: '+navigator.userAgent,
								'Support Cookies: '+navigator.cookieEnabled
							]
						}
					}
					let productID = this.productID || this.getProductCode()
					if(productID){
						supportInfo.productID = productID
						supportInfo.subject = this.productDisplayName(productID)+' Support Contact - '+this.subject
					} else {
						supportInfo.subject = 'ExploreLearning Support Contact - '+this.subject
					}
					if(this.inquiryType=='technical'){
						if(this.thisComputer){
							supportInfo.systemInfo.info.push('Same Computer: Yes')
						} else {
							supportInfo.systemInfo.info.push('Same Computer: No')
						}
					}
					this.$http.post(this.getSupportAPI(),supportInfo).then(response=>{
						this.$store.commit('setAllowConfirm', true)
						this.$router.push({name:'SupportSuccess'})
					},response=>{
						this.submitted = false
						this.$store.commit('setMessageError', this.$t('error.contactSupport'))
					})
				}
			}
		},
		created(){
			if(this.$route.query.type){
				this.inquiryType = this.$route.query.type
			}
			if(this.isAdminContact){
				this.productID = 3 // Gizmos
				this.inquiryType = 'admin_contact'
				this.subject = 'Gizmos Admin Contact for Migration'
			}
			if(this.profile.email) {
				this.autoFill()
				this.$store.commit('setMessageInfo', this.$t('help.intercomChat'))
			}
		}
  	}
</script>

<style lang="less" scoped>
	#message {
		background-color: #eee;
		border-radius: 10px;
	}
</style>
