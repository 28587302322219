<template>
    <div class="products" :class="['active-' + active]">
        <div 
            v-for="product in availableProducts" 
            :key="product.displayName"
            class="product"
        >
            <img :src="product.logo" :alt="product.displayName" :class="[isProductActive(product.environment),product.displayName.toLowerCase()+'-leaf']"/>
        </div>
        <!-- <div v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}" content="Learn about Reflex" class="product reflex">
            <reflex-logo></reflex-logo>
        </div> -->
    </div>
</template>

<script>
    import EnvironmentDetector from 'mixins/EnvironmentDetector'
    import ProductInfo from 'mixins/ProductInfo'
    export default {
        name: 'Products',
        mixins: [EnvironmentDetector, ProductInfo],
        props: ['active'],
        methods: {
            isProductActive(product){
                return 'active'
                if(this.currentEnvironment=='el' || this.currentEnvironment==product){
                    return 'active'
                } else {
                    return 'grey-product-indicator'
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .product{
        img{
            height: 26px;
            width: 26px;
        }
    }
    .grey-product-indicator {
        filter: grayscale(1);
        opacity: 0.22;
    }
    .grey-product-indicator.reflex-leaf{
        opacity: 0.4;
    }
    .grey-product-indicator.gizmos-leaf{
        opacity: 0.25;
    }
    .grey-product-indicator.science4us-leaf{
        opacity: .5;
    }
</style>