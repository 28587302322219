<template>
  <form @submit.prevent="resetPassword">
  	<v-layout wrap>
	  	<Headline :headline="headline" :subHeadline="account.username"></Headline>
			<Messaging outline></messaging>
      <PasswordValidationIndicator :password="password" class="mb-1"/>
			<v-flex xs12>
				<v-text-field
	      	v-model="password"
	      	:label="$t('form.passwordNew')"
	      	v-validate="veeValidatePasswordRules"
	      	name="password"
          ref="password"
	      	:error-messages="errors.collect('password')"
          :append-icon="passwordVisible ? 'visibility_off' : 'visibility'"
					@click:append="() => (passwordVisible = !passwordVisible)"
	      	:type="passwordVisible ? 'text' : 'password'">
	      </v-text-field>
			</v-flex>
			<v-flex xs12>
				<v-text-field
	      	v-model="passwordConfirm"
	      	:label="$t('form.passwordConfirm')"
	      	v-validate="'confirmed:password|required'"
	      	name="password confirmation"
	      	:error-messages="errors.collect('password confirmation')"
	      	type="password">
	      </v-text-field>
			</v-flex>
	    <v-flex xs6>
      	<v-btn v-if="displayBackBtn" large :to="{name:'ChooseAccount', params: {resetKey: resetKey}}" color="grey lighten-2">
        	<i class="material-icons">keyboard_arrow_left</i>
        	{{$t('navigation.back')}}
        </v-btn>
      </v-flex>
      <v-flex xs6 class="text-xs-right">
        <v-btn color="primary" large type="submit">
        	{{$t('form.submit')}}
        </v-btn>
      </v-flex>
    </v-layout>
  </form>
</template>

<script>
  import APIs from 'mixins/APIs'
  import ErrorHandling from 'mixins/ErrorHandling'
  import Headline from 'components/layout/Headline'
  import Messaging from 'components/layout/Messaging'
  import PasswordValidation from 'mixins/PasswordValidation'
  import PasswordValidationIndicator from 'components/layout/PasswordValidationIndicator'

  export default {
    name: 'ResetPassword',
    metaInfo : {
			title : 'Reset'
		},
    components: {
    	Headline,
    	Messaging,
      PasswordValidationIndicator
    },
    mixins : [APIs, ErrorHandling, PasswordValidation],
    data () {
      return {
        password : '',
        passwordConfirm : '',
        passwordVisible: false,
        account : {}
      }
    },
    computed: {
      headline(){
        if(this.flow=='passReset'){
          return 	this.$t('chooseAccount.header')
        } else if (this.flow=='passExp') {
          return this.$t('expiredPassword.header')
        }
      },
      resetKey : function(){
        return this.$route.params.resetKey
      },
      displayBackBtn : function(){
      	return this.$store.state.displayOverrides.passwordResetBackBtn &&  this.flow=='passReset'
      }
    },
    methods: {
      resetPassword : async function(){
        var valid = await this.$validator.validateAll()
        if(valid){
          if(this.flow=='passReset'){
            this.$http.post(this.getResetAPI(), {regCode: this.resetKey, loginID: this.account.loginID, password: this.password}).then(response => {
              //success callback
              this.$router.push({name:'Login', params: {userType:'educator'}, query: {s: 'passwordReset'}})
            }, error => {
              //error callback
              let response = error.response
              if (response.status == 400) {
                this.$store.commit('setMessageError', this.$t('error.invalidCode'))
              } else if (response.status == 404 || response.status == 0) {
                this.$store.commit('setMessageError', this.$t('error.404'))
              } else {
                this.handleError(response)
              }
            })
          } else if(this.flow=='passExp') {
            const payload = {
              loginID : this.$route.params.userID,
              password : this.password,
              currentPassword : this.$route.params.password
            }
            this.$http.post(this.getExpiredPasswordAPI(), payload).then(response => {
              this.$router.push({name:'Login', params:{userType:'educator'}, query:{s:'passwordReset',u:this.$route.params.username}})
            }, error => {
              this.handleError(error.response)
            })
          }
        }
      }
    },
    created: function(){
      if(this.flow == 'passReset'){
        if(this.$store.state.formData.resetAccount){
          this.account = this.$store.state.formData.resetAccount
        } else {
          this.$router.replace({name:'ChooseAccount', params: {resetKey: this.resetKey}})
        }
      } else if (this.flow == 'passExp') {
        if(!this.$route.params.userID || !this.$route.params.password){
          this.$router.replace({name:'Login', params: {userType:'educator'}})
        }
      }
    }
  }
</script>
