import EnvironmentDetector from 'mixins/EnvironmentDetector'
import LoginDetector from 'mixins/LoginDetector'
import ProductInfo from 'mixins/ProductInfo'
export default {
  	data() {
		return {
			protocol: process.env.protocol,
			platformURL: process.env.basePlatformApiUrl,
			studentLoginURL: process.env.studentLoginURL,
			classCodeURL: process.env.classCodeURL,
			helpCenterURL: process.env.helpCenterURL,
			generalHelpCenterURL: process.env.generalHelpCenterURL,
			el: {
				API: process.env.baseReflexApiUrl,
				marketing: process.env.exploreLearningURL,
			},
			reflex: {
				API: process.env.baseReflexApiUrl,
				reportingApp: process.env.reflexReportingAppURL,
				studentApp: process.env.reflexStudentAppURL,
				marketing: process.env.reflexMarketingURL,
				pdDashboard: process.env.reflexPdDashboard,
			},
			gizmos: {
				API: process.env.baseGizmosApiUrl,
				reportingApp: process.env.gizmosReportingAppURL,
				studentApp: process.env.gizmosStudentAppURL,
				marketing: process.env.gizmosMarketingURL,
				adminApp: process.env.gizmosAdminURL,
				migrationTool: process.env.gizmosMigrationToolURL,
				reportsTool: process.env.gizmosReportsToolURL
			},
			frax: {
				API: process.env.baseFraxApiUrl,
				reportingApp: process.env.fraxReportingAppURL,
				studentApp: process.env.fraxStudentAppURL,
				marketing: process.env.fraxMarketingURL
			},
			frax2: {
				API: process.env.baseFraxApiUrl,
				reportingApp: process.env.fraxReportingAppURL,
				studentApp: process.env.frax2StudentAppURL,
				marketing: process.env.fraxMarketingURL
			},
			s4us: {
				API: process.env.baseS4uApiUrl,
				reportingApp: process.env.s4uReportingAppURL,
				studentApp: process.env.s4uStudentAppURL,
				marketing: process.env.s4uMarketingURL,
				adminApp: process.env.s4uAdminURL
			},
			competitions: process.env.baseCompetitionsApiUrl,
			csaURLold: process.env.csaAppURLold,
			csaURLnew: process.env.csaAppURL,
			gizmosAdminURL: process.env.gizmosAdminURL,
			educatorResourceHub: process.env.educatorResourceHub
		}
	},
	mixins: [EnvironmentDetector,LoginDetector,ProductInfo],
	methods: {
		// Get Base URLs
		getPlatformURL(){
			return this.protocol + this.platformURL + '/api/platform'
		},
		getClassCodeRegDoc(code, name) {
			return this.protocol + this.gizmos.API + `/api/generate/student/enrollment/file?classCode=${code}&className=${encodeURI(name)}`
		},
		// API Endpoints
		getAccountCreationAPI(regCode, environment){
			environment = environment || this.currentEnvironment
			if(regCode){
				return this.protocol + this.platformURL + '/web/trial/register?productID=' + this.getProductCode(environment) + '&regCode=' + regCode
			} else {
				return this.protocol + this.platformURL + '/web/store/register?productID=' + this.getProductCode(environment)
			}
		},
		getAccountCreationFromInviteAPI(regCode){
			return this.protocol + this.platformURL + '/web/login/regCode?regCode=' + regCode
		},
		getAccountsAPI(resetKey){
		  	return this.protocol + this.platformURL + '/auth/accounts/' + resetKey
		},
		getAddUpdateCheckoutAddressAPI(addressType){
			return this.protocol + this.platformURL + '/api/web/order/address/' + addressType
		},
		getAddUpdateCheckoutContactInfoAPI(){
			return this.protocol + this.platformURL + '/api/web/order/contact'
		},
		getAddUpdateCheckoutCreditCardInfoAPI(){
			return this.protocol + this.platformURL + '/api/web/order/creditCard'
		},
		getAddUpdateCheckoutPromoCodeAPI(promoCode){
			return this.protocol + this.platformURL + '/api/web/order/' + promoCode
		},
		getAddUpdateCheckoutSchoolInfoAPI(){
			return this.protocol + this.platformURL + '/api/web/order/schoolInfo'
		},
		getAddUpdateOrderItem(orderItemID){
			return this.protocol + this.platformURL + '/api/web/order/orderItem/' + orderItemID
		},
		getAdminAppURL(environment){
			environment = environment || this.currentEnvironment
			return this[environment].adminApp
		},
		getAdminURL(environment){
			return this[environment].admin
		},
		getCatalogAPI(secured){
			if(secured){
				return this.protocol + this.platformURL + '/api/web/orderItems'
			} else {
				return this.protocol + this.platformURL + '/web/orderItems'
			}
		},
		getCheckoutPurchaseAPI(){
			return this.protocol + this.platformURL + '/api/web/order/purchase'
		},
		getCheckoutReviewAPI(){
			return this.protocol + this.platformURL + '/api/web/order/review'
		},
		getClaimPendingUserAPI(regCode, emailCheck){
			let URL = this.protocol + this.platformURL + '/web/v2/login/invite/regcode/' + regCode
			if(emailCheck){
				URL += '?wasEmailSentByAdmin='+emailCheck
			}
			return URL
		},
		// Because this method is used by the router it needs to pull data directly from the environmental vars
		getClaimsAPI(environment){
			return  process.env.protocol + process.env.basePlatformApiUrl + '/auth/claims'
		},
		getClassesAPI(){
			return this.protocol + this.platformURL + '/auth/classes'
		},
		getClassCodeURL(classCode){
			return this.protocol + this.classCodeURL
		},
		getClientLogAPI(){
			return this.protocol + this.platformURL + '/clientLog'
		},
		getCompetitionsAppURL() {
			return this.protocol + this.competitions			 
		},
		getCountriesAPI(){
			return this.protocol + this.platformURL + '/web/countries/'
		},
		getCsaAppURL(useNewVersion){
			if(useNewVersion){
				return this.csaURLnew
			} else {
				return this.csaURLold
			}
		},
		getCustomerServiceURL(){
			return window.location.origin + process.env.rootPath + '/el/support'
		},
		getEducatorResourceHubURL(){
			return this.educatorResourceHub
		},
		getEmailValidationAPI(regCode){
			return this.protocol + this.platformURL + '/web/validate/email/' + regCode
		},
		getExpiredPasswordAPI(){
			return this.protocol + this.platformURL + '/auth/reset/expired/password'
		},
		getFreemiumTrialPromotionAPI(environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this[environment].API + '/Freemium/trialPromotion'
		},
		getGizmosMigrationToolURL(){
			return this.gizmos.migrationTool
		},
		getGizmosReportsToolURL(){
			return this.gizmos.reportsTool
		},
		getGrantActivationCodeValidatorAIP(grantCode){
			return this.protocol + this.platformURL + '/web/grant/regcode/' + grantCode
		},
		getGrantApplicationAPI(regCode, environment){
			environment = environment || this.currentEnvironment
			let URL = this.protocol + this.platformURL + '/web/grant/apply/' + this.getProductCode(environment)
			if(regCode){
				URL = URL + '?optRegCode=' + regCode
			}
			return URL
		},
		getGrantApplicationClosedAPI(environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this.platformURL + '/web/grant/apply/closed/' + this.getProductCode(environment)
		},
		getGrantApplicationPreApprovedAPI(environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this.platformURL + '/api/web/grant/apply/' + this.getProductCode(environment) + '/preapproved'
		},
		getGrantCreationAPI(grantCode){
			return this.protocol + this.platformURL + '/api/web/grant/complete/' + grantCode
		},
		getGrantPeriodAPI(environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this.platformURL + '/web/grant/period/' + this.getProductCode(environment)
		},
		getGrantRegCodeValidationAPI(grantRegCode, environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this.platformURL + '/web/grant/regcode/apply/' + grantRegCode + '/' + this.getProductCode(environment)
		},
		getInstitutionSearchAPI(countryCode, stateCode, type){
			let url = this.protocol + this.platformURL + '/web/institutions/search?countryCode=' + countryCode
			if(stateCode){
				url = url + '&stateCode=' + stateCode
			}
			if(type){
				url = url + '&type=' + type
			}
			return url
		},
		getInstitutionsAPI(zip){
			return this.protocol + this.platformURL + '/web/institutions/search/' + zip
		},
		getInstitutionsByCountryCodeAPI(countryCode, environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this.platformURL + '/web/institutions/search/country/' + countryCode
		},
		getInstitutionsByParentAPI(pid){
			return this.protocol + this.platformURL + '/web/institutions/parent/' + pid
		},
		getHelpCenterURL(path){
			let url
			path ? url = this.helpCenterURL + path : url = this.generalHelpCenterURL
			return url
		},
		getLinkAccountToSubscriptionAPI(regCode){
			return this.protocol + this.platformURL + '/api/web/login/regCode/self?regCode='+regCode
		},
		// Because this method is used by the router it needs to pull data directly from the environmental vars
		getLoggingAPI(){
			return process.env.protocol + process.env.basePlatformApiUrl + '/clientLog'
		},
		getLoginAPI(app, usernameConflictVersion, environment){
			environment = environment || this.currentEnvironment
			let url
			if(app=="app"){
				url = this.protocol + this[environment].API + '/auth/login/'
			} else {
				url = this.protocol + this.platformURL + '/auth/login/'
			}
			if(usernameConflictVersion){
				url = url + 'migration/'
			}
			return  url + app
		},
		getLogoutAPI(){
		  return this.protocol + this.platformURL + '/api/logout'
		},
		getMarketingURL(environment){
			environment = environment || this.currentEnvironment
			return this[environment].marketing
		},
		getMarketingGrantURL(environment){
			environment = environment || this.currentEnvironment
			return this[environment].marketing + 'grant'
		},
		getMarketingPurchaseURL(environment){
			environment = environment || this.currentEnvironment
			return this[environment].marketing + 'buy'
		},
		getModelAPI(){
			return this.protocol + this.platformURL + '/api/platform/model'
		},
		getOrderAPI(){
			return this.protocol + this.platformURL + '/api/web/order'
		},
		getPdDashboardURL(environment){
			environment = environment || this.currentEnvironment
			return this[environment].pdDashboard
		},
		getOrderExistsAPI(){
			return this.protocol + this.platformURL + '/api/web/order/exists'
		},
		getOrderHistoryAPI(){
			return this.protocol + this.platformURL + '/api/web/order/history'
		},
		getParentEmailAPI(environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this.platformURL + '/web/v2/parent/invite/' + this.getProductCode(environment)
		},
		getProfileAPI(){
			return this.protocol + this.platformURL + '/api/platform/login/nonstudent/self'
		},
		getProfileUpdateAPI(){
			return this.protocol + this.platformURL + '/api/platform/login/self'
		},
		getQuoteAPI(environment){
			environment = environment || this.currentEnvironment
			let productCode = this.getProductCode(environment)
			productCode = productCode || 101
			return this.protocol + this.platformURL + '/web/sales/contact/' + productCode
		},
		getRecoveryAPI(){
		  	return this.protocol + this.platformURL + '/auth/recovery'
		},
		getRefreshAPI(){
		  	return this.protocol + this.platformURL + '/api/refresh'
		},
		getRegistrationURL(regCode,emailCheck){
			let URL = window.location.origin + process.env.rootPath + '/el/reg/'
			if(regCode){
				URL += regCode
				if(emailCheck){
					URL += '?emailCheck='+emailCheck
				}
			}
			return URL
		},
		getReportingAppURL(environment){
			environment = environment || this.currentEnvironment
			return this[environment].reportingApp
		},
		getReportingAppURLForMultiInstTeacher(environment) {
			environment = environment || this.currentEnvironment
			return this[environment].reportingApp + '/' + this.selectedSchoolID
		},
		getResetAPI(){
		  	return this.protocol + this.platformURL + '/auth/reset'
		},
		getSectorLimitAPI(subID){
			return this.protocol + this.frax.API + '/api/config/subscription/'+ subID +'/sectorLimit'
		},
		getSsoTrialRegistrationAPI(regCode, environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this.platformURL + '/web/trial/complete/' + this.getProductCode(environment) + '/' + regCode
		},
		getSsoTrialAPI(institutionID, environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this.platformURL + '/api/web/trial/signup/' + this.getProductCode(environment) + '/sso?institutionID=' + institutionID
		},
		getStatesAPI(countryCode){
			return this.protocol + this.platformURL + '/web/states/' + countryCode
		},
		getStudentAppURL(environment){
			environment = environment || this.currentEnvironment
			return this[environment].studentApp + "?cb=" + (new Date()).getTime()
		},
		getStudentSelfEnrollmentExisting(environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this.platformURL + '/classcode/student/existing/' + this.getProductCode(environment)
		},
		getStudentSelfEnrollmentNew(){
			return this.protocol + this.platformURL + '/classcode/student/new'
		},
		getStudentInfoFraxAPI(){
			return this.protocol + this.frax.API +'/api/reports/frax/user/setting/sector'
		},
		getStudentLoginURL(){
			return this.protocol + this.studentLoginURL
		},
		getStudentLogoutAPI(environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this[environment].API + '/api/logout'
		},
		getSubscriptionsAPI(subscriptionId){
			if(subscriptionId){
				return this.protocol + this.platformURL + '/api/platform/subscription/' + subscriptionId
			} else {
				return this.protocol + this.platformURL + '/api/web/v2/subscriptions'
			}
		},
		getSubscriptionsEmployeeAPI(institutionID){
			return this.protocol + this.platformURL + '/api/platform/institution/' + institutionID + '/logins/subscriptions'
		},
		getSupportAPI(){
			return this.protocol + this.platformURL + '/web/support'
		},
		getTrialCreationAPI(institutionID, regCode, environment){
			// TODO Swap productName For productID
			environment = environment || this.currentEnvironment
			let URL = this.protocol + this.platformURL + '/api/web/trial/complete/' + this.getProductCode(environment)
			if(institutionID || regCode){
				URL = URL + '?'
			}
			if(institutionID){
				URL = URL + 'institutionID=' + institutionID
				if(regCode){
					URL = URL + '&'
				}
			}
			if(regCode){
				URL = URL + 'regcode=' + regCode
			}
			return URL
		},
		getTrialEmailAPI(isFreemium, environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this.platformURL + '/web/trial/signup/' + this.getProductCode(environment) + '?isFreemium=' + isFreemium
		},
		getUpdateSectorAPI(maxSectorID){
			return this.protocol + this.frax.API +  '/api/reports/frax/user/setting/sector/' + maxSectorID
		},
		getUploadAvatarAIP(){
			return this.protocol + this.platformURL + '/api/platform/login/self/profilepicture'
		},
		getUsernameAvalibility(){
			return this.protocol + this.platformURL + '/auth/username/availability'
		},
		getValidateClassCodeAPI(){
			return this.protocol + this.platformURL + '/classcode/class'
		},
		getValidatePromoCodeTrialAPI(promoCode, environment){
			environment = environment || this.currentEnvironment
			return this.protocol + this.platformURL + '/web/promoCode/trial/' + this.getProductCode(environment) + '/' + promoCode
		},
		getValidateRegCodeAPI(regCode){
			let URL = this.protocol + this.platformURL
			if(this.loggedIn){
				URL = URL + '/api'
			}
			return URL + '/web/validate/' + regCode
		},
		getValidateStudentIdAPI(){
			return this.protocol + this.platformURL + '/classcode/student/check'
		},
		// Helper Functions
		addZero(x, n) {
			while (x.toString().length < n) {
				x = "0" + x
			}
			return x
		},
		createLoggingObject(response, xClaims={}, error=""){
			let trackingObject = {}
			trackingObject.cookie = document.cookie.replace(/(?:(?:^|.*;\s*)el-client\s*\=\s*([^;]*).*$)|^.*$/, "$2");
			trackingObject.clientInfo = navigator.userAgent
			trackingObject.sessionID = xClaims.sessionID
			trackingObject.error = error

			let trackingEntry = {}
			trackingEntry.url = response.url.replace(/(http.?:\/\/.*\..{2}.?)(\/)(.*)/,'$2$3')
			let now = new Date()
			let hour = this.addZero(now.getHours(),2)
			let min = this.addZero(now.getMinutes(),2)
			let sec = this.addZero(now.getSeconds(),2)
			let ms = this.addZero(now.getMilliseconds(),3)
			trackingEntry.time = hour+':'+min+':'+sec+':'+ms
			trackingEntry.status = response.status
			trackingEntry.error = response.data.error
			trackingObject.trackingEntry = trackingEntry

			return trackingObject
		},
		decodeXclaims(response){
			var xclaims = null
			var userInfo = {}
			if(response.headers['X-CLAIMS']){
				xclaims = response.headers['X-CLAIMS']
			} else if (response.headers['x-claims']) {
				xclaims = response.headers['x-claims']
			}
			if(xclaims){
				if(Array.isArray(xclaims)){
					xclaims = xclaims[0]
				}
				try {
					userInfo = JSON.parse(atob(xclaims))
				} catch (error) {
					return userInfo
				}
			}
			return userInfo
		},
		getProductCode(environment){
			environment = environment || this.currentEnvironment
			if(environment == 'reflex'){
				return 1
			} else if(environment == 'frax' || environment == 'frax2'){
				return 2
			} else if(environment == 'gizmos'){
				return 3
			} else if(environment == 's4us'){
				return 4
			} else {
				return NaN
			}
		},
		getStaticURL(){
			let URL = window.location.origin
			URL = URL + process.env.rootPath
			URL = URL + '/assets/'
			return URL
		}
	},
	created(){
		//this.protocol = window.location.protocol+'//'
	}
}
