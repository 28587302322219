<template>
  <form @submit.prevent="advance">
  	<v-layout wrap>
    	<GrantHeadline :headline="$t('grant.questions')"></GrantHeadline>
    </v-layout>
    <v-layout wrap>
    	<GrantStepper  :currentStep="3"></GrantStepper>
    	<v-flex xs12>
				<Messaging outline></Messaging>
			</v-flex>
			<v-flex xs12>
				<span class="title">
					{{$t('grant.ofTotal', {num:questionID, total:totalQuestions})}}
					{{currentQuestion}}
				</span>
			</v-flex>
			<v-flex xs12>
				<v-textarea
					v-model="answer"
					name="answer"
					v-validate="'required'"
					:error-messages="errors.collect('answer')">
				</v-textarea>
			</v-flex>
			<v-flex xs6>
				<v-btn @click="back" large color="grey lighten-2">
          {{$t('navigation.back')}}
        </v-btn>
			</v-flex>
    	<v-flex class="text-xs-right">
	      <v-btn color="primary" large raised type="submit">
	        {{$t('navigation.next')}}
	    	</v-btn>
	    </v-flex>
  	</v-layout>
  </form>
</template>

<script>
	import GrantHeadline from 'components/layout/GrantHeadline'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import GrantStepper from 'components/grant/GrantStepper'
	import Messaging from 'components/layout/Messaging'
	export default {
		name: 'Question',
		metaInfo() {
			return {
				title : 'Question '+this.questionID
			}
		},
		components: {
			GrantStepper,
			GrantHeadline,
			Messaging
		},
		mixins: [EnvironmentDetector],
		data () {
		return {
			answer: null
		}
		},
		computed : {
			currentQuestion(){
				return  this.$t('grant.'+this.currentEnvironment+'.question'+this.questionID)
			},
			questionID(){
				return this.$route.params.questionID
			},
			totalQuestions(){
				return Object.keys(this.$t('grant.'+this.currentEnvironment)).length
			}
		},
		methods : {
			advance: async function(){
				var valid = await this.$validator.validateAll()
				if(valid){
					this.$store.commit('setGrantQuestion',{id:this.questionID, question:this.currentQuestion, answer:this.answer})
					let nextQuestion = Number(this.questionID)+1
					if(nextQuestion<=this.totalQuestions){
						if(this.$store.state.grant.questions[nextQuestion-1]){
							this.answer=this.$store.state.grant.questions[nextQuestion-1].answer
						} else {
							this.answer = ''
						}
						this.$validator.reset();
						this.$router.push({name:'GrantQuestion',params:{questionID:nextQuestion}})
					} else {
						this.$router.push({name:'GrantTerms'})
					}
				}
			},
			back(){
				this.$store.commit('setGrantQuestion',{id:this.questionID, question:this.currentQuestion, answer:this.answer})
				let previousQuestion = this.questionID-1
				if(previousQuestion){
					if(this.$store.state.grant.questions[previousQuestion-1]){
						this.answer=this.$store.state.grant.questions[previousQuestion-1].answer
					} else {
						this.answer = ''
					}
					this.$router.push({name:'GrantQuestion', params:{questionID:previousQuestion}})
				} else {
					this.$router.push({name:'GrantSchoolInfo'})
				}
			}
		},
		created(){
			if(!this.$store.state.schoolInfo.pid && !this.$store.state.schoolInfo.unknownName){
				this.$router.replace({name:'GrantSchoolInfo'})
			} else {
				if(this.$store.state.grant.questions[this.questionID-1]){
					console.log(124)
					this.answer = this.$store.state.grant.questions[this.questionID-1].answer
				}
			}
		}
  	}
</script>
