<template>
	<form @submit.prevent="login">
		<v-layout wrap v-if="redirectFailure">
			<v-flex xs12 sm10 offset-sm1 md8 offset-md2>
				<Headline :headline="$t('studentPassword.loggedIn')" :subHeadline="student.name"></Headline>
			</v-flex>
			<v-flex xs12 sm10 offset-sm1 md8 offset-md2>
				<a target="_blank" :href="getStudentAppURL()">
					<v-btn color="primary" round large>
						{{$t('studentPassword.launch')}}
					</v-btn>
				</a>
			</v-flex>
		</v-layout>
    	<v-layout v-else wrap>
			<v-flex xs12 sm10 offset-sm1 md8 offset-md2>
				<Headline :headline="$t('studentPassword.header')" :subHeadline="student.name"></Headline>
			</v-flex>
			<v-flex xs12 sm10 offset-sm1 md8 offset-md2>
				<Messaging></Messaging>
			</v-flex>
			<v-flex xs12 sm10 offset-sm1 md8 offset-md2>
				<v-text-field
					type="password"
					v-model="password"
					:label="$t('form.password')"
					v-validate="'required|max:30'"
					name="password"
					:error-messages="errors.collect('password')"
					color="white"
					dark
					autofocus
				>
	      		</v-text-field>
			</v-flex>
			<v-flex xs6 sm5 offset-sm1 md4 offset-md2 class="text-xs-left">
				<router-link :to="{name:'AppStudent'}" >
					<v-btn flat large round dark>
					<i class="material-icons">keyboard_arrow_left</i>
					{{$t('studentPassword.back')}}
					</v-btn>
				</router-link>
      		</v-flex>
			<v-flex xs6 sm5 md4 class="text-xs-right">
				<v-btn color="primary" large round type="submit" :disabled="disableLogin">
				{{$t('navigation.login')}}
				</v-btn>
			</v-flex>
		</v-layout>
	</form>
</template>

<script>
	import APIs from 'mixins/APIs'
	import ErrorHandling from 'mixins/ErrorHandling'
	import Forwarding from 'mixins/Forwarding'
	import Headline from 'components/layout/Headline'
	import Messaging from 'components/layout/Messaging'
	export default {
		name: 'StudentPassword',
		metaInfo : {
				title : 'Password'
			},
		components: {
			Headline,
			Messaging
		},
		mixins: [APIs, ErrorHandling, Forwarding],
		data () {
			return {
				password: '',
				redirectFailure: false,
			}
		},
		computed : {
			student(){
                return this.$store.state.studentLogin.student
            },
			className : function(){
                return this.$store.state.studentLogin.class.className
            },
			disableLogin : function(){
				return this.$store.state.navigation.disableLogin
			},
			teacherName: function(){
                return this.$store.state.studentLogin.teacherName
            },
		},
		methods : {
			login : async function(){
				var valid = await this.$validator.validateAll()
				if(valid){
					this.$store.commit('setDisableLogin', true)
					this.$http.post(this.getLoginAPI('app'), {"username": this.student.username, "password": this.password}).then(response => {
						//success
						this.forward(this.getStudentAppURL()+'&isMobileApp=true')
						let self = this;
						setTimeout(function(){ self.redirectFailure = true }, 5000)
					}, error => {
						//failure
						if(error.response.data.id==14){
							this.$store.commit('setMessageError', this.$t('invalidPassword'))
							this.formErrors = {password:true}
						} else {
							this.handleError(error.response)
						}
						this.$store.commit('setDisableLogin', false)
					})
				}
			}
		},
		created : function(){
			if(!this.$store.state.studentLogin.student.username){
				this.$router.replace({name: 'AppStudent'})
			}
		}
	}
</script>
