<template>
	<v-layout wrap>
    <Headline :logo="currentEnvironment" :headline="$t('contactSuccess.header')"></Headline>
    <v-flex xs12 class="mb-3"v-if="flow=='support'">{{$t('support.success')}}</v-flex>
  	<v-flex xs12 class="mb-3" v-else>{{$t('success.contact')}}</v-flex>
   </v-layout>
</template>

<script>
  import Headline from 'components/layout/Headline'
  import EnvironmentDetector from 'mixins/EnvironmentDetector'
  export default {
    name: 'ContactSuccess',
    metaInfo : {
        title : 'Success'
    },
    components: {Headline},
    mixins: [EnvironmentDetector],
    created(){
    	if(!this.$store.state.navigation.allowConfirm){
    		this.$router.go(-1)
  		}
    }
  }
</script>
