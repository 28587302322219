<template>
    <v-alert
        :value="1"
        v-if="!hasEnoughSeats(selectedStudents,productID) && modalSpecificSelection>0"
        color="error"
        icon="error"
        outline
        class="mb-3"
    >
        {{$t('classMgmt.subscriptionLimit', {
            product: productDisplayName(productID), 
            seatCap: seatInfo(productID).studentsCap
        })}}
        <span v-if="selectedStudents.length==1">
            {{$t('classMgmt.reachedTheLimit')}}
        </span>
        <span v-else>
            {{$t('classMgmt.currentlyThereAre',{
                studentsUsed: seatInfo(productID).studentsUsed, 
                numberOfSelectedStudents: selectedStudents.length, 
                numberOfUnassignedStudents: numberOfUnassigned(selectedStudents,productID), 
                inactive: $tc('classMgmt.inactivePlural', numberOfUnassigned(selectedStudents,productID))
            })}}
        </span>
        {{$t('classMgmt.toMakeThisChangeFirst', {
            countStudentsToMakeInactive: countStudentsToMakeInactive,
            students: $tc('classMgmt.students', countStudentsToMakeInactive).toLowerCase()
        })}} 
    </v-alert> 
</template>

<script>
    import APIs from 'mixins/APIs'
    import ProductInfo from 'mixins/ProductInfo'

	export default {
        name: 'SeatCapAlert',
        components: { },
        mixins: [APIs, ProductInfo],
        props: {
            determineAssignment: Function,
            modalSpecificSelection: Number,
            productID: Number,
            seatInfo: Function,
            selectedSchool: Object,
            selectedStudents: Array,
        },
        data() {
            return {
                prevHasEnoughSeats: true,
            }
        },
		computed : {
            countStudentsToMakeInactive() {
                return (this.seatInfo(this.productID).studentDelta - this.numberOfUnassigned(this.selectedStudents, this.productID)) * -1
            }
        },
        methods: {
            checkForButtonUpdate(){
                let newHasEnoughSeats = this.hasEnoughSeats(this.selectedStudents, this.productID)
                if (this.prevHasEnoughSeats != newHasEnoughSeats){
                    this.$emit('updateButtonState', newHasEnoughSeats)
                    this.prevHasEnoughSeats = newHasEnoughSeats
                }
            },
            hasEnoughSeats(students,productID){
                let numUnassigned = this.numberOfUnassigned(students,productID)
                if(numUnassigned){
                    return this.seatInfo(productID).studentDelta >= this.numberOfUnassigned(students,productID)
                } else {
                    return true
                }
            },
            numberOfUnassigned(students,productID){
                let count = 0
                if(students && productID){
                    students.forEach(student => {
                        if(!this.determineAssignment(student,productID).code){
                            ++count
                        }
                    })
                }
                return count
            },
        },
        watch: {
            modalSpecificSelection(val){
                this.checkForButtonUpdate()
            },
            productID(val){
                this.checkForButtonUpdate()
            },
            selectedSchool(val){
                this.checkForButtonUpdate()
            },
            selectedStudents(val){
                this.checkForButtonUpdate()
            },
        },
    }
</script>

<style lang="less" scoped>
</style>

// has enough seats will emit when changed and containing class should remain in sync