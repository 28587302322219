<template>
	<v-layout id="headline" align-center wrap row>
		<div v-if="logo" class="logo">
			<img v-if="productCode" :src="productImgPath(productCode)" :alt="'Explore Learning '+productDisplayName(productCode)+' Logo'">
			<img v-else src="../../assets/explorelearning/img/logo-leaf.svg" alt="Explore Learning Logo">
			
		</div>
		<v-flex class="headline" :class="alignmentClass">
			<v-flex v-if="flowDisplayName" :class="{pointer:dropdown}" class="subheading" @click="$emit('flow-click')">
				<span v-html="flowDisplayName"></span>
				<span v-if="flowDropdown">
					<v-icon small>expand_more</v-icon>
				</span>
			</v-flex>
			<v-flex v-if="headline" :class="{pointer:dropdown}" class="display-1" :style="{color}" @click="$emit('dropdown-click')">
				<v-icon v-if="icon" class="pb-2 pr-1" :style="{color}">{{icon}}</v-icon>
				{{headline}}
				<span v-if="subHeadline && fullPage" class="hidden-xs-only subheading">
					{{subHeadline}}
				</span>
				<span v-if="dropdown">
					<v-icon id="HeadlineDropdownIndicator" class="pb-1" :color="color" size="30">expand_more</v-icon>
				</span>
			</v-flex>
			<v-flex v-if="subHeadline && !fullPage" class="subheading mt-1">
				{{subHeadline}}
			</v-flex>
		</v-flex>
	</v-layout>
</template>

<script>
	import APIs from 'mixins/APIs'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import ProductInfo from 'mixins/ProductInfo'
	import { mapGetters } from 'vuex'
	export default {
		name: 'HeadLine',
		mixins: [APIs, EnvironmentDetector, ProductInfo],
		props: {
			flowDropdown : {
				type: Boolean,
				default: false
			},
			flowOverride: {
				type: String,
				default: null
			},
			logo: {
				type: String,
				default: null
			},
			headline: {
				type: String,
				default: null
			},
			subHeadline: {
				type: String,
				default: null
			},
			dropdown: {
				type: Boolean,
				default: false
			},
			icon: {
				type: String,
				default: ''
			},
			color: {
				type: String,
			},
			align: {
				type: String,
				default: 'left'
			}
		},
		computed: {
			...mapGetters('platformData/profile', ['profile']),
			alignmentClass(){
				return 'text-xs-'+this.align
			},
			elSkin(){
				return this.$route.meta.elSkin
			},
			flowDisplayName(){
				if(this.flowOverride == 'none'){
					return null
				} else if (this.flowOverride){
					return this.flowOverride
				} else {
					let flow = this.flow
					if (flow=='grant'){
						return this.$t('flow.grant')
					} else if(flow=='reg') {
						return this.$t('flow.reg')
					} else if(flow=='quote') {
						return this.$t('flow.quote')
					} else if(flow=='demo') {
						return this.$t('flow.demo')
					} else if(flow=='pre-approved') {
						return this.$t('flow.pre-approved')
					} else if(flow=='checkout') {
						return this.$t('flow.checkout')
					} else if(flow=='passReset') {
						return this.$t('flow.passReset')
					} else if(flow=='class') {
						if(this.profile.roleID==3){
							return this.$t('flow.class')
						} else {
							return this.$t('flow.user')
						}
					} else {
						return null
					}
				}
			},
			productCode(){
				return this.getProductCode(this.logo)
			},
			fullPage: function(){
      			return this.$route.meta.fullPage
    		}
		}
	}
</script>

<style scoped lang="less">
	#headline{
		margin-bottom: 30px;
		color:#444;
		.headline, .display-1, .subheading {
			padding: 0px 4px
		}
		.headline {
			margin-top: -4px;
		}
		.display-1 {
			line-height: 34px !important;
		}
		.subheading {
			line-height: 16px;
			margin-bottom: 2px;
		}
		.logo {
			width: 75px;
			img {
				width: 60px;
			}
		}
		.pointer {
			cursor: pointer;
		}
		.prodLogo {
			height: 60px;
		}
		.expand {
			vertical-align: center;
			margin-top: -25px;
			display: inline-block;
		}
	}
</style>
