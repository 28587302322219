<template>
  	<form @submit.prevent="submit">
  		<v-layout wrap>
    		<GrantHeadline :headline="$t('grant.terms')"></GrantHeadline>
    	</v-layout>
    	<v-layout wrap>
    		<GrantStepper  :currentStep="4"></GrantStepper>
    		<v-flex xs12>
				<Messaging outline></Messaging>
			</v-flex>
			<v-flex xs12>
				<span class="subheading">{{$t('grant.mustAgree')}}:</span>
			</v-flex>
		</v-layout>
		<GrantTerms :productID="getProductCode()"></GrantTerms>
		<v-layout mb-4 pl-3 class="text-xs-right">
			<v-checkbox
				:label="$t('grant.agree',{product:productName})"
				v-model="agree"
				v-validate="'required'"
				name="agree"
				:error-messages="errors.collect('agree')"
			></v-checkbox>
	  	</v-layout>
		<v-layout wrap>
			<v-flex xs6>
				<v-btn :to="{name:'GrantQuestion',params:{questionID:lastQuestionID}}" large color="grey lighten-2">
				{{$t('navigation.back')}}
				</v-btn>
			</v-flex>
    		<v-flex class="text-xs-right">
				<v-btn color="primary" large raised type="submit" :disabled="!agree || submitting">
					{{$t('form.apply')}}
					</v-btn>
	    	</v-flex>
  		</v-layout>
  	</form>
</template>

<script>
	import APIs from 'mixins/APIs'
	import Constants from 'mixins/Constants'
	import ErrorHandling from 'mixins/ErrorHandling'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import GrantStepper from 'components/grant/GrantStepper'
	import GrantTerms from 'components/grant/GrantTerms'
	import GrantHeadline from 'components/layout/GrantHeadline'
	import Messaging from 'components/layout/Messaging'
	import ProductInfo from 'mixins/ProductInfo'
	import Tracking from 'mixins/Tracking'
	import { mapGetters } from 'vuex'
	export default {
    name: 'AcceptGrantTerms',
		metaInfo : {
				title : 'Grant Application'
			},
		components: {
			GrantStepper,
			GrantTerms,
			GrantHeadline,
			Messaging
		},
		mixins: [APIs, Constants, ErrorHandling, EnvironmentDetector, Tracking, ProductInfo],
		data () {
			return {
				agree: false,
				submitting: false
			}
		},
		computed : {
			...mapGetters('platformData/profile', ['profile']),
			lastQuestionID(){
				return this.$store.state.grant.questions.length
			},
			productName(){
				return this.productDisplayName(this.getProductCode())
			}
		},
		methods : {
			submit: async function(){
				var valid = await this.$validator.validateAll()
				if(valid){
					this.submitting = true
					var applicationInfo = {
						salutaion : this.getTitle(this.$store.state.servicesForm.titleID || this.profile.titleID),
						firstName : this.$store.state.servicesForm.firstName || this.profile.firstName,
						lastName : this.$store.state.servicesForm.lastName || this.profile.lastName,
						email : this.$store.state.servicesForm.email || this.profile.email,
						phone : this.$store.state.servicesForm.phone || this.profile.phone,
						phoneExt : this.$store.state.servicesForm.phoneExt || this.profile.phoneExt,
						mobilePhone : this.$store.state.servicesForm.mobile || this.profile.mobile,
						contactTime : this.$store.state.servicesForm.contactTime || this.profile.contactTime,
						principalName : this.$store.state.grant.principalName,
						principalEmail : this.$store.state.grant.principalEmail,
						referrer : this.$store.state.grant.referrer,
						priorGrant : this.$store.state.grant.priorGrant,
						schoolInfo : this.$store.state.schoolInfo,
						formInfo : this.addTrackingInfo(),
						grantQuestions : ''
					}
					this.$store.state.grant.questions.forEach(questionObject => {
						applicationInfo.grantQuestions += '<p><b>'+questionObject.id+'. '+questionObject.question+'</b></p>'
						applicationInfo.grantQuestions += '<p>'+questionObject.answer+'</p>'
					})
					if(Array.isArray(applicationInfo.schoolInfo.titles)){
						applicationInfo.schoolInfo.titles = applicationInfo.schoolInfo.titles.join()
					}
					if(Array.isArray(applicationInfo.schoolInfo.gradeLevels)){
						applicationInfo.schoolInfo.gradeLevels = applicationInfo.schoolInfo.gradeLevels.join(';')
					}
					this.$http.post(this.getGrantApplicationAPI(this.$store.state.grant.regCode), applicationInfo).then(response => {
						this.$store.commit('setAllowConfirm', true)
						this.$router.push({name:'GrantComplete'})
					}, error => {
						this.handleError(error)
						this.submitting = false
					})
				}
			},
		},
		created(){
			if(!this.$store.state.grant.questions.length){
				this.$router.replace({name:'GrantQuestion',params:{questionID:1}})
			}
		}
	}
</script>

