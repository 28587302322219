<template>
    <UserManagmentModal
        :value="value"
        :headline="$tc('classMgmt.transferHeader', selectedStudents.length)"
        :subHeadline="userCountOrName(selectedStudents,$tc('classMgmt.students', selectedStudents.length))"
        size="600"
        @closeDialogs="closeDialogs()"
    >
            <v-card-text>
                <v-data-table
                    v-if="selectedStudents.length" 
                    :headers="transferStudentHeaders"
                    :items="selectedStudents"
                    item-key="id"
                    :no-data-text="$t('classMgmt.noData')"
                    :rows-per-page-items="rowsPerPageItems('modal')"
                    :hide-actions="selectedStudents.length<rowsPerPageItems('modal')[0]"
                    class="elevation-2 mb-3 roundedCorners"
                    must-sort
                    dense
                >
                    <template v-slot:items="selectedStudents">
                        <tr>
                            <td>
                                {{selectedStudents.item.lastName}}, {{selectedStudents.item.firstName}}
                            </td>
                            <td>
                                {{selectedStudents.item.sisID}}
                            </td>
                            <td class="text-xs-right">
                                    <span>{{getGradeLevel(selectedStudents.item.gradeID)}}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <v-select
                    v-model="destinationSchoolID"
                    :items="otherSchools"
                    item-text="name"
                    item-value="id"
                    label="Destination School"
                    prepend-icon="school"
                ></v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="submitTransfer(selectedStudents,destinationSchoolID)" :disabled="!destinationSchoolID" class="elevation-0">
                    <v-icon>check</v-icon>&nbsp;{{$t('classMgmt.accept')}}
                </v-btn>
            </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import Constants from 'mixins/Constants'
    import { mapActions } from 'vuex'

	export default {
        name: 'TransferStudentModal',
        components: { UserManagmentModal },
        mixins: [Constants],
        props: {
            currentSchoolID: Number,
            selectedStudents: Array,
            schools: Array,
            rowsPerPageItems: Function,
            userCountOrName: Function,
            value: Boolean,
        },
        data() {
            return {
                destinationSchoolID: null,
                transferStudentHeaders: [
                    {text: this.$t('classMgmt.name'), value:'name', sortable:true, align: 'left'},
                    {text: this.$t('classMgmt.studentID'), value:'sisUserId', sortable:true, align: 'left'},
                    {text: this.$t('classMgmt.gradeLevel'), value:'gradeId', sortable:true, align: 'right'},
                ],
            }
        },
		computed : {
            otherSchools(){
                return this.schools.filter(school => {
                    return school.id !== this.currentSchoolID && school.typeId > 3
                })
            },
            platform(){
                 return this.$store.state.platform
            }
        },
        methods: {
            ...mapActions('platformData/students', ['transferStudents']),
            closeDialogs() {
                this.destinationSchoolID = null
                this.$emit('closeDialogs')
            },
            async submitTransfer(studentsToTransfer, institutionId){
                if (studentsToTransfer.length && institutionId) {
                    let students = []
                    studentsToTransfer.forEach(student => {
                        students.push({loginID: student.id, institutionID: this.currentSchoolID})
                        
                    })
                    await this.transferStudents({institutionId, students})
                    await this.$emit('updateModel', this.selectedSchoolID)
                    this.$emit('handleSuccess', 'Transfer Complete')
                    this.closeDialogs()
                }
            },
        }
	}
</script>

<style lang="less" scoped>
</style>