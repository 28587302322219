<template>
    <UserManagmentModal
        :value="value"
        :headline="headline"
        :subHeadline="institutionName"
        size="800"
        @closeDialogs="closeDialogs()"
    >
        <span v-if="value">
            <form @submit.prevent="inviteUsers(emails)">
                <v-card-text class="pb-0">
                    <Messaging outline class="mb-3"/>
                    <p v-html="instructions"/>
                    <v-layout  v-if="roleID == 3" class="mt-4 mb-3" wrap>
                        <v-flex xs12>
                            <span class="subheading grey--text text--darken-2" style>
                                {{ $t('classMgmt.products') }}:
                            </span>
                        </v-flex>
                        <v-flex xs12 class="ml-2">
                            <v-checkbox 
                                v-for="product in products" 
                                v-model="selectedProducts" 
                                :value="product.id"
                                :key="product.id"
                                class="d-inline-flex mr-4"
                                :color="productColor(product.id)"
                            >
                                <template v-slot:label>
                                    <img height="30" :src="productImgPath(product.id)" class="mr-2"/>
                                    <span :class="productColorClass(product.id)" class="title">
                                        {{ product.name }}
                                    </span>
                                </template> 
                            </v-checkbox>
                        </v-flex>
                        <v-flex v-for="product in selectedProductsWithMultipleSubscriptions" xs12 class="ml-2" :key="product">
                            <v-select
                                :items="getActiveInstitutionSubscriptions(product, selectedSchool)"
                                :label="'Select ' + productDisplayName(product)  + ' Subscription'"
                                v-model="selectedSubscriptions[product]"
                                item-text="name"
                                :return-object="true"
                                :name="productDisplayName(product) + ' subscription'"
                                v-validate="'required'"
                                :error-messages="errors.collect(productDisplayName(product) + ' subscription')"
                                :key="product.id">
                                <template v-slot:item="subscription">
                                    <h4 class="mr-auto">{{subscription.item.name.length > 55 ? subscription.item.name.slice(0, 54) + '&hellip;' : subscription.item.name}}</h4>
                                    {{subscription.item.settings ? `${new Date(subscription.item.settings.startsOn).toLocaleDateString("en-US")} - ${new Date(subscription.item.settings.expiresOn).toLocaleDateString("en-US")}` : ''}}
                                    <br/>
                                    {{subscription.item.settings ? createCapString(subscription.item) : ''}}
                                </template>
                            </v-select>
                        </v-flex>
                    </v-layout>
                    <v-layout wrap>
                        <v-flex xs12 class="mb-1">
                            <v-combobox
                                append-icon=""
                                box
                                clearable
                                data-vv-as="email"
                                :error-messages="errors.collect('email address')"
                                :hint="$t('classMgmt.emailsHint')"
                                persistent-hint
                                :label="$t('form.emails')"
                                multiple
                                name="email address"
                                rows="3"
                                :search-input.sync="search"
                                v-validate="'required|email:multi'"
                                v-model="emails"
                                @update:searchInput="formatInput()"
                                ref="emails"
                            >
                                <template v-slot:selection="{ attrs, item }">
                                    <v-tooltip top :disabled="!Boolean(chipColor(item))">
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-bind="attrs"
                                                close
                                                :color="chipColor(item)"
                                                small
                                                v-on="on"
                                                @input="removeChip(emails,item)"
                                            >
                                                {{item}}
                                            </v-chip>
                                        </template>
                                        <span v-if="!isEmail(item)">{{$t('error.malformedEmail')}}</span>
                                        <span v-else-if="isExistingEmail(item)">
                                            {{buildExistingUserHoverText(item)}}
                                        </span>
                                        <span v-else-if="isExistingInvite(item)">
                                            {{buildExistingInviteHoverText(item)}}
                                        </span>
                                    </v-tooltip>
                                </template>
                            </v-combobox>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn class="elevation-0 mr-2 mb-2" color="primary" type="submit" :disabled="disableSubmit">
                        <v-icon>send</v-icon>&nbsp;{{$tc('classMgmt.sendInvitation')}}
                    </v-btn>
                </v-card-actions>
            </form>
        </span>
    </UserManagmentModal>
</template>

<script>
    import Constants from 'mixins/Constants' 
    import Messaging from 'components/layout/Messaging'
    import ProductInfo from 'mixins/ProductInfo'
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import isEmail from 'validator/lib/isEmail';
    import SubscriptionHelper from 'mixins/SubscriptionHelper'

	export default {
        name: 'InviteUserModal',
        components: { Messaging, UserManagmentModal },
        mixins: [Constants, ProductInfo, SubscriptionHelper],
        props: {
            value: Boolean,
            invites: Array,
            isDistrictAdmin: Boolean,
            selectedSchool: Object,
            productIDs : Array,
            role : String,
            nonStudents : Array,
            seatInfo : Function,
            subSeatInfo : Function,
            tenantID: Number
        },
        data() {
            return {
                emails: [],
                roleID: null,
                roles : [
                    {id: 3, name: this.$tc('classMgmt.teachers',1)},
                    {id: 5, name: this.$tc('classMgmt.administrators',1)}
                ],
                search: {},
                selectedProducts: [],
                selectedProductsWithMultipleSubscriptions: [],
                selectedSubscriptions: {}
            }
        },
        computed : {
            availableSeats(){
                let counts = {}
                this.productIDs.forEach(productID => {
                    let seatInfo = this.seatInfo(productID)
                    counts[productID] = seatInfo.teacherDelta
                })
                return counts
            },
            availableSubscriptionSeats(sub){
                let counts = {}
                if (Object.keys(this.selectedSubscriptions).length) {
                    Object.values(this.selectedSubscriptions).forEach(sub => {
                        let seatInfo = this.subSeatInfo(sub.id)
                        counts[sub.id] = seatInfo.teacherDelta
                    })
                }
                return counts
            },
            disableSubmit() {
                return this.disableMultiSubscriptions || Boolean(this.seatsExceeded.length) || !this.emails.length || !this.selectedProducts.length && this.roleID == 3
            },
            disableMultiSubscriptions() {
                let disable = false
                // Check if any product IDs are present in selectedProductsWithMultipleSubscriptions and there is a selection
                if (this.roleID == 3 && this.selectedProductsWithMultipleSubscriptions.length){
                    this.selectedProductsWithMultipleSubscriptions.forEach(productId => {
                        if (!this.selectedSubscriptions[productId]) {
                            disable = true;
                        }
                    });
                }
                return disable
            },
            headline(){
                if(this.role=='administrators'){
                    if(this.isDistrictAdmin){
                        return this.$t('classMgmt.inviteDistrictAdmins')
                    } else {
                        return this.$t('classMgmt.inviteSchoolAdmins')
                    }
                } else {
                    return this.$t('classMgmt.inviteTeachers')
                }
            },
            institutionName(){
                return this.selectedSchool.name
            },
            instructions(){
                if(this.role=="teachers"){
                    return this.$t('classMgmt.inviteInstructionsTeachers')
                } else {
                    return this.$t('classMgmt.inviteInstructionsAdmins')
                }
            },
            platform(){
                return this.$store.state.platform
            },
            products(){
                let products = []
                this.productIDs.forEach(productID =>{
                    // ProductIDs can be trusted for Employees and CTs
                    this.subscriptions.forEach(sub => {
                        if(sub.productId==productID && sub.isActive && !sub.isExpired && !products.map(p => p.id).includes(sub.productId)){
                            products.push({id:productID, name:this.productDisplayName(productID)})
                        }
                    }) 
                })
                return products
            },
            seatsExceeded(){
                let tooManySeats = []
                if(this.roleID == 3){
                    Object.keys(this.selectedSubscriptions).length
                    ? Object.values(this.selectedSubscriptions).forEach(sub => {
                        if(this.newUsersPerProduct[sub.productId]>[this.availableSubscriptionSeats[sub.id]]){
                            tooManySeats.push(this.seatInfo(sub.productId))
                        }
                    })
                    : this.selectedProducts.forEach(productID => {
                        if(this.newUsersPerProduct[productID]>[this.availableSeats[productID]]){
                            tooManySeats.push(this.seatInfo(productID))
                        }
                    })
                }
                return tooManySeats
            },
            newUsersPerProduct(){
                let newUsers = {}
                if(this.selectedProducts.length){
                    // Set Count For Each Product Selected to 0
                    this.selectedProducts.forEach(productID => {
                        newUsers[productID] = 0
                    })
                    if(this.roleID){
                        // Once Role is Chosen Calculate Counts
                        this.emails.forEach(email => {
                            let matchingUsers = this.existingEmailAndRole(email)
                            if(matchingUsers.length){
                                // Check Products of Matching Accounts
                                matchingUsers.forEach(user => {
                                    this.selectedProducts.forEach(productID => {
                                        if(!user.licenses.filter(l => l.matchingLicense).map(l => l.productId).includes(productID)){
                                            ++newUsers[productID]
                                        }
                                    })
                                })
                            } else {
                                // Increment Products For New Users
                                this.selectedProducts.forEach(productID => {
                                    ++newUsers[productID]
                                })
                            }
                        })
                    }
                }
                return newUsers
            }
        },
        watch : {
            seatsExceeded(val){
                let message = ''
                val.forEach(product => {
                    product.newSeats = this.newUsersPerProduct[product.productId]
                    product.teacherOverCap = product.teacherDelta * -1 + product.newSeats
                    if(message != ''){
                        message += '<br/><br/>'
                    }
                    message += this.$t('classMgmt.overTeacherLimit',product)
                })
                this.$store.commit('setMessageError', message)   
            },
            roleID(ID){
                if(ID==5){
                    this.selectedProducts = this.productIDs
                }
            },
            value(){
                this.reset()
            },
            selectedProducts(newVal, oldVal){
                const removedProduct = oldVal.filter(p => !newVal.includes(p))[0]
                const addedProduct = newVal.filter(p => !oldVal.includes(p))[0]
                if (addedProduct && this.getActiveInstitutionSubscriptions(addedProduct, this.selectedSchool).length > 1) {
                    this.selectedProductsWithMultipleSubscriptions.push(addedProduct)
                } else if (removedProduct && this.getActiveInstitutionSubscriptions(removedProduct, this.selectedSchool).length > 1) {
                    this.selectedProductsWithMultipleSubscriptions = this.selectedProductsWithMultipleSubscriptions.filter(p => p !== removedProduct);
                    delete this.selectedSubscriptions[removedProduct]
                }
            }
        },
        methods: {
            buildExistingInviteHoverText(email){
                let invite = this.existingInviteAndRole(email)
                let roleMatched = true
                if(!invite.length){
                    roleMatched = false
                    invite = this.existingInvite(email)
                }
                invite = invite[0]
                let msg = ""
                let data = {
                    email : invite.email,
                    role : this.getRoleName(invite.roleId).toLowerCase()
                }
                msg = this.$t('classMgmt.existingInvite',data)
                let newRoleID = this.roleID
                if(!newRoleID){
                    // This space intentionally left blank
                } else if(roleMatched){
                    msg += ' ' + this.$t('classMgmt.noInviteSent')
                } else {
                    msg += ' ' + this.$t('classMgmt.additionalInviteSent', {role:this.getRoleName(newRoleID).toLowerCase()})
                }
                return msg
            },
            buildExistingUserHoverText(email){
                let users = this.existingEmailAndRole(email)
                let roleMatched = true
                if(!users.length){
                    roleMatched = false
                    users = this.existingEmail(email)
                }
                let msg = ""
                if(users.length == 1){
                    const user = users[0]
                    let data = {
                        firstName : user.firstName,
                        lastName : user.lastName,
                        role : this.getRoleName(user.roleID).toLowerCase()
                    }
                    msg = this.$t('classMgmt.existingEmail',data)
                    let newRoleID = this.roleID
                    if(!newRoleID){
                        // This space intentionally left blank
                    } else if(user.roleID != newRoleID){
                        msg += ' ' + this.$t('classMgmt.newRole', {role:this.getRoleName(newRoleID).toLowerCase()})
                    } else {
                        let selectedProducts = this.selectedProducts
                        if(selectedProducts && selectedProducts.length){
                            let currentProducts = user.licenses.filter(l => l.matchingLicense).map(l => l.productId)
                            let difference = selectedProducts.filter(productID => !currentProducts.includes(productID))
                            if(difference.length){
                                let products = this.productDisplayName(difference)
                                msg += ' ' + this.$t('classMgmt.newProductAccess', {products})
                            } else {
                                msg += ' ' + this.$t('classMgmt.noProductChange')
                            }
                        } else {
                            msg += ' ' + this.$t('classMgmt.mayGetProduct')
                        }
                    }
                } else {
                    let data = {
                        count : users.length
                    }
                    let newRoleID = this.roleID
                    if(roleMatched){
                        data.role = this.getRoleName(this.roleID).toLowerCase()
                        msg = this.$t('classMgmt.existingEmailsRole',data)
                        if(this.selectedProducts.length){
                            let products = this.productDisplayName(this.selectedProducts)
                            msg = msg + ' ' + this.$t('classMgmt.gainProducts',{products})
                        }
                    } else if(newRoleID){
                        data.role = this.getRoleName(users[0].roleID).toLowerCase()
                        msg = this.$t('classMgmt.existingEmailsRole',data)
                        msg += ' ' + this.$t('classMgmt.newRole', {role:this.getRoleName(newRoleID).toLowerCase()})
                    } else {
                        msg = this.$t('classMgmt.existingEmails',data)
                    }
                }
                return msg
            },
            chipColor(email){
                if(!isEmail(email)){
                    return 'error lighten-2'
                } else if(this.isExistingEmailAndRole(email)) {
                    return 'success lighten-5'
                } else if(this.isExistingEmail(email)) {
                    return 'info lighten-5'
                } else if(this.isExistingInviteAndRole(email)) {
                    return 'success lighten-5'
                } else if(this.isExistingInvite(email)) {
                    return 'info lighten-5'
                } else {
                    return ''
                }
                
            },
            closeDialogs() {
                this.$emit('closeDialogs', ['inviteUserModal'])
            },
            createCapString(sub) {
                //gizmos with teacher plus license-show teacher cap
                if (sub.productId == 3 && sub.licensingTypeId === 10 && sub.teachersCap > 0 && sub.teachersCap !== Infinity) {
                    return `${sub.teachersUsed}/${sub.teachersCap} Seats Used`
                //Frax or reflex with teacher license-show students cap
                } else if (sub.productId <= 2 && sub.licensingTypeId === 2 && sub.studentsCap > 0 && sub.studentsCap !== Infinity){
                    return `${sub.studentsUsed}/${sub.studentsCap} Seats Used`
                } else {
                    return ''
                }
            },
            displayInvites() {
                let tabID = this.tabIDs.ADMIN
                if(this.roleID==3){
                    tabID = this.tabIDs.TEACHER
                }
                this.$emit('displayInvites',tabID)
                this.closeDialogs()
            },
            existingEmail(email){
                return this.nonStudents.filter(user => {
                    if(user.email){
                        return user.email.toLowerCase() == email.toLowerCase()
                    } else {
                        return false
                    }
                })
            },
            existingEmailAndRole(email){
                const roleID = this.roleID
                if(roleID){
                    return this.nonStudents.filter(user => {
                        if(user.email){
                            return user.email.toLowerCase() == email.toLowerCase() && user.roleID == roleID
                        } else {
                            return false
                        }
                    })
                }
                return []
            },
            existingInvite(email){
                return this.invites.filter(user => {
                    if(user.email){
                        return user.email.toLowerCase() == email.toLowerCase()
                    } else {
                        return false
                    }
                })
            },
            existingInviteAndRole(email){
                const roleID = this.roleID
                if(roleID){
                    return this.invites.filter(user => {
                        if(user.email){
                            return user.email.toLowerCase() == email.toLowerCase() && user.roleId == roleID
                        } else {
                            return false
                        }
                    })
                }
                return []
            },
            formatInput(){
                let search = this.search
                let delimiters = /[\s,]+/
                if (search && search.split(delimiters).length > 1) {
                    this.emails = this.emails.concat(
                        search.split(delimiters).filter(email => {
                            return email.trim() && !this.emails.includes(email.trim())
                        })
                    )
                    this.search = ''
                }
            },
            getRoleName(id){
                const role =  this.roles.find(role => {
                    return role.id == id
                })
                if(role){
                    return role.name
                } else {
                    return ''
                }
            },
            async inviteUsers(emails){
                let valid = await this.$validator.validateAll()
                if(valid){
                    let newEmails = []
                    let newUsers = []
                    let existingEmails = []
                    let existingUsers =[]
                    let existingInvites = []
                    // Sort Emails
                    emails.forEach(email => {
                        if(this.isExistingEmailAndRole(email)){
                            existingEmails.push(email)
                        } else if(this.isExistingInviteAndRole(email)) {
                            existingInvites.push(email)
                        } else {
                            newEmails.push(email)
                        }
                    })
                    // Invite New Users
                    if(newEmails.length){
                        const newUsersData = {
                            emails: newEmails,
                            tenantId: this.tenantID,
                            institutionId: this.selectedSchool.id,
                            roleId: this.roleID,
                            isInvite: true
                        }
                        //building subscriptionIds
                        if (this.selectedProductsWithMultipleSubscriptions.length) {
                            //use selectedSubscriptions for inviting teachers, give invited admins all school subscriptions or all subscriptions if district
                            const subs = this.roleID == 3 
                                ? Object.values(this.selectedSubscriptions).map(s => s.id) 
                                : this.selectedSchool.typeId > 3 
                                    ? this.selectedSchool.products.map(s => s.subscriptionId) 
                                    : this.subscriptions.map(s => s.id)
                            //need to also append selected products if they are chosen but arent multiple sub
                            const missingProducts = this.selectedProducts.filter((product) => {
                                return !this.selectedProductsWithMultipleSubscriptions.some((multiSub) => multiSub === product)
                            });
                            if (missingProducts.length) {
                                missingProducts.forEach(p => {
                                    subs.push(this.subscriptions.find(sub => sub.productId === p).id)
                                })
                            }
                            newUsersData.subscriptionIds = subs
                        } else {
                            let subs = []
                            if (this.roleID == 5) {
                                //use the selected schools products for inviting teachers, give invited admins all school subscriptions or all subscriptions if district
                                this.selectedSchool.typeId > 3 
                                    ? subs = this.selectedSchool.products.map(s => s.subscriptionId) 
                                    : subs = this.subscriptions.map(s => s.id)
                            } else {
                                this.selectedProducts.forEach(p => {
                                    subs.push(this.selectedSchool.products.find(sub => sub.productId === p).subscriptionId)
                                })
                            }
                            newUsersData.subscriptionIds = subs
                        }
                        newUsers = await this.platform.createUsers(newUsersData)
                    }
                    // Add Access For Existing Users
                    let errors = []
                    if(existingEmails.length){
                        existingUsers = this.nonStudents.filter(user => {
                            return existingEmails.includes(user.email)
                        })
                        await Promise.all(
                            this.selectedProducts.map(async (productID)=>{
                                let needsAccess = existingUsers.filter(user => {
                                    return !user.licenses.filter(l => l.matchingLicense).map(l => l.productId).includes(productID)
                                })
                                if(needsAccess.length){
                                    let IDs = []
                                    needsAccess.forEach(user=>{
                                        let id = user.id || user.login.id
                                        IDs.push(id)
                                    })
                                    let sub = this.subscriptions.find(sub => {
                                        return productID == sub.productId
                                    })
                                    const requestData = {                                        
                                        tenantId: this.tenantID,
                                        institutionId: this.selectedSchool.id,
                                        subscriptionIds: sub.id,
                                        loginIds: IDs,
                                    }
                                    try {
                                        await this.platform.addUsersToProduct(requestData)
                                        return {productID, users: needsAccess}
                                    } catch (error) {
                                        errors.push(error)
                                    }
                                }
                            })
                        )
                    }
                    // Resend Existing Invites
                    if(existingInvites.length){
                        let existingInvitedUsers = this.invites.filter(invite => {
                            return existingInvites.includes(invite.email)
                        })
                        await Promise.all(
                            existingInvitedUsers.map(async (invitedUser)=>{
                                this.platform.resendInvite({loginId:invitedUser.id})
                            })
                        )
                    }
                    // Update Model
                    this.$emit('updateModel')
                    this.$emit('updateInvites')
                    this.$emit("handleSuccess", this.$tc('classMgmt.inviteSuccess', newEmails.length+existingInvites.length))
                    this.displayInvites()
                }
            },
            isEmail(email){
                return isEmail(email)
            },
            isExistingEmail(email){
                return Boolean(this.existingEmail(email).length)
            },
            isExistingEmailAndRole(email){
                return Boolean(this.existingEmailAndRole(email).length)
            },
            isExistingInvite(email){
                return Boolean(this.existingInvite(email).length)
            },
            isExistingInviteAndRole(email){
                return Boolean(this.existingInviteAndRole(email).length)
            },
            removeChip(list, item) {
                list.splice(list.indexOf(item), 1);
                list = [...list];
            },
            reset(){
                this.emails = []
                this.selectedProductsWithMultipleSubscriptions = []
                this.selectedSubscriptions = {}
                if(this.role=='administrators'){
                    this.roleID = 5
                } else {
                    this.roleID = 3
                    this.selectedProducts = []
                }
                this.errors.items=[]
            }
        }
	}
</script>

<style lang="less" scoped>
    .el-orange-bg {
        background:#F58220
    }
    .ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .summary-header {
        display: inline-block;
        margin-top: 8px;
        .product-wrapper {
            display: inline-block;
            padding: 0 2px;
        }
        img.inline-product {
            height: 18px;
            display: inline-block;
            margin: 0 2px -4px;
        }
    }
    img.summary-icon {
        width: 40px;
        height: 40px;
        border-radius: 0;
        padding-left: 0;
        margin-bottom: -10px;
        display: inline-block;
        float: left;
    }
    .summary-icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        padding: 4px;
        margin-right: 10px;
        margin-bottom: -10px !important;
    }
    img.inline-product-small {
        height: 14px;
        display: inline-block;
        margin: 0 2px -2px;
    }
</style>