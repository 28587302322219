<template>
	<v-flex>
		<v-layout wrap>
			<Headline :headline="$t('catalog.newSubscriptions')"></Headline>
		</v-layout>
		<v-flex v-if="catalog.length">
			<v-layout v-for="sub in catalog" :key="sub.orderItemID">
				<v-flex xs md2 lg1 class="text-sm-center text-md-left">
					<img src="../../assets/reflex/img/logo-leaf.svg" :alt="sub.description" class="catalogIcon"/>
				</v-flex>
				<v-flex xs md10 lg11>
					<v-layout wrap>
						<v-flex xs12 md4 lg6 xl7 class="pl-3 pt-3 title">
							{{productDisplayName(sub.productID)}} {{$t('catalog.subscription')}}
						</v-flex>
						<v-flex xs12 sm4 md3 lg2 class="pl-3">
							<v-text-field
								v-model="newSubs[sub.orderItemID+'-seats']"
								:label="$t('catalog.numSeats')"
								v-validate="'numeric|min_value:10|integer'"
								:name="sub.orderItemID+'-seats'"
								:error-messages="cleanErrors(errors.collect(sub.orderItemID+'-seats'), 'The '+sub.orderItemID+'-seats field must be 10 or more.', 'Minimum of 10 students')"
								:disabled="inCart(sub.orderItemID)"
								data-vv-validate-on="change">
							</v-text-field>
			      		</v-flex>
			      		<v-flex xs12 sm4 md3 lg2 class="pl-3">
			        		<v-autocomplete
								v-model="newSubs[sub.orderItemID+'-years']"
								:label="$t('catalog.numYears')"
								:disabled="inCart(sub.orderItemID)"
								v-bind:items="duration"
								item-text="label"
								item-value="value">
							</v-autocomplete>
			      		</v-flex>
			      		<v-flex xs12 sm4 md2 xl1 class="text-xs-right">
							<v-btn v-if="!inCart(sub.orderItemID)" large color="primary" @click="prepForCart(sub)" :disabled="!fieldsFilled(sub.orderItemID)">
								<v-icon v-if="!singleItem">add_shopping_cart</v-icon>
								<span v-else>{{$t('catalog.buy')}}</span>
							</v-btn>
							<v-btn v-else large color="success" disabled>
								<v-icon>check</v-icon>
							</v-btn>
			      		</v-flex>
			     	</v-layout>
			    </v-flex>
			</v-layout>
		</v-flex>
		<v-flex v-else class="text-xs-center grey--text my-5">
			<p>{{$t('catalog.noItems')}}</p>
			<div>{{$t('catalog.mySubs')}} <router-link :to="{name:'Subscriptions'}">{{$t('catalog.currentSubs')}}</router-link>.</div>
		</v-flex>
		<v-layout wrap class="text-xs-center mt-3">
			<v-flex sm6 order-sm2 class="text-sm-right" v-if="!singleItem">
				<v-btn large color="primary" v-if="loggedIn" :to="{name:'Cart'}" :disabled="!shoppingCartSize">
					{{$t('navigation.cart')}}
				</v-btn>
				<v-btn large color="primary" v-else :to="{name:'CheckoutAccountInfo'}" :disabled="!shoppingCartSize">
					{{$t('catalog.createAccount')}}
				</v-btn>
			</v-flex>
			<v-flex :sm6="!singleItem" :class="{'text-sm-left':!singleItem}" v-if="loggedIn">
				<v-btn large outline color="primary" :to="{name:'Subscriptions'}">
					{{$t('catalog.currentSubs')}}
				</v-btn>
			</v-flex>
			<v-flex :sm6="!singleItem" :class="{'text-sm-left':!singleItem}" v-else>
				<v-btn large outline color="primary" :to="{name:'SecureProductCatalog',params:{clearCart:true}}">
					{{$t('catalog.haveAccount')}}
				</v-btn>
			</v-flex>
		</v-layout>
		<SiteLicenseModal></SiteLicenseModal>
	</v-flex>
</template>

<script>
	import APIs from 'mixins/APIs'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import Headline from 'components/layout/Headline'
	import LoginDetector from 'mixins/LoginDetector'
	import ProductInfo from 'mixins/ProductInfo'
	import ShoppingCart from 'mixins/ShoppingCart'
	import SiteLicenseModal from 'components/ecommerce/SiteLicenseModal'
	import { mapGetters } from 'vuex'
	export default {
		name: 'ProductCatalog',
		components: {
			Headline,
			SiteLicenseModal
		},
		mixins: [APIs, EnvironmentDetector, LoginDetector, ProductInfo, ShoppingCart],
		data(){
			return {
				newSubs : {},
				duration: [
					{label: '1 '+this.$t('catalog.duration'), value: 1},
					{label: '2 '+this.$t('catalog.duration'), value: 2},
					{label: '3 '+this.$t('catalog.duration'), value: 3}
				],
			}
		},
		computed: {
			...mapGetters('platformData/profile', ['profile']),
			catalog(){
				if(this.$store.state.store.orderItems){
					let catalog = []
					var matchesFilter = this.matchesFilter
					this.$store.state.store.orderItems.forEach(function(item){
						if(item.orderItemType == "Purchase" && matchesFilter(item)){
							catalog.push(item)
						}
					})
					return catalog
				} else {
					return []
				}
			},
			singleItem(){
				return this.catalog.length == 1
			},
				secure(){
				return this.$route.meta.secure
			},
			subHeading(){
				if(this.secure){
					return this.profile.username
				} else {
					return this.$t('catalog.guest')
				}
			}
		},
    watch: {
    	shoppingCartSize(){
    		if(this.shoppingCartSize==1 && this.singleItem){
    			if(this.secure){
    				this.$router.push({name:'Cart'})
    			} else {
    				this.$router.push({name:'CheckoutAccountInfo'})
    			}
    		}
    		let newSubs = this.newSubs
				for(var cartItemID in this.shoppingCart){
					let cartItem = this.shoppingCart[cartItemID]
					newSubs[cartItemID+'-seats'] = cartItem.seats
    			newSubs[cartItemID+'-years'] = cartItem.fullYears
				}
    	},
    },
    methods: {
		cleanErrors(errors, badWord, goodWord){
			let cleanErrors = []
			errors.forEach(function(error){
				cleanErrors.push(error.replace(badWord, goodWord))
			})
			return cleanErrors
		},
    	prepForCart(sub){
    		let seats = this.newSubs[sub.orderItemID+'-seats']
    		let years = this.newSubs[sub.orderItemID+'-years']
    		this.addToCart(sub.orderItemID, seats, years)
    	},
    	fieldsFilled(ID){
    		var filled = false
    		let seats = Number(this.newSubs[ID+'-seats'])
			let years = Number(this.newSubs[ID+'-years'])
			let errors = this.errors.collect(ID+'-seats').length || this.errors.collect(ID+'-years').length
    		if(!errors && seats && seats>0 && years && years>0){
    			filled = true
    		}
    		return filled
    	},
    	imagePath(productID){
    		return "../../assets/reflex/img/logo-leaf.svg"
    	},
    	loadCatalog(secured){
    		this.$http.get(this.getCatalogAPI(secured),this.jtiHeader).then(response => {
					this.$store.commit('setOrderItems', response.data.ArrayList)
				}, error => {
					console.log(error)
				})
    	},
    	matchesFilter(item){
    		var match = true
    		if(this.$route.query.p){
    			match = this.$route.query.p == item.productID
    		}
    		return match
    	}
    },
		created (){
			if(this.$route.query.u){
				this.$store.commit('setNavAccountType',this.$route.query.u)
			}
			if(!this.secure && this.loggedIn){
				this.$router.replace({ name: 'SecureProductCatalog'})
				this.loadCatalog(true)
			} else {
				this.loadCatalog(this.secure)
			}
			if(this.$route.params.clearCart){
				this.resetCart()
			}
		}
	}
</script>

<style lang="less">
	.catalogIcon{
		width: 60px;
	}
</style>
