<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.resetPasswordHeader')"
        @closeDialogs="closeDialogs()"
    >
        <v-card-text>
            {{$t('classMgmt.thisWillSendAnEmailTo')}} <strong>{{editedNonStudent.email}}</strong> {{$t('classMgmt.toAllowThisUserToReset')}}
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="resetPassword()" class="elevation-0">
                <v-icon>send</v-icon>&nbsp;{{$t('classMgmt.resetPassword')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import APIs from 'mixins/APIs'
    import UserManagmentModal from 'components/layout/UserManagmentModal'

	export default {
        name: 'ResetPasswordModal',
        components: { UserManagmentModal },
        mixins: [APIs],
        props: {
            selectedUsers: Array,
            value: Boolean,
        },
        data() {
            return {
                editedNonStudent: {},
            }
        },
		computed : {
             platform(){
                 return this.$store.state.platform
            }
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs')
            },
            resetPassword(){
                this.$http.post(this.getRecoveryAPI(), {"email": this.editedNonStudent.email.trim(), "lang": this.$locale.current()}).then(response => {
                    this.closeDialogs()
                }, error => {
                    console.log(error)
                })
            },
        },
        watch: {
            value(val){
                if (val){
                    this.editedNonStudent = this.selectedUsers[0]
                }
            }
        }
	}
</script>

<style lang="less" scoped>
</style>