<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-icon color="info" :small="small" v-on="on" class="text--lighten-2">info</v-icon>
        </template>
        <span>Some ExploreLearning products feature localization.<br/>Those products will use this language preference.</span>
    </v-tooltip>
</template>

<script>
    export default {
        name: 'LanguageTooltip',
        props: {
            small : {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                
            }
        }
    }
</script>