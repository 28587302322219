<template>
	<v-flex>
		<v-layout wrap>
			<Headline :headline="$t('cart.header')" :logo="currentEnvironment"></Headline>
		</v-layout>
		<v-data-table v-if="orderProcessed" :headers='headers' :items='Object.values(shoppingCart)' hide-actions>
			<template slot="items" slot-scope="props">
	      <td>
	      	<span v-if="props.item.orderItem.orderItemType=='Purchase'">
	      		{{$t('cart.new')}} {{productDisplayName(props.item.orderItem.productID)}} {{$t('cart.subscription')}}
	      	</span>
	      	<span v-if="props.item.orderItem.orderItemType=='Expansion'">
	      		{{productDisplayName(props.item.orderItem.productID)}} {{$t('cart.subscription')}} {{$t('cart.expansion')}}
	      	</span>
	      	<span v-if="props.item.orderItem.orderItemType=='ExpandPending'">
	      		{{$t('cart.pending')}} {{productDisplayName(props.item.orderItem.productID)}} {{$t('cart.subscription')}} {{$t('cart.expansion')}}
	      	</span>
	      	<span v-if="props.item.orderItem.orderItemType=='Renew'">
	      		{{productDisplayName(props.item.orderItem.productID)}} {{$t('cart.subscription')}} {{$t('cart.renewal')}}
	      	</span>
	      </td>
	      <td class="text-xs-center">{{props.item.seats}}</td>
	      <td class="text-xs-center">
	      	<span v-if="props.item.fullYears">{{props.item.fullYears}} {{$t('cart.years')}}</span>
	      	<span v-if="props.item.fullYears && props.item.roundedMonths"><br/></span>
	      	<span v-if="props.item.roundedMonths">{{props.item.roundedMonths}} {{$t('cart.months')}}</span>
	      	<sup v-if="props.item.durationChanged" @click="showChangeModal(props.item)"><v-icon class="priceExplanation" color="warning">warning</v-icon></sup>
	      </td>
				<td class="text-xs-center relative">
					<span v-if="props.item.normalPricePerSeat*props.item.fullYears!=props.item.pricePerSeatForTotalDuration">
						<span v-if="props.item.fullYears>0" class="strike">${{(props.item.normalPricePerSeat*props.item.fullYears).toFixed(2)}}</span>
						<span v-else class="strike">${{props.item.normalPricePerSeat.toFixed(2)}}</span>
						<sup @click="showCostModal(props.item)"><v-icon class="priceExplanation" color="info">help_outline</v-icon></sup>
						<br/>
					</span>
					${{props.item.pricePerSeatForTotalDuration.toFixed(2)}}
				</td>
				<td class="text-xs-center">${{props.item.totalPrice.toFixed(2)}}</td>
				<td class="text-xs-right">
					<v-btn icon flat @click="removefromCart(props.item.orderItem.orderItemID)">
						<v-icon>remove_shopping_cart</v-icon>
					</v-btn>
				</td>
	    </template>
	    <template slot="footer">
	    	<td colspan="3" class="pt-1">
	    		<v-layout wrap>
		    		<v-flex xs6>
			    		<v-text-field
								v-model="promoCode"
			          :label="$t('form.promoCode')"
			          v-validate="'noTags'"
			          name="promo code"
			          :error-messages="errors.collect('promo code')"
			        ></v-text-field>
			       </v-flex>
			       <v-flex xs4>
			        <v-btn outline round small color="primary" class="mt-3" @click="applyPromo" :disabled="!promoCode">
								{{$t('cart.apply')}}
							</v-btn>
						</v-flex>
					</v-layout>
	    	</td>
	      <td colspan="4" class="text-xs-right">
	      	<v-flex v-for="promo in promos" key="promo.promoCode" class="red--text">
	      		{{$t('cart.promotion')}}: {{promo.promoCode}}<span v-if="promo.promoMessage"> - {{promo.promoMessage}}</span>
	      	</v-flex>
	        <strong>{{$t('cart.total')}}: ${{totalPrice.toFixed(2)}}</strong>
	      </td>
	    </template>
		</v-data-table>
		<v-layout>
			<v-flex sm6>
				<v-btn large color="grey lighten-2" :to="{name:'SecureProductCatalog'}">
					{{$t('cart.back')}}
				</v-btn>
			</v-flex>
			<v-flex sm6 class='text-xs-right'>
				<v-btn large color="primary"  @click="advance">
					{{$t('cart.next')}}
				</v-btn>
			</v-flex>
		</v-layout>
		<v-dialog v-model="dialog.display" v-if="dialog.item" max-width="480px">
      <v-card>
      	<v-card-title class="headline grey--text text--darken-2">{{$t('cart.costExplanation')}}</v-card-title>
        <v-card-text>
        	<v-layout wrap mb-2>
						<v-flex xs8 class="text-xs-right">{{$t('cart.perStudent')}}:</v-flex>
						<v-flex xs4 class="price" pl-2>${{this.dialog.item.normalPricePerSeat.toFixed(2)}}</v-flex>
					</v-layout>
					<v-layout wrap mb-2 v-if="this.dialog.item.promoCodeApplied">
						<v-flex xs8 class="text-xs-right">{{$t('cart.perStudentPromo')}}:</v-flex>
						<v-flex xs4 class="price" pl-2>${{this.dialog.item.pricePerSeat.toFixed(2)}}</v-flex>
					</v-layout>
					<v-layout wrap mb-2 v-if="this.dialog.item.fullYears>1">
						<v-flex xs8 class="text-xs-right">{{$t('cart.perStudentYears')}}&nbsp;{{this.dialog.item.fullYears}}&nbsp;{{$t('cart.years')}}:</v-flex>
						<v-flex xs4 class="price" pl-2>${{(this.dialog.item.pricePerSeat*this.dialog.item.fullYears).toFixed(2)}}</v-flex>
					</v-layout>
					<v-layout wrap mb-2 v-if="this.dialog.item.prorated">
						<v-flex xs8 class="text-xs-right">{{$t('cart.perStudentMonths')}}&nbsp;{{this.dialog.item.roundedMonths}}&nbsp;{{$t('cart.months')}}:</v-flex>
						<v-flex xs4 class="price" pl-2>${{this.dialog.item.pricePerSeatForRemainingActual.toFixed(2)}}</v-flex>
					</v-layout>
					<v-layout wrap mb-2 v-if="this.dialog.item.prorated">
						<v-flex xs8 class="text-xs-right">{{$t('cart.perStudentTotal')}}</v-flex>
						<v-flex xs4 class="price" pl-2>${{this.dialog.item.pricePerSeatForTotalDuration.toFixed(2)}}</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs8 class="text-xs-right">{{$t('cart.priceTotal')}}&nbsp;{{this.dialog.item.seats}}&nbsp;{{$t('cart.students')}}:</v-flex>
						<v-flex xs4 class="price" pl-2>${{this.dialog.item.price.toFixed(2)}}</v-flex>
					</v-layout>
        </v-card-text>
        <v-card-actions>
        	<v-spacer></v-spacer>
          <v-btn color="primary" flat @click.stop="dialog.display=false">
          	{{$t('navigation.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="change.display" v-if="change.item" max-width="480px">
      <v-card>
      	<v-card-title class="headline grey--text text--darken-2">{{$t('cart.durationChangedHeader')}}</v-card-title>
        <v-card-text>
        	<v-flex xs12>
        		{{$t('cart.durationChangedMsg')}}: <span class="red--text">{{this.change.item.promoCode}}</span>
        	</v-flex>
        </v-card-text>
        <v-card-actions>
        	<v-spacer></v-spacer>
          <v-btn color="primary" flat @click.stop="change.display=false">
          	{{$t('navigation.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
	</v-flex>
</template>

<script>
	import APIs from 'mixins/APIs'
	import Headline from 'components/layout/Headline'
	import LoginDetector from 'mixins/LoginDetector'
	import OrderLoader from 'mixins/OrderLoader'
	import ProductInfo from 'mixins/ProductInfo'
	import ShoppingCart from 'mixins/ShoppingCart'
	import { mapGetters } from 'vuex'
	export default {
		name: 'ShoppingCart',
		components: {
			Headline
		},
		mixins: [APIs, LoginDetector, OrderLoader, ProductInfo, ShoppingCart],
		data(){
  		return {
  			dateFormat: {month: 'short', day: 'numeric', year: 'numeric'},
  			headers: [
  				{text: this.$t('cart.product'), value:'product', sortable: false, align: 'left'},
  				{text: this.$t('cart.students'), value:'seats', sortable:false, align: 'center'},
  				{text: this.$t('cart.duration'), value:'years', sortable:false, align: 'center'},
  				{text: this.$t('cart.unitCost'), value: 'pricePerSeat', sortable: false, align: 'center'},
  				{text: this.$t('cart.cost'), value: 'totalPrice', sortable: false, align: 'center'},
  				{text: this.$t('cart.remove'), value: 'remove', sortable: false, align: 'right'}
  			],
  			promoCode : '',
  			totalPrice : 0
  		}
  	},
  	computed: {
		...mapGetters('platformData/profile', ['profile']),
  		promos(){
  			let promos = []
  			for(let index in this.shoppingCart){
  				let item = this.shoppingCart[index]
  				if(item.promoCodeApplied){
  					promos.push({promoCode:item.promoCode,promoMessage:item.promoCodeMessage})
  				}
  			}
  			return promos
  		},
  		totalSeats(){
  			let totalSeats = 0
  			for(let index in this.shoppingCart){
  				let item = this.shoppingCart[index]
  				totalSeats += item.seats
  			}
  			return totalSeats
  		}
  	},
    watch: {
    	shoppingCartSize(size){
    		if(size==0){
    			this.$router.push({name:'SecureProductCatalog'})
    		}
    	}
    },
    methods: {
    	applyPromo(){
	    	if(this.promoCode){
	    		this.errors.clear()
		    	this.$http.get(this.getAddUpdateCheckoutPromoCodeAPI(this.promoCode),this.jtiHeader).then(response => {
						this.$store.commit('setShoppingCart', response.data.OrderDto.orderLineItemMap)
						this.totalPrice = response.data.OrderDto.totalPrice
						let validPromo = false
						let appliedCode = this.promoCode
						this.promos.forEach(function(promo){
							if(promo.promoCode==appliedCode){
								validPromo = true
							}
						})
						if(validPromo){
							this.promoCode = ""
						} else {
							this.errors.add('promo code', this.$t('error.invalidPromoCode'))
						}
					}, error => {
						console.log(error)
					})
		    }
    	},
    	advance(){
    		if(this.$store.state.navigation.checkoutSkipSchool){
    			this.$router.push({name:"BillingInfo"})
  			} else {
  				this.$router.push({name:"CheckoutSchoolInfo"})
    		}
    	}
    },
		created (){
			this.loadOrder()
		}
	}
</script>


<style lang="less">
	.strike{
		text-decoration: line-through;
	}
	.priceExplanation{
		font-size: 1.5em !important;
		top:16px;
		&:hover{
			cursor: pointer;
		}
	}
	.price {
		font-weight: 500;
	}
</style>
