<template>
    <v-layout v-if="!display" class="my-5">
        <v-flex class="text-xs-center">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </v-flex>
    </v-layout>
    <v-container v-else pa-0 grid-list-xl class="view-height-home" id="acct-home-container">
        <ElTitleBar
            :title="welcomeTitle"
            :subTitle="welcomeSubTitle"
            parseMarkdown="*"
            class="acct-home-header"
            :buttons="educatorsHub"
            :crumbs="acctHomeCrumb"
            >
        </ElTitleBar>
        <Messaging class="mb-3 pa-0"></Messaging>
        <div class="home-panels-wrapper">
            <div class="acct-home-panels" v-if="!isEmployee">
                <ElPanel
                    :title="$t('home.myProducts')"
                    icon="rocket"
                    height="xl"
                    titleDark
                    titleBackgroundTransparent
                    backgroundColor="#fff"
                    banner="Access to your ExploreLearning products."
                    :bannerDark="false"
                    :primaryAction="myProductsTrialAction"
                >
                    <template #subActions v-if="isAdmin">
                        <ElNavItem
                            :item="{
                                label: $t('home.subscriptions'),
                                link: { name: 'Subscriptions' },
                                arrow: true,
                            }"
                            size="sm"
                            theme="darkblue"
                        />
                    </template>
                    <template #subActions v-else-if="showOptIn">
                        <ElNavItem
                            size="md"
                            :item="{
                                actionIcon: 'ellipsis-v',
                                link: '#',
                                dropRight: true,
                                tip: 'Manage Products',
                                items: [
                                { label: 'Manage Products', header: true },
                                {
                                    icon: 'gear',
                                    label: $t('home.enableDisableProducts'),
                                    link: { name: 'OptIn' }
                                },
                                ],
                            }"
                        />
                    </template>
                    <ElNav
                        v-if="productIDs.length"
                        layout="centered"
                        circle
                        size="lg"
                        :items="panelIconsItems"
                    />
                    <v-layout v-else class="my-5">
                        <v-flex class="text-xs-center">
                            <div class="subheading">You do not currently have access to any products.</div>
                            <div class="subheading">Visit your <router-link :to="{name:'Subscriptions'}">subscriptions</router-link> page to learn more.</div>
                        </v-flex>
                    </v-layout>
                </ElPanel>
                <ElPanel
                    v-if="!isAdmin"
                    backgroundColor="#fff"
                    :banner="myClassesBanner"
                    :bannerDark="false"
                    :footer="teacherFooterObject"
                    height="xl"
                    icon="users"
                    :scroll="!isSplitClassCodeUser"
                    :scrollBars="!isSplitClassCodeUser"
                    :title="$t('home.myClasses')"
                    titleDark
                    titleBackgroundTransparent
                    >
                    <template #subActions>
                        <ElNavItem
                            :item="isTeacher && institutionList.length > 1 ? schoolSelectItem : newClassItem"
                            theme="darkblue"
                            size="sm"
                            :dropRight="true"
                        />
                    </template>
                    <ElNav
                        :items="loadingModel ? [] : classListItems" 
                        itemUnits="classes"
                        filter="classes" 
                        grouped
                        :groupLabelShowCount="!isSplitClassCodeUser"
                        group-by="group"
                        theme="lightblue"
                        :columns="classListItems.length < 4 ? '1': '2'"
                        size="lg"
                        parseMarkdown="*"
                        dropRight
                        >
                    </ElNav>
                </ElPanel>
                <ElPanel
                    v-else-if="isAdmin"
                    backgroundColor="#fff"
                    banner="Manage product access for administrators, teachers, and students."
                    :bannerDark="false"
                    height="xl"
                    icon="users"
                    :scroll="isDistrictAdmin || !this.isClassCodeEnabledUser || (!this.hasCCDisabledProduct || !this.hasCCEnabledProduct)"
                    :scrollBars="isDistrictAdmin || !this.isClassCodeEnabledUser || (!this.hasCCDisabledProduct || !this.hasCCEnabledProduct)"
                    :title="$t('home.manageUsers')"
                    titleDark
                    titleBackgroundTransparent
                    >
                    <ElNav
                        class="pb-3"
                        :items="schoolListItems" 
                        itemUnits="Districts/Schools"
                        filter="classes"
                        :grouped="isSplitClassCodeUser || isDistrictAdmin"
                        :groupLabelShowCount="isDistrictAdmin && !isSplitClassCodeUser"
                        group-by="group"
                        theme="lightblue"
                        :columns="'1'"
                        parseMarkdown="*"
                        search
                        :searchMinCount="6"
                        size="lg"
                        >
                    </ElNav>
                </ElPanel>
            </div>
            <v-layout>
                <v-flex xs12 px-2 v-if="isEmployeeOrCertifiedTrainer" class="panels">
                    <ElPanel
                        :title="$t('home.employeeApps')"
                        icon="user-shield"
                        titleSize="md"
                        titleDark
                        titleBackgroundTransparent
                        :bannerDark="false"
                        backgroundColor="#fff"
                        height="xl"
                    >
                        <!-- fix!! Employee -->
                        <ElNav
                            size="md"
                            :items="employeeItems"
                            circle
                        />
                    </ElPanel>
                </v-flex>
            </v-layout>
        </div>
        <TrialDialog 
            :allowTrials="isUserTypeWithClasses"
            :value="isMounted && trialModal"
            :institutions="institutionList"
            :missingProductIDs="missingProducts"
            :optedOutProductIDs="optOuts"
            @close="trialModal=false"
        />
        <FirstClassDialog
            :value="showFirstClassModal"
            :productIDs="productIDs"
            :institutions="institutionList"
            :user="profile"
            :subscriptions="activeSubscriptions"
            :classList="classList"
            :hasCCEnabledClass="hasCCEnabledClass"
            :hasCCDisabledClass="hasCCDisabledClass"
            :hasCCEnabledProduct="hasCCEnabledProduct"
            :hasCCDisabledProduct="hasCCDisabledProduct"
            :selectedSchoolID="selectedSchoolID"
            @classCreated="createdClass=true"
        />
        <ClassCodeInfoModal 
            :value="showClassCodeInfoModal"
            :isAdmin="isAdmin"
            @close="showClassCodeInfoModal=false"
        />
    </v-container>
</template>

<script>
    import APIs from 'mixins/APIs'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
    import FirstClassDialog from 'components/home/FirstClassDialog'
	import Forwarding from 'mixins/Forwarding'
	import Headline from 'components/layout/Headline'
	import LoginDetector from 'mixins/LoginDetector'
	import Messaging from 'components/layout/Messaging'
    import ProductInfo from 'mixins/ProductInfo'
	import ProfileLoader from 'mixins/SubscriptionsLoader'
    import RoleDetector from 'mixins/RoleDetector'
    import SubscriptionHelper from 'mixins/SubscriptionHelper'
    import TrialDialog from 'components/home/TrialDialog'
    import ClassCodeInfoModal from 'components/modals/ClassCodeInfoModal'
    import { ElPanel, ElNav, ElNavItem, ElTitleBar } from '@explorelearning/components'
    import { mapGetters, mapActions, mapState } from 'vuex';
	export default {
		name: 'Home',
		components: {ClassCodeInfoModal, FirstClassDialog, Headline, TrialDialog, Messaging, ElPanel, ElNav, ElNavItem, ElTitleBar},
		mixins: [APIs, EnvironmentDetector, Forwarding, LoginDetector, ProductInfo, ProfileLoader, RoleDetector, SubscriptionHelper],
        data(){
            return {
                showClassCodeInfoModal: false,
                createdClass: false,
                newProductIDs : [],
                trialModal: false,
                isMounted: false,
                selectedSchoolID: null,
                loadingModel: false,
                educatorsHub: [
                    {
                        label: "Educator's Hub",
                        linkExtNew: "https://www.explorelearning.com/educator-resource-hub/"
                    }
                ],
                acctHomeCrumb: [
                    { label: "/home/ Account Home" }
                ]
            }
        },
		computed : {
            ...mapGetters('platformData/administrators', ['administratorList']), 
            ...mapGetters('platformData/classes', ['classList', 'classCodeEnabledClassList', 'nonClassCodeEnabledClassList']),
            ...mapGetters('platformData/students', ['studentList', 'classCodeEnabledStudentList', 'nonClassCodeEnabledStudentList']),
            ...mapGetters('platformData/subscriptions', ['subscriptionList']),
            ...mapGetters('platformData/institutions', ['institutionList']),  
            ...mapGetters('platformData/teachers', ['teacherList']),
            ...mapGetters('platformData/profile', ['profile', 'isSSO']),
            ...mapState('platformData/app', ['claims']),
            chance(){
                return this.$route.query.chance || Math.floor(Math.random() * 100 + 1)
            },
            competitionsFeatureFlag() {
                return {
                    label: 'Reflex Competitions - Early Access',
                    subLabel: 'Create competitions at your school!',
                    linkExt: this.getCompetitionsAppURL(),
                    img: this.getStaticURL() + 'img/el/competitions-icon.png',
                    arrow: true,
                    size: 'md'
                }
            },
            classListMinusAllClasses() {
                return this.classList.filter((c) => { return c.id != -1})
            },
            classListItems() {
                if (this.classList && this.institutionList) {
                    const filteredCCClassList = this.classCodeEnabledClassList.filter(c => c.id != -1)
                    const filteredNonCCClassList = this.nonClassCodeEnabledClassList.filter(c => c.id != -1)
                    //this checks if it is a split class code enabled school
                    if(this.selectedSchoolIsClassCodeEnabled && filteredCCClassList.length && filteredNonCCClassList.length) {
                        return [
                            {
                                label: this.$t('home.gizmosClasses'),
                                subLabel: `db[Class Code Enabled] ${filteredCCClassList.length} ${filteredCCClassList.length == 1 ? 'Class' : 'Classes'}, ${this.classCodeEnabledStudentList.length} ${this.classCodeEnabledStudentList.length == 1 ? 'Student' : 'Students'}`,
                                group: 'Your Class Rosters',
                                actionIcon: 'caret-down',
                                isExternalLink: true,
                                items: filteredCCClassList.map(c => {
                                    const lk = this.institutionList.length > 1
                                        ? { name: 'UserManagement', query: { institution: this.selectedSchoolID, class: c.id }, params: { environment: 'gizmos', isReturnHome: true } }
                                        : { name: 'UserManagement', query: { class: c.id }, params: { environment: 'gizmos', isReturnHome: true } }
                                    return {
                                        label: c.name,
                                        arrow: true,
                                        link: lk
                                    }
                                })
                            },
                            {
                                label: `${this.nonCCProductsText} Classes`,
                                subLabel: `${filteredNonCCClassList.length} ${filteredNonCCClassList.length == 1 ? 'Class' : 'Classes'}, ${this.nonClassCodeEnabledStudentList.length} ${this.nonClassCodeEnabledStudentList.length == 1 ? 'Student' : 'Students'}`,
                                group: 'Your Class Rosters',
                                actionIcon: 'caret-down',
                                isExternalLink: true,
                                items: filteredNonCCClassList.map(c => {
                                    const lk = this.institutionList.length > 1
                                    ? { name: 'UserManagement', query: { institution: this.selectedSchoolID, class: c.id }, params: { environment: `${this.nonCCProductEnvironment}`, isReturnHome: true } }
                                    : { name: 'UserManagement', query: { class: c.id }, params: { environment: `${this.nonCCProductEnvironment}`, isReturnHome: true } }
                                    return {
                                        label: c.name,
                                        arrow: true,
                                        link: lk
                                    }
                                })
                            }
                        ]
                    } else {
                        const filteredClassList = this.classList.filter((c) => {
                            return c.id != -1
                        })
                        filteredClassList.sort(function(a,b){
                                let aName  = a.name.toUpperCase()
                                let bName  = b.name.toUpperCase()
                                let comp = 0
                                if(aName>bName){
                                    comp = 1
                                } else if (aName<bName){
                                    comp=-1
                                }
                                return comp
                        })
                        return filteredClassList.map(c => {
                            let lk
                            //multi-inst teacher, ccEnabledUser, only has gizmos
                            if (this.isTeacher && this.institutionList.length > 1 && this.isClassCodeEnabledUser && !this.hasCCDisabledProduct) {
                                lk = { name: 'UserManagement', query: { institution: this.selectedSchoolID, class: c.id }, params: { isReturnHome: true, environment: 'gizmos' } }
                            //multi-inst teacher, ccEnabledUser, only has non-gizmos products
                            } else if (this.isTeacher && this.institutionList.length > 1 && this.isClassCodeEnabledUser && this.hasCCDisabledProduct && !this.hasCCEnabledProduct) {
                                lk = { name: 'UserManagement', query: { institution: this.selectedSchoolID, class: c.id }, params: { isReturnHome: true, environment: this.nonCCProductEnvironment } }
                            //multi-inst teacher, nonCCEnabledUser   
                            } else if (this.isTeacher && this.institutionList.length > 1) {
                                lk = { name: 'UserManagement', query: { institution: this.selectedSchoolID, class: c.id }, params: { isReturnHome: true } }
                            //CCEnabledUser, only has gizmos    
                            } else if (this.isClassCodeEnabledUser && !this.hasCCDisabledProduct) {
                                lk = { name: 'UserManagement', query: { class: c.id }, params: { isReturnHome: true, environment: 'gizmos' } }
                            //CCenabledUser, only has non-gizmos products
                            } else if (this.isClassCodeEnabledUser && this.hasCCDisabledProduct && !this.hasCCEnabledProduct) {
                                lk = { name: 'UserManagement', query: { class: c.id }, params: { isReturnHome: true, environment: this.nonCCProductEnvironment } }
                            //everyone else
                            } else {
                                lk = { name: 'UserManagement', query: { class: c.id }, params: { isReturnHome: true } }
                            }
                            return {
                                group: "Your Classes",
                                notifyCount: 0,
                                notifyUnits: 'new student result',
                                label: c.name,
                                subLabel: c.studentIDs.length == 1 ? '1 Student' : c.studentIDs.length + ' Students' ,
                                link: lk,
                                arrow: true
                            }
                        })
                    }
                }
            },
            employeeItems(){
                let items = []
                if(this.isEmployee){
                    items =  [
                        {label:'CSA', tags: ['VPN'], icon:'cogs',linkExt: this.getCsaAppURL(), linkExtTargetSelf: true},
                        {label:'CSA 2.0', tags: ['VPN','NEW'], icon:'users-gear',linkExt: this.getCsaAppURL(true), linkExtTargetSelf: true},
                        {label:'Report Viewer',icon:'chart-area',linkExt: this.getReportingAppURL('reflex'), linkExtTargetSelf: true},
                        {label:'Gizmos Admin',icon:'gizmos',linkExt:this.getAdminAppURL('gizmos'), linkExtTargetSelf: true},
                        {label:'Gizmos Reports', tags: ['BETA'], icon:'chart-line',linkExt:this.getGizmosReportsToolURL(), linkExtTargetSelf: true,},
                        {label:'Gizmos Migration Tool',icon:'hiking',linkExt:this.getGizmosMigrationToolURL(), linkExtTargetSelf: true},
                        {label:'Pre-Approved Grant',icon:'check-circle',route:{name:'PreApprovedGrant',params:{environment:'reflex'}}},
                        {label:'S4U Reports',icon:'s4us',linkExt:this.getAdminAppURL('s4us'), linkExtTargetSelf: true},
                        {label:'PD Reflex Dashboard', tags: ['VPN'],icon:'chalkboard-teacher',linkExt:this.getPdDashboardURL('reflex'), linkExtTargetSelf: true}
                    ]
                    if(this.chance==26) {
                        items.push({label:'Zombo',icon:'people-group',linkExt:'https://zombo.com/'})
                    } else if(this.chance==42) {
                        items.push({label:'Party',icon:'gift',linkExt:'https://www.youtube.com/embed/jV05ka0-XVw?autoplay=1&loop=1&playlist=jV05ka0-XVw'})
                    } else if(this.chance==50) {
                        items.push({label:'Treat',icon:'burger',linkExt:'https://www.youtube.com/embed/6ZZh7bOvBlI?autoplay=1&loop=1&playlist=6ZZh7bOvBlI'})
                    } else if(this.chance==96) {
                        items.push({label:'Turbo Mode',icon:'bolt',linkExt:'https://www.youtube.com/embed/GpLCjrayo-8?autoplay=1&loop=1&playlist=GpLCjrayo-8'})
                    }
                } else if (this.isCertifiedTrainer){
                    items =  [
                        {label:'Report Viewer',icon:'chart-area',linkExt: this.getReportingAppURL('reflex'), linkExtTargetSelf: true},
                    ]
                }
                return items        
            },
            display(){
                return this.$store.state.store.subscriptionsLoaded
            },
            forceOptIn(){
                return this.showOptIn && this.optOuts.length==0
            },
            hasClasses(){
                if(this.classList){
                    return this.createdClass || this.classList.length > 0
                } else {
                    return true
                }
            },
            institutionListByID() {
                return this.institutionList.sort((a, b) => a.id - b.id)
            },
            isClassCodeEnabledUser() {
                return this.$store.getters.getIsClassCodeEnabledUser 
            },
            isRosteredUser(){
                return this.profile.isRostered
            },
            isUserTypeWithClasses(){
                let roleID = this.profile.roleID
                const userTypesWithClasses = [1,3] // Home, Teacher
                return userTypesWithClasses.includes(roleID)
            },
            missingProducts(){
                const subs =  this.getProductIDs(this.subscriptions)
                const products =  this.productDisplayOrder
                return products.filter(id => !subs.includes(id))
            },
            myClassesBanner() {
                if (this.isTeacher && this.institutionList.length > 1 && this.classListMinusAllClasses.length) {
                    const selectedSchool = this.institutionList.find(s => s.id == this.classList[0].institutionID)
                    return selectedSchool.name
                } else if (this.isTeacher && this.institutionList.length > 1 && !this.classListMinusAllClasses.length) {
                    return `${this.selectedSchoolID ? this.institutionList.find(i => i.id === this.selectedSchoolID).name : 'This school'} has no classes. Please select a different school.`
                } else {
                    return 'Manage classes and student product access.'
                }
            },
            newClassItem() {
                const newClassObj = {
                    label: this.$t('home.newClass'),
                    icon: 'plus-circle',
                    link: '#',
                }
                const items = [
                            { label: 'New Gizmos Class', link: { name: 'UserManagement', query: { modal: 'newClass' }, params: { environment: 'gizmos', isReturnHome: true }}},
                            { label: `New ${this.nonCCProductsText} Class`, link: { name: 'UserManagement', query: { modal: 'newClass' }, params: { environment: `${this.nonCCProductEnvironment}`, isReturnHome: true }}}
                        ]
                this.isClassCodeEnabledUser && this.hasCCDisabledProduct && this.hasCCEnabledProduct 
                    ?   newClassObj.items = items
                    :   newClassObj.link = { name: 'UserManagement', query: { modal: 'newClass' }, params: { environment: `${this.isClassCodeEnabledUser ? 'gizmos' : this.productEviroment(this.subscriptionList[0].productId)}`, isReturnHome: true } }
                    return newClassObj
            },
            schoolSelectItem() {
                const schoolSelectObj = {
                    label: "Select School",
                    icon: 'school',
                    link: '#',
                }
                schoolSelectObj.items = this.institutionListByID.map(s => {
                    return {
                        label: s.name,
                        onClick: () => {this.switchInstitution(s.id)},
                        actionIcon: this.selectedSchoolID == s.id ? 'check' : null
                    }
                })
                return this.loadingModel 
                    ? {
                        label: "Select School",
                        icon: 'school',
                        actionIcon: 'spinner',
                        actionFX: 'spin',
                        link: '#',
                        items: []
                    }
                    : schoolSelectObj
            },
            nonCCProductEnvironment() {
                if (this.institutionList.length && this.hasCCDisabledProduct) {
                    const compareProducts = (a, b) => {
                        const productIdA = this.productDisplayOrder.indexOf(a.productId);
                        const productIdB = this.productDisplayOrder.indexOf(b.productId);
                        return productIdA - productIdB;
                    };
                    const productsArray = this.institutionList[0].subscriptions.filter((sub) => {
                        return !this.classCodeEnabledProductIDs.includes(sub.productId) && sub.isActive
                    });
                    productsArray.sort(compareProducts);
                    return productsArray.length ? productsArray[0].displayName.toLowerCase() : '';
                }
            },
            nonCCProductsText() {
                if (this.institutionList.length && this.selectedSchoolID) {
                    let productString = ''
                    const productsArray = []
                    this.productDisplayOrder.forEach((id) => {
                        this.institutionList.find(i => i.id === this.selectedSchoolID).subscriptions.find((sub) => {
                            if (id !== 3 && sub.productId === id && sub.isActive && !productsArray.map(p => p.productId).includes(sub.productId)) {
                                productsArray.push(sub)
                            }
                        })
                    })
                    productsArray.forEach(function(prod) {
                        productString += prod.displayName + "/";
                    });
                    return productString.slice(0, -1);
                }
            },
            panelIconsItems() {
                return this.optedInSubscriptionsWithoutDuplicates.map(s => {
                    const tags = []
                    this.getSubType(this.getSubscriptions(s.productId)) ? tags.push(this.getSubType(this.getSubscriptions(s.productId))) : null
                    this.isFreemium(s) ? tags.push('Free Access') : null
                    s.licensingTypeId == 3 ? tags.push('Trial') : null
                    s.licensingTypeId == 8 || s.licensingTypeId == 13 ? tags.push('Grant') : null
                    return {
                        label: this.productSettings[s.productId].displayName,
                        tags: tags.length ? tags : null,
                        icon: this.UrlFriendlyProductName(s.productId),
                        // link to a specific institution if they are a multi-inst teacher linking to reflex or frax that already has classes
                        linkExt: this.isTeacher && this.institutionList.length > 1 && s.productId < 3 && this.classList.length
                            ? this.getReportingAppURLForMultiInstTeacher(this.UrlFriendlyProductName(s.productId)) 
                            : this.getReportingAppURL(this.UrlFriendlyProductName(s.productId)),
                        linkExtTargetSelf: true
                    }
                });
            }, 
            productIDs(){
                return this.getProductIDs(this.optedInSubscriptions)
            },
            schoolListItems() {
                if (!this.isDistrictAdmin) {
                    const items = [
                        {
                            label: this.$t('home.admins'),
                            subLabel: `${this.administratorList.length} ${this.administratorList.length == 1 ? 'account' : 'accounts'}`,
                            group: 'Staff',
                            arrow: true,
                            link: { name: 'UserManagement', query: {tab: 'administrators'}, params: { isReturnHome: true } },
                        },
                        {
                            label: this.$t('home.teachers'),
                            subLabel: `${this.teacherList.length} ${this.teacherList.length == 1 ? 'account' : 'accounts'}`,
                            group: 'Staff',
                            arrow: true,
                            link: { name: 'UserManagement', query: {tab: 'teachers'}, params: { isReturnHome: true } },
                        }
                    ]
                    if (this.isSplitClassCodeUser) {
                        items.push({
                            label: this.$t('home.gizmosStudents'),
                            subLabel:
                                'db[Class Code Enabled] No school-level roster.',
                            group: 'Students',
                            link: '#classcodeinfo',
                        },
                        {
                            label: `${this.nonCCProductsText} Students`,
                            subLabel: `${this.nonClassCodeEnabledStudentList.length} student ${this.nonClassCodeEnabledStudentList.length == 1 ? 'account' : 'accounts'}`,
                            group: 'Students',
                            arrow: true,
                            link: { name: 'UserManagement', query: {tab: 'students'}, params: { isReturnHome: true } },
                        })
                    } else {
                        items.push({
                            label: this.$t('home.students'),
                            subLabel: this.isClassCodeEnabledUser && this.hasCCEnabledProduct ? 'db[Class Code Enabled] No school-level roster.' : `${this.studentList.length} ${this.studentList.length == 1 ? 'account' : 'accounts'}`,
                            group: 'Staff',
                            arrow: this.isClassCodeEnabledUser && this.hasCCEnabledProduct ? false : true,
                            link: this.isClassCodeEnabledUser && this.hasCCEnabledProduct ? '#classcodeinfo' : { name: 'UserManagement', query: {tab: 'students'}, params: { isReturnHome: true } },
                        })
                    }
                    return items
                } else {
                    return this.institutionList.map(s => {
                            const lk = { name: 'UserManagement', query: { school: s.id }, params: { isReturnHome: true } }
                            return {
                                group: s.typeId<4 ? 'Districts' : 'Schools',
                                icon: s.typeId<4 ? 'building' : 'school',
                                label: s.name,
                                subLabel: this.createDistrictSchoolSubLabel(s),
                                link: lk,
                                arrow: true
                            }
                        })
                }
            },
            selectedSchoolIsClassCodeEnabled() {
                let school = this.institutionList.find(i => i.id === this.selectedSchoolID);
                return school ? school.isClassCodeEnabled : false;
            },
            showFirstClassModal() {
                if (!this.isMounted) {
                    return false;
                }

                if (this.createdClass) {
                    return false;
                }

                if (this.isRosteredUser) {
                    return false;
                }

                if (!this.isUserTypeWithClasses) {
                    return false;
                }

                if (!this.$store.getters.getIsClassCodeEnabledUser) {
                    return !this.hasClasses
                } else if (this.hasCCEnabledProduct && this.hasCCDisabledProduct) {
                    return !this.hasCCEnabledClass || !this.hasCCDisabledClass
                } else if (!this.hasCCEnabledProduct && this.hasCCDisabledProduct) {
                    return !this.hasCCDisabledClass
                } else if (this.hasCCEnabledProduct && !this.hasCCDisabledProduct) {
                    return !this.hasCCEnabledClass
                }
            },
            showELTrialRecommendation() {
                return this.isSSO && this.missingProducts.length
            },
            showFraxTrialRecommendation() {
                return !this.showELTrialRecommendation && this.productIDs.includes(1) && !this.hasSubscription(2,this.subscriptions) && !this.isSSO
            },
            myProductsTrialAction() {
                if (this.claims.feat && this.claims.feat.includes("COMP")  && this.hasSubscription(1,this.subscriptions)) {
                    return this.competitionsFeatureFlag
                } else if (this.showELTrialRecommendation) {
                    return this.trialRecommendationItem
                } else if (this.showFraxTrialRecommendation) {
                    return this.trialRecommendationExternalItem
                } else {
                    return {}
                }
            },
            teacherFooterObject() {
                if (this.isSplitClassCodeUser) {
                    return {
                        label: this.$t('home.learnMore'),
                        link: '#classcodeinfo',
                        icon: 'question-circle',
                    }
                } else {
                    return null
                }
            },
            trialRecommendationItem() {
                return {
                    label: 'Learn more and try for free!',
                    subLabel: 'ExploreLearning offers an entire suite of math and science solutions.',
                    link: '#opentrialmodal',
                    icon: 'el',
                    arrow: true,
                }
            },
            trialRecommendationExternalItem() {
                return {
                    label: 'Try Frax for free!',
                    subLabel: 'Adaptive and game-based Fractions for grades 3+',
                    linkExt: this.getMarketingURL('frax'),
                    icon: 'frax',
                    arrow: true
                }
            },
            hasCCEnabledClass() {
                return this.classList.some(c => c.isClassCodeEnabled)
            },
            hasCCDisabledClass() {
                return this.classList.some(c => !c.isClassCodeEnabled)
            },
            hasCCEnabledProduct() {
                return this.activeSubscriptions.some(sub => {
                    return this.classCodeEnabledProductIDs.includes(sub.productId)
                })
            },
            hasCCDisabledProduct() {
                return this.activeSubscriptions.some(sub => {
                    return !this.classCodeEnabledProductIDs.includes(sub.productId)
                })
            },
            isAdmin(){
                return this.$store.getters["platformData/profile/isAdmin"]
            },
            isDistrictAdmin(){
                if(this.isAdmin && this.institutionList){
                    return this.institutionList.length>1
                } else {
                    return false
                }
            },
            isTeacher() {
                return this.$store.getters["platformData/profile/isTeacher"]
            },
            isSplitClassCodeUser() {
                return this.isClassCodeEnabledUser && this.hasCCDisabledProduct && this.hasCCEnabledProduct
            },
            roleTitle() {
                if (this.isTeacher) {
                    return this.$t('header.teacher')
                } else if (this.isEmployee) {
                    return this.$t('header.employee')
                } else if (this.isAdmin) {
                    return this.$t('header.admin')
                } else if (this.profile.roleID==2) {
                    return this.$t('header.student')
                } else if (this.profile.roleID==4) {
                    return this.$t('header.parent')
                }
            },
            welcomeTitle() {
                return `${this.$t('home.welcomeWord')}, ${this.profile.displayName || this.profile.firstName}`
            },
            welcomeSubTitle() {
                if (this.isTeacher && this.institutionList.length > 1) {
                    return `or[${this.roleTitle}] &#8226; Multiple Schools`
                } else if (!this.isDistrictAdmin && this.institutionList.length) {
                    return `or[${this.roleTitle}] &#8226; ${this.institutionList[0].name}`
                } else {
                    return `or[${this.roleTitle}]`
                }
            }
        },
        watch : {
            forceOptIn(val){
                if(val){
                    this.$router.push({ name: 'OptIn', params: {hideBack:true}})
                }
            },
            "$route.hash" (val) {
                if (val == "#classcodeinfo") {
                    this.openClassCodeInfoModal()
                    this.$router.replace({hash: null})
                } else if (val == "#opentrialmodal") {
                    this.openTrialModal()
                    this.$router.replace({hash: null})
                }
            }
        },
        methods : {
            ...mapActions('platformData/app', ['fetchModel']),
            createDistrictSchoolSubLabel(s) {
                if (s.typeId<4) {
                    return `${s.usersCount.activatedAdminCount || 0} ${s.usersCount.activatedAdminCount == 1 ? 'admin' : 'admins'}`
                } else {
                    const adminString = `${s.usersCount.activatedAdminCount || 0} ${s.usersCount.activatedAdminCount == 1 ? 'admin' : 'admins'}, `
                    const teacherString = `${s.usersCount.activatedTeacherCount || 0} ${s.usersCount.activatedTeacherCount == 1 ? 'teacher' : 'teachers'}, `
                    const studentNumber = s.usersCount.classCodeDisabledStudentCount + s.usersCount.classCodeEnabledStudentCount
                    const studentString = `${studentNumber || 0} ${studentNumber == 1 ? 'student' : 'students'}`
                    return adminString + teacherString + studentString
                }
            },
            getSubType(sub){
                const typesToAlwaysShow = [ 5, 8, 13 ]
                let type = sub.licensingTypeId
                if(typesToAlwaysShow.includes(type)){
                    return sub.licensingTypeId
                }
                if(sub.licensingTypeId==3 && sub.isActive && !sub.isExpired){
                    return sub.licensingTypeId
                }
            },
            openTrialModal(){
                this.trialModal = true
            },
            openClassCodeInfoModal() {
                this.showClassCodeInfoModal = true
            },
            async switchInstitution(id) {
                this.loadingModel = true
                await this.fetchModel({institutionId: id, isEmployee: this.isEmployee})
                this.selectedSchoolID = id
                this.loadingModel = false
            },
            UrlFriendlyProductName(productID){
                return this.productEviroment(productID)
            }
        },
		async created(){
            this.loadSubscriptions()
        },
        async mounted(){
            // New Subscription Messaging
            const newSub = this.$route.params.newSub
            if(newSub){
                let productIDs = [...new Set(newSub.productIDs)]
                let products = this.productDisplayName(productIDs,', ').replace(/,([^,]*)$/,'\ and$1')
                this.$store.commit('setMessageSuccess', this.$tc('home.newSub',productIDs.length,{product:products,subType:newSub.type}))
                this.newProductIDs = productIDs
            }

            // New SSO Trial Messaging
            const ssoTrial = this.$route.params.ssoTrial
            if(ssoTrial){
                this.$store.commit('setMessageSuccess', this.$t('home.ssoTrial'))
            }

            //selected School logic for multi-institution teachers
            if (this.isTeacher && this.institutionList.length > 1 && this.classListMinusAllClasses.length) {
                this.selectedSchoolID = this.institutionList.find(s => s.id == this.classList[0].institutionID).id
            } else if (this.isTeacher && this.institutionList.length > 1 && !this.classListMinusAllClasses.length) {
                this.selectedSchoolID = this.institutionListByID[0].id
                await this.switchInstitution(this.institutionListByID[0].id)
            } else if (this.institutionListByID[0] && this.institutionListByID[0].id) {
                this.selectedSchoolID = this.institutionListByID[0].id
            } else {
                this.selectedSchoolID = null
            }
            this.isMounted = true
        }
	}
</script>

<style lang="less">
    #acct-home-container {
        & .acct-home-panels  {
            display: grid;
            grid-gap: 4em;
            grid-template-columns: 1fr 1fr;
            grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
            grid-auto-flow: row;
            .title {
                font-size: inherit !important;
                font-weight: normal !important;
                line-height: normal !important;
                letter-spacing: normal !important;
                font-family: "Avenir", Helvetica, Arial, sans-serif !important;
            }
        }
        & .acct-home-header .title {
            font-size: 2em !important;
            font-family: "Avenir", Helvetica, Arial, sans-serif !important;
            font-weight: bold;
        }
        & .acct-home-header .sub-title {
            font-family: "Avenir", Helvetica, Arial, sans-serif !important;
            font-weight: 500;
        }
        &.container.grid-list-xl :not(:only-child) .panel-nav .layout:last-child {
            margin: 0;
        }
        &.container.grid-list-md :not(:only-child) .panel-nav .layout:last-child {
            margin: 0;
        }
    }
</style>

<style lang="less" scoped>
    .home-panels-wrapper {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        line-height: normal;
        letter-spacing: normal;
    }
    .acct-home-header.title-bar {
        font-size: 16px;
    }
    #product-settings {
        float: right;
        margin-top: -15px;
        margin-right: -15px;
    }
    .view-height-home {
        min-height: 60vh;
    }
    .home-products {
        display: grid;
        gap: 1.2em;
        grid-auto-columns: 1fr;
        grid-template-columns: repeat( auto-fit, minmax(150px, .33fr) );
        justify-content: center;
        // min-height: 350px;
        .product-container {
            position: relative;
            z-index: 10;

        }
        .product-info {
            border-radius: 50%;
            border: solid 3px #fff !important;
            padding: 2px;
            position: absolute;
            top: 10%;
            right: 10%;
            z-index: 20;
            cursor:pointer
        }
        @media only screen and (max-width: 800px) {
            .product-info {
                top: 9%;
                right: 9%;

            }
        }
        @media only screen and (max-width: 700px) {
            .product-info {
                top: 8%;
                right: 8%;

            }
        }
        @media only screen and (max-width: 600px) {
            .product-info {
                top: 7%;
                right: 7%;

            }
        }
        @media only screen and (max-width: 500px) {
            .product-info {
                top: 6%;
                right: 6%;

            }
        }
    }
    #recBlock {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #444;
        margin-top: -5px;
        margin-bottom: -10px;
        .v-btn {
            text-transform: none;
            padding-top: 2px;
        }
    }
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #ddd;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #ddd transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
