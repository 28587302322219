<template>
	<form @submit.prevent="submitInfo">
		<v-layout wrap>
			<Headline :logo="currentEnvironment" :headline="$t('address.header')"></Headline>
		</v-layout>
		<v-layout wrap>
			<Messaging outline></Messaging>
			<v-flex xs12>
				<v-text-field
	      	v-model="billingAddress.address1"
	      	:label="$t('form.address1')"
	      	v-validate="'required|noTags'"
	      	name="street address"
	      	:error-messages="errors.collect('street address')">
	      </v-text-field>
			</v-flex>
			<v-flex xs12>
				<v-text-field
	      	v-model="billingAddress.address2"
	      	:label="$t('form.address2')"
	      	v-validate="'noTags'"
	      	name="unit/suite"
	      	:error-messages="errors.collect('unit/suite')">
	      </v-text-field>
			</v-flex>
			<v-flex xs12 sm6>
				<v-text-field
	      	v-model="billingAddress.city"
	      	:label="$t('form.city')"
	      	v-validate="'required|alpha_spaces'"
	      	name="city"
	      	:error-messages="errors.collect('city')">
	      </v-text-field>
			</v-flex>
			<v-flex xs12 sm6>
				<v-text-field
	      	v-model="billingAddress.postalCode"
	      	:label="$t('form.zip')"
	      	v-validate="'required|noTags'"
	      	name="zip/postal code"
	      	:error-messages="errors.collect('zip/postal code')">
	      </v-text-field>
			</v-flex>
			<v-flex xs12 :sm6="billingDisplayStates">
				<v-autocomplete
					v-model="billingAddress.countryCode"
					v-bind:items="allCountries"
					item-value="code"
					item-text="name"
					:label="$t('chooseLocation.country')"
					v-validate="'required'"
	      	name="country"
	      	:error-messages="errors.collect('country')">
				</v-autocomplete>
			</v-flex>
			<v-flex v-if="billingDisplayStates" xs12 sm6>
				<v-autocomplete
					v-model="billingAddress.state"
					v-bind:items="billingAllStates"
					item-value="code"
					item-text="name"
					:label="$t('chooseLocation.state')"
					v-validate="'required'"
	      	name="state/province"
	      	:error-messages="errors.collect('state/province')">
				</v-autocomplete>
			</v-flex>
			<v-flex xs12>
				<v-checkbox
		      :label="$t('address.check')"
		      v-model="sameAddress">
		  	</v-checkbox>
		  </v-flex>
		  <v-layout wrap v-if="!sameAddress">
		  	<v-flex headline grey--text text--darken-2 class="tabHeading text-xs-left">{{$t('address.shipping')}}</v-flex>
		  	<v-flex xs12>
				<v-text-field
					v-model="shippingAddress.address1"
					:label="$t('form.address1')"
					v-validate="'required|noTags'"
					name="shipping street address"
					:error-messages="errors.collect('shipping street address')">
				</v-text-field>
			</v-flex>
			<v-flex xs12>
					<v-text-field
		      	v-model="shippingAddress.address2"
		      	:label="$t('form.address2')"
		      	v-validate="'noTags'"
	      		name="shipping unit/suite"
	      		:error-messages="errors.collect('shipping unit/suite')">
		      </v-text-field>
				</v-flex>
				<v-flex xs12 sm6>
					<v-text-field
		      	v-model="shippingAddress.city"
		      	:label="$t('form.city')"
		      	v-validate="'required|alpha_spaces'"
		      	name="shipping city"
		      	:error-messages="errors.collect('shipping city')">
		      </v-text-field>
				</v-flex>
				<v-flex xs12 sm6>
					<v-text-field
		      	v-model="shippingAddress.postalCode"
		      	:label="$t('form.zip')"
		      	v-validate="'required|noTags'"
		      	name="shipping zip/postal code"
		      	:error-messages="errors.collect('shipping zip/postal code')">
		      </v-text-field>
				</v-flex>
				<v-flex xs12 :sm6="shippingDisplayStates">
					<v-autocomplete
						v-model="shippingAddress.countryCode"
						v-bind:items="allCountries"
						item-value="code"
						item-text="name"
						:label="$t('chooseLocation.country')"
						v-validate="'required'"
		      	name="shipping country"
		      	:error-messages="errors.collect('shipping country')">
					</v-autocomplete>
				</v-flex>
				<v-flex v-if="shippingDisplayStates" xs12 sm6>
					<v-autocomplete
						v-model="shippingAddress.state"
						v-bind:items="shippingAllStates"
						item-value="code"
						item-text="name"
						:label="$t('chooseLocation.state')"
						v-validate="'required'"
		      	name="shipping state/province"
		      	:error-messages="errors.collect('shipping state/province')">
					</v-autocomplete>
				</v-flex>
		  </v-layout>
			<v-flex xs6>
				<v-btn :to="{name:'BillingInfo'}" large color="grey lighten-2">
          <i class="material-icons">keyboard_arrow_left</i>
          {{$t('navigation.back')}}
        </v-btn>
			</v-flex>
			<v-flex xs6 class="text-xs-right">
				<v-btn color="primary" large type="submit">
					{{$t('navigation.review')}}
				</v-btn>
			</v-flex>
		</v-layout>
	</form>
</template>

<script>
	import AutoFill from 'mixins/AutoFill'
	import APIs from 'mixins/APIs'
	import Constants from 'mixins/Constants'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import ErrorHandling from 'mixins/ErrorHandling'
	import Headline from 'components/layout/Headline'
	import Location from 'mixins/Location'
	import LoginDetector from 'mixins/LoginDetector'
	import Messaging from 'components/layout/Messaging'
	import OrderLoader from 'mixins/OrderLoader'
	import { mapGetters } from 'vuex'
  export default {
    name: 'BillingInfo',
    metaInfo : {
			title : 'Address'
		},
    components: {
    	Headline,
    	Messaging
    },
    mixins: [AutoFill, APIs, Constants, EnvironmentDetector, ErrorHandling, Location, LoginDetector, OrderLoader],
    data () {
      return {
      	allCountries : [],
      	billingDisplayStates : false,
      	billingAllStates : [],
      	billingAddress : {
	      	address1 : '',
	      	address2 : '',
	      	city : '',
	      	postalCode : '',
	      	state : '',
	      	postalCode : '',
	      	countryCode : '',
	      	countryName : ''
	      },
	      sameAddress : true,
	      shippingDisplayStates : false,
      	shippingAllStates : [],
	      shippingAddress : {
	      	address1 : '',
	      	address2 : '',
	      	city : '',
	      	postalCode : '',
	      	state : '',
	      	postalCode : '',
	      	countryCode : '',
	      	countryName : ''
	      }
      }
    },
    computed: {
		...mapGetters('platformData/profile', ['profile']),
    	years(){
    		let years = []
    		let currentYear = (new Date()).getFullYear()
    		for(let i = 0; i < 21; i++){
    			years.push({text: currentYear+i, value: (currentYear+i)%100})
    		}
    		return years
    	}
    },
    watch: {
    	billingAllStates(val){
    		if(val && val.length){
    			this.billingDisplayStates = true
    		} else {
    			this.billingDisplayStates = false
    		}
    	},
    	'billingAddress.countryCode': function(val){
    		this.getStates('billingAllStates',val)
    		this.billingAddress.countryName = this.getCountryName(val,this.allCountries)
    	},
    	shippingAllStates(val){
    		if(val && val.length){
    			this.shippingDisplayStates = true
    		} else {
    			this.shippingDisplayStates = false
    		}
    	},
    	'shippingAddress.countryCode': function(val){
    		this.getStates('shippingAllStates',val)
    		this.shippingAddress.countryName = this.getCountryName(val,this.allCountries)
    	}
    },
    methods: {
      submitInfo: async function (){
      	var valid = await this.$validator.validateAll()
      	this.$store.commit('setSameAddress', this.sameAddress)
      	if(valid){
	      	if(this.sameAddress){
		      	this.$http.post(this.getAddUpdateCheckoutAddressAPI('both'), this.billingAddress, this.jtiHeader).then(response=>{
		      		this.$store.commit('setBillingAddress', this.billingAddress)
							this.$store.commit('setShippingAddress', JSON.parse(JSON.stringify(this.billingAddress))) //Janky Way to Shallow Clone
							this.$router.push({name:'CheckoutConfirm'})
		      	},response=>{
		      		if(response.status==401){
		    				this.expiredCredentials()
		    			}
		      		this.$store.commit('setMessageError', response.data.MessageResponse.message)
		      	})
	      	} else {
	      		this.$http.post(this.getAddUpdateCheckoutAddressAPI('billing'), this.billingAddress, this.jtiHeader).then(response=>{
		      		this.$store.commit('setBillingAddress', this.billingAddress)
							this.$http.post(this.getAddUpdateCheckoutAddressAPI('shipping'), this.shippingAddress, this.jtiHeader).then(response=>{
			      		this.$store.commit('setShippingAddress', this.shippingAddress)
			      		this.$router.push({name:'CheckoutConfirm'})
			      	},response=>{
			      		if(response.status==401){
		      				this.expiredCredentials()
		      			}
			      		this.$store.commit('setMessageError', response.data.MessageResponse.message)
			      	})
		      	},response=>{
		      		if(response.status==401){
		    				this.expiredCredentials()
		    			}
		      		this.$store.commit('setMessageError', response.data.MessageResponse.message)
		      	})
	      	}
	      }
      }
    },
    created(){
    	this.loadOrder()
    	this.getCountries('allCountries')
    }
  }
</script>

<style lang="less">
	.input-group.checkbox label{
	  height: auto;
	  white-space: normal;
	}
</style>
