<template>
    <div class="chooser">
        <div class="body">
            <div class="cols">
                <router-link :to="backRoute" class="back"><i class="fas fa-arrow-left"></i></router-link>
                <div class="inner card-inner">
                    <div class="header">
                        <el-logo text="Student Login"></el-logo>
                        <products :active="'reflex'"></products>
                    </div>
                    <h1 class="ul-title cols">
                        <span>Select your name:</span>
                        <span class="font-weight-light grey--text text--darken-3" >
                            <span v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}" content="Your Class">
                                {{className}}
                            </span>
                            &middot;
                            <span v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}" content="Your Teacher" ref="teacher">
                                {{teacherName}}
                            </span>                           
                        </span>
                    </h1>
                    <div v-if="students.length" class="student-list">
                        <button v-for="student in students" :key="student.id" class="student" tabindex="0" @click="selectStudent(student)">
                            <span class="icon"><i class="fas fa-user-circle"></i></span>
                            <div class="names">
                                <span class="name">{{student.name}}</span>
                                <span class="full">{{student.name}}</span>
                            </div>
                        </button>
                    </div>
                    <div v-else class="text-xs-center mb-5">
                        <h1 class="ul-title">
                            <span>Sorry, we are unable to log you in right now...</span>
                        </h1>
                        <p>Class {{className}} does not have any students enrolled in it.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import elLogo from 'components/login/EL-Logo'
    import Products from 'components/login/Products'
	export default {
		name: 'SelectStudent',
		metaInfo : {
			title : 'Name'
		},
		components: { elLogo, Products },
        data(){
            return {
                backRoute: {name: 'ChooseClass'}
            }
        },
		computed: {
			className: function(){
				return this.$store.state.studentLogin.class.className
			},
			students: function(){
                return this.$store.state.studentLogin.students
			},
			teacherName: function(){
				//so the teacher name doesnt wrap causing tab issues
                if (this.$store.state.studentLogin.teacherName.length > 12) {
                    return this.$store.state.studentLogin.teacherName.slice(0, 10) + "...";
                } else {
                    return this.$store.state.studentLogin.teacherName
                }
			}
		},
		methods: {
			selectStudent : function(student){
                this.$store.commit('setStudent', student)
                this.$router.push({ name: 'ChooseProduct'})
			}
		},
		created: function(){
			if(!this.$store.state.studentLogin.students.length){
				this.$router.replace(this.backRoute)
			}
		},
        mounted: function(){
            //this is here to reset the tab order for keyboard users
            this.$nextTick(() => {
                this.$refs.teacher.focus();
                this.$refs.teacher.blur();
            });            
        }
	}
</script>

<style lang="less" scoped>    
    @color-gizmos-blue: #0072B4;

    .student-list {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: .75em;
        padding-top: .7em;
        animation: fadein .3s linear;
        .student {
            min-width: 0;
            color: @color-gizmos-blue;
            border-radius: 6px;
            font-weight: 700;
            text-decoration: none;
            text-align: center;
            padding: .3em .5em .2em .5em;
            background: #efefef;
            margin-bottom: .3em;
            cursor: pointer;
            .icon {
                color: #ccc;
                display: block;
                font-size: 1.5em;
                margin:-0.75em auto 0 auto;
                width: 1.3em;
                height: 1.3em;
                border-radius: 50%;
                background: #efefef;
                position: relative;
            }
            .names {
                position: relative;
                font-size: 1em;
                font-weight: 700;
                .name {
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .full {
                    opacity: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: .75em;
                    white-space: nowrap;
                }
            }
            &:last-child {
                margin: 0;
            }
            &:hover {
                transform: scale(1.2);
                box-shadow: 0px 4px 10px 0 rgba(0,0,0,0.4);
                border-radius: 4px;
                background: @color-gizmos-blue;
                color: #fff;
                .icon {
                    background: @color-gizmos-blue;
                    color: inherit;
                }
                .names .name { opacity : 0; }
                .names .full { opacity : 1; }
            }
        }

        .student:focus {
            box-shadow: 0 0 2px 2px #0072B4;
            transition: box-shadow .2s ease;
        }        
    }	
</style>
