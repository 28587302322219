<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.editAccessHeader',{product:productDisplayName(productID)})"
        :subHeadline="userCountOrName(selectedStudents,$tc('classMgmt.students',selectedStudents.length))"
        size="600"
        @closeDialogs="closeDialogs()"
    > 
        <v-card-text>
            <v-select
                v-model="editAccessSelection"
                :items="productAccess"
                item-text="label"
                item-value="value"
                :label="$tc('classMgmt.access')"
                prepend-icon="fact_check"
            ></v-select>
            <SeatCapAlert
                @updateButtonState="this.updateButtonState"
                :determineAssignment="determineAssignment"
                :modalSpecificSelection="editAccessSelection"
                :productID="productID"
                :seatInfo="seatInfo"
                :selectedSchool="selectedSchool"
                :selectedStudents="selectedStudents"
            ></SeatCapAlert>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" 
                @click="updateAccess()" 
                :disabled="editAccessSelection===null || (!hasEnoughSeats && editAccessSelection>0)" 
                class="elevation-0">
                <v-icon>check</v-icon>&nbsp;{{$t('classMgmt.accept')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import Constants from 'mixins/Constants'
    import ProductInfo from 'mixins/ProductInfo'
    import SubscriptionHelper from 'mixins/SubscriptionHelper'
    import SeatCapAlert from 'components/modals/SeatCapAlert'

	export default {
        name: 'EditSectorModal',
        components: { UserManagmentModal, SeatCapAlert },
        mixins: [Constants, ProductInfo, SubscriptionHelper],
        props: {
            determineAssignment: Function,
            getIDs: Function,
            institutionID: Number,
            productID: {type: Number, default: 1},
            seatInfo: Function,
            selectedStudents: Array,
            selectedSchool: Object,
            subscriptionID: Number,
            tenantID: Number,
            userCountOrName: Function,
            value: Boolean,
        },
        data() {
            return {
                editAccessSelection: null,
                hasEnoughSeats: true,
            }
        },
		computed : {
            platform(){
                 return this.$store.state.platform
            },
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs', ['editAccessModal'])
            },
            async updateAccess(){
                let institutionId = this.institutionID
                let loginIds = this.getIDs(this.selectedStudents)
                let tenantId = this.tenantID
                const subscriptionIdsToRemove = [];
                this.selectedStudents.forEach(student => {
                    if (student.licenses.length) {
                        student.licenses.forEach(license => {
                            if (license.productId === this.productID && license.matchingLicense) {
                            // Check if subscription ID already exists, only add if its unique
                            if (!subscriptionIdsToRemove.some(id => id === license.subscriptionId)) {
                                    subscriptionIdsToRemove.push(license.subscriptionId);
                                }
                            }
                        });
                    }
                });
                let subscriptionIdsToAdd = [this.getActiveInstitutionSubscriptions(this.productID, this.selectedSchool)[0].id]
                if(this.editAccessSelection){
                    await this.platform.addUsersToSubscription({tenantId, institutionId, subscriptionIds: subscriptionIdsToAdd, loginIds})
                } else {
                    await this.platform.removeUsersFromSubscription({tenantId, institutionId, subscriptionIds: subscriptionIdsToRemove, loginIds})
                }
                await this.$emit('updateModel')
                this.closeDialogs()
                                
            },
            updateButtonState(hasEnoughSeats){
                this.hasEnoughSeats = hasEnoughSeats
            },
        },
        watch: {
            value(val){
                if (val){
                    if(this.selectedStudents.length>1){
                        this.editAccessSelection = null
                    } else {
                        this.editAccessSelection = this.selectedStudents[0].licenses.filter(l => l.matchingLicense).map(l => l.productId).includes(this.productID) ? 1 : 0
                    }
                }
            },
        }
	}
</script>

<style lang="less" scoped>
</style>