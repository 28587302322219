<template>
    <UserManagmentModal
        :value="value"
        :headline="deleteMode ? $tc('classMgmt.deleteFromSchoolRoster', selectedUsers.length) : $t('classMgmt.removeFromClass')"
        :subHeadline="userCountOrName(selectedUsers,$tc('classMgmt.students', selectedUsers.length))"
        size="600"
        @closeDialogs="closeDialogs()"
    >
        <v-card-text>
            <v-flex v-if="!deleteMode">
                {{$t('classMgmt.pleaseConfirmRemove')}} {{selectedUsers.length > 1 ? 'these students' : 'this student'}} {{$t('classMgmt.from')}} <strong>{{selectedClassName}}</strong>. {{!isClassCodeEnabledClass ? $t('classMgmt.stillInRoster') : ''}}
            </v-flex>
            <v-flex v-else>
                <v-alert value='deleteMode' outline class="py-0">
                    <v-checkbox
                        v-model="deleteStudents"
                        color="error"
                        class="body-1 mb-3"
                        :disabled="containsRosteredUser(selectedUsers)"
                    >
                        <template v-slot:append>
                            <v-flex>{{$tc('classMgmt.pleaseConfirm', selectedUsers.length)}} {{$t('classMgmt.deleteMessage', {studentPossessive: $tc('classMgmt.studentPossessive', selectedUsers.length)})}}</v-flex>
                        </template>
                    </v-checkbox>
                </v-alert>
            </v-flex>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="allowStudentCreation && !forceDeleteMode && !isClassCodeEnabledClass" 
                :disabled="containsRosteredUser(selectedUsers)" 
                :color="deleteMode?'primary':'error'" 
                flat 
                @click="toggleDeleteMode()"
            >
                <span v-if="deleteMode">{{$t('classMgmt.cancel')}}</span>
                <span v-else>{{$tc('classMgmt.permanentlyDeleteStudent', selectedUsers.length)}}</span>
            </v-btn>
            <v-btn @click="closeDialogs()" v-if="!deleteMode && isClassCodeEnabledClass" flat color="primary">{{ $t('classMgmt.cancel') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="closeDialogs()" v-if="!deleteMode && !isClassCodeEnabledClass" flat color="primary">{{ $t('classMgmt.cancel') }}</v-btn>
            <v-btn v-if="!deleteMode" color="error" @click="removeStudents(selectedUsers)" class="elevation-0">
                <v-icon>person_remove</v-icon>&nbsp;{{$tc('classMgmt.removeStudent', selectedUsers.length)}}
            </v-btn>
            <v-btn v-else color="error" 
                @click="deleteUsers(selectedUsers)" 
                class="elevation-0" 
                :disabled="!deleteStudents || containsRosteredUser(selectedUsers)"
            >
                &nbsp;{{$tc('classMgmt.deleteStudent', selectedUsers.length)}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'

	export default {
        name: 'RemoveStudentModal',
        components: { UserManagmentModal },
        props: {
            allowStudentCreation: Boolean,
            containsRosteredUser: Function,
            forceDeleteMode: {type: Boolean, default: false},
            getIDs: Function,
            institutionID: Number,
            isClassCodeEnabledClass: Boolean,
            selectedClassID: Number,
            selectedClassName: String,
            selectedUsers: Array,
            studentToRemove: String,
            userCountOrName: Function,
            value: Boolean,
        },
        data() {
            return {
                deleteMode: false,
                deleteStudents: false,
            }
        },
		computed : {
            platform(){
                 return this.$store.state.platform
            },
            studentIDs(){
                return this.getIDs(this.selectedUsers)
            }
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs')
            },
            deleteUsers(users) {
                this.$emit('deleteUsers', users)
            },
            async removeStudents(students){
                let institutionId = this.institutionID
                let classId = this.selectedClassID
                let studentIds = this.studentIDs
                await this.platform.removeStudentsFromClass({institutionId,classId,studentIds})
                this.$emit("updateModel")
                this.closeDialogs()
                this.selectedStudents = []
            },
            toggleDeleteMode(){
                this.deleteStudents = false
                this.deleteMode = !this.deleteMode
            }
        },
        watch: {
            value(val){
                if (val){
                    this.deleteStudents = false
                    this.deleteMode = this.forceDeleteMode
                }
            }
        }
	}
</script>

<style lang="less" scoped>
</style>