import reflexLeaf from '../assets/reflex/img/logo-leaf.svg'
import reflexFull from '../assets/reflex/img/logo.svg'
import fraxLeaf from '../assets/frax/img/logo-leaf.svg'
import fraxFull from '../assets/frax/img/logo.svg'
import gizmosLeaf from '../assets/gizmos/img/logo-leaf.svg'
import gizmosFull from '../assets/gizmos/img/logo.svg'
import s4usLeaf from '../assets/s4u/img/logo-leaf.svg'
import s4usFull from '../assets/s4u/img/logo.svg'

export default {
    data(){
        return {
            productDisplayOrder : [3,1,2,4],      
            productSettings: {
                1 : {
                        environment : 'reflex',
                        displayName : 'Reflex',
                        assignmentType: 'assignment',
                        logo: reflexLeaf,
                        logoFull: reflexFull,
                        assignmentValue: 'assignmentReflex',
                        color: '#71BF4B',
                        colorClass: 'reflex-green',
                        missing:'an assignment',
                        newUserItems: 'assignmentId',
                        dropDownOptions: 'assignmentIDs',
                        friendlyURL: 'ReflexMath.com',
                        description: 'Math fact fluency system',
                        gradeBand: '2+',
                        studentLoginRequiresClassID: false,
                        systemRequirementsURL: process.env.helpCenterURL + 'article/Reflex-System-Requirements',
                        expiredLicenseTypeExemption: [],
                        hasTrialPromotionalPeriods: false,
                        purchaseable: true,
                        autoEnroll: 0
                    },
                2 : {
                        environment : 'frax',
                        displayName : 'Frax',
                        assignmentType: 'assignment',
                        logo: fraxLeaf,
                        logoFull: fraxFull,
                        assignmentValue: 'assignmentFrax',
                        color: '#8E5BA6',
                        colorClass: 'frax-purple',
                        missing:'an assignment',
                        newUserItems: 'sectorId',
                        dropDownOptions: 'availableSectors', // This is a computed property that must be added manually. TODO: remove this constraint.
                        friendlyURL: 'FraxMath.com',
                        description: 'A better way to learn fractions',
                        gradeBand: '3+',
                        studentLoginRequiresClassID: false,
                        systemRequirementsURL: process.env.helpCenterURL + 'article/Will-Frax-Run-on-My-Computer-or-Device',
                        expiredLicenseTypeExemption: [],
                        hasTrialPromotionalPeriods: false,
                        purchaseable: false,
                        autoEnroll: 0
                    },
                3 : {
                        environment : 'gizmos',
                        displayName : 'Gizmos',
                        assignmentType: 'access',
                        logo: gizmosLeaf,
                        logoFull: gizmosFull,
                        assignmentValue: "assignmentGizmos",
                        color: '#00A1E4',
                        colorClass: 'gizmos-blue',
                        missing:'access',
                        newUserItems: 'gizmosAccessId',
                        dropDownOptions: 'productAccess',
                        friendlyURL: 'ExploreLearning.com',
                        description: 'Simulation library for math and science',
                        gradeBand: '3-12',
                        studentLoginRequiresClassID: true,
                        expiredLicenseTypeExemption: [3],
                        hasTrialPromotionalPeriods: true,
                        purchaseable: false,
                        autoEnroll: 1
                    },
                4 : {
                        environment : 's4us',
                        displayName : 'Science4Us',
                        assignmentType: 'access',
                        logo: s4usLeaf,
                        logoFull: s4usFull,
                        assignmentValue: "assignmentScience4Us",
                        color: '#F2BB14',
                        colorClass: 's4u-yellow',
                        missing:'access',
                        newUserItems: 's4uAccessId',
                        dropDownOptions: 'productAccess',
                        friendlyURL: 'Science4Us.com',
                        description: 'Science topics with the 5E model',
                        gradeBand: 'K-2',
                        studentLoginRequiresClassID: false,
                        expiredLicenseTypeExemption: [],
                        hasTrialPromotionalPeriods: false,
                        purchaseable: false,
                        autoEnroll: 0
                    }
            },
            licenseTypeMapping : {
                'site' : 1,
                'teacher' : 2,
                'trial' : 3,
                'complimentary' : 4,
                'pilot' : 5,
                'employee free teacher' : 6,
                'employee seed program' : 7,
                'teacher grant' : 8,
                'home' : 9,
                'teacher plus' : 10,
                'department' : 11,
                'external access partner' : 12,
                'site grant' : 13
            }
        }
    },
    computed: {
        availableProducts(){
            let products = []
            let environments = process.env.environments
            let hidden = process.env.hiddenProducts
            this.productDisplayOrder.forEach(prodID=>{
                let product = this.productSettings[prodID]
                product.id = prodID
                if(environments.includes(product.environment) && !hidden.includes(product.environment)){
                    products.push(product)
                }
            })
            return products
        },
        classCodeEnabledProductIDs() {
            const IDArray = Object.keys(this.productSettings).filter(key => this.productSettings[key].autoEnroll == 1).map(([key]) => +key);
            return IDArray
        }
    },
	methods: {
        getLicenseTypeName(licenseTypeID) {
            const reversedMapping = Object.entries(this.licenseTypeMapping).reduce((acc, [key, value]) => {
              acc[value] = key;
              return acc;
            }, {});
           
            return licenseTypeID ? reversedMapping[licenseTypeID].replace(/\b\w/g, char => char.toUpperCase()) : 'unknown license type';
        },
        licenseTypeIsExpiredExempted(prodID,licenseTypeID){
            if(this.productSettings[prodID]){
                return this.productSettings[prodID].expiredLicenseTypeExemption.includes(licenseTypeID)
            }
            return false
        },
        productAssignmentType(prodID){
            if(this.productSettings[prodID]){
                return this.productSettings[prodID].assignmentType
            }
            return ''
        },
        productColor(prodID){
            if(this.productSettings[prodID]){
                return this.productSettings[prodID].color
            }
            return ''
        },
        productColorClass(prodID){
            if(this.productSettings[prodID]){
                return this.productSettings[prodID].colorClass
            }
            return ''
        },
        productDisplayName(prodID, separator=', ',finalSeparator=' and'){
			if(Array.isArray(prodID)){
				let products = ''
				prodID.forEach(id=>{
					products = products + ' ' + this.productDisplayName(id)
				})
				products = products.trim()
				if(separator){
					products = products.replaceAll(' ',separator)
				}
                if(finalSeparator){
                    let lastSeparator = products.lastIndexOf(separator)
                    if(lastSeparator != -1){
                        products = products.slice(0,lastSeparator) + finalSeparator + products.slice(lastSeparator + 1)
                    }
                }
				return products
			}
            if(this.productSettings[prodID]){
                return this.productSettings[prodID].displayName
            }
            return ''
        },
        productEviroment(prodID){
            if(this.productSettings[prodID]){
                return this.productSettings[prodID].environment
            }
            return ''
        },
        productFriendURL(prodID){
            if(this.productSettings[prodID]){
                return this.productSettings[prodID].friendlyURL
            }
            return ''
        },
        productHasTrialPromotions(prodID){
            return this.productSettings[prodID].hasTrialPromotionalPeriods
        },
		productImgPath(prodID, full=false){
            if(this.productSettings[prodID]){
                if(full){
                    return this.productSettings[prodID].logoFull
                } else {
                    return this.productSettings[prodID].logo
                }
            }
            return ''
        },
        productSystemRequirementsURL(prodID){
            if(this.productSettings[prodID]){
                return this.productSettings[prodID].systemRequirementsURL
            }
            return ''
        }  
	}
}
