<template>
    <v-tooltip left>
        <template v-slot:activator="{ on }">
            <v-icon color="info" :small="small" v-on="on" class="text--lighten-2">info</v-icon>
        </template>
        <span>
            Student IDs are used to grant students access to our<br/>
            products while also tracking their progress year-to-year.
        </span>
    </v-tooltip>
</template>

<script>
    export default {
        name: 'LanguageTooltip',
        props: {
            small : {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                
            }
        }
    }
</script>