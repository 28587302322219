<template>
    <span>
        <v-icon class="body blue--text pointer" @click="showModal=true">
            help_outline
        </v-icon>
        <v-dialog v-model="showModal" max-width="500">
            <v-card class="modal">
                <v-card-title color="light-grey" class="headline">
                    <span v-if="adminText">Student/Teacher Rostering</span>
                    <span v-else>Class Rostering</span>
                    <v-spacer></v-spacer>
                    <v-btn flat icon @click="showModal=false"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <p v-if="adminText">This subscription is configured for automatic rostering.</p>
                    <p v-else>Your account is configured for automatic rostering.</p>
                    <p><span v-if="adminText">Teacher</span><span v-else>Classes</span> and student accounts that are managed automatically by your institution's Student Information System are indicated with an <v-icon>sync_alt</v-icon> icon.</p>
                    <p>For more information about how rostering works, please visit our <a target="_blank" :href="getHelpCenterURL('topic/0TO0b000000UTwMGAW/rostering')">help center</a>.</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="elevation-0" target="_blank" :href="getHelpCenterURL('topic/0TO0b000000UTwMGAW/rostering')">
                        Visit Help Center 
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
    import APIs from 'mixins/APIs'
    export default {
        name: 'RosterModal',
        mixins: [APIs],
        props: {
            adminText : {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                showModal: false
            }
        }
    }
</script>

<style lang="less" scoped>
    .v-icon {
        font-size: 1.1em;
    }
    p > .v-icon {
        font-weight: 500;
        color: black;
    }
</style>