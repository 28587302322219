<template>
	<div>
		<v-layout wrap>
			<Headline :headline="$t('chooseClass.header')" :subHeadline="teacherName"></Headline>
		</v-layout>
		<v-layout wrap>
			<v-flex v-for="c in classes" :key="c.classID" xs12 sm6 md4 xl3>
				<v-btn @click="selectClass(c)" buttonType="button" color="white" round class="option">
					{{ c.className }}
				</v-btn>
			</v-flex>
		</v-layout>
	  	<v-layout wrap>
			<v-flex xs12 mt-4>
				<router-link :to="{name:'AppLogin'}" >
					<v-btn flat dark round large>
					<i class="material-icons">keyboard_arrow_left</i>
					{{$t('chooseClass.back')}}
					</v-btn>
				</router-link>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
	import Headline from 'components/layout/Headline'
	export default {
		name: 'SelectClass',
		metaInfo : {
			title : 'Class'
		},
		components: {
			Headline
		},
		computed: {
			classes: function(){
				return this.$store.state.studentLogin.classes
			},
			teacherName(){
				return this.$store.state.studentLogin.teacherName
			}
		},
		methods: {
			selectClass: function(c){
                this.$store.commit('setClass',c)
                this.$router.push({ name: 'AppStudent'})
            }
		},
		created: function(){
			if(!this.$store.state.studentLogin.responseData){
				this.$router.replace({name: 'AppLogin'})
			}
		}
	}
</script>

<style lang="less">
	button.option {
		width: 95%;
	}
</style>
