<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.csvFormattingHeader')"
        :headline2="$t('classMgmt.formattingHeader')"
        :size="size"
        @closeDialogs="closeDialogs()"
    >
        <v-card-text>
            <p>{{isClassCodeEnabledClass ? $t('classMgmt.toImportYouNeedCSVClassCode') : $t('classMgmt.toImportYouNeedCSV')}}</p>
            <p v-if="isSubscriptionRostered">{{$t('classMgmt.fieldCannotBeAltered')}} (<v-icon small color="black">sync_alt</v-icon>)</p>
            <v-layout wrap>
                <v-flex xs12 sm6 md4 px-1 :offset-md1="!isAdmin">
                    {{$t('classMgmt.requiredColumns')}}
                    <v-flex class="grey lighten-3 well">
                        <ul>
                            <li>{{$t('classMgmt.firstNameColumn')}}<span v-if="isSubscriptionRostered">*</span></li>
                            <li>{{$t('classMgmt.lastNameColumn')}}<span v-if="isSubscriptionRostered">*</span></li>
                            <li v-if="!isClassCodeEnabledClass">{{$t('classMgmt.studentIDColumn')}}<span v-if="isSubscriptionRostered">*</span></li>
                            <li v-if="!isClassCodeEnabledClass">{{$t('classMgmt.gradeLevelColumn')}}<span v-if="isSubscriptionRostered">*</span></li>
                        </ul>
                    </v-flex>
                </v-flex>
                <v-flex xs12 sm6 md4 px-1 :offset-md2="!isAdmin">
                    {{$t('classMgmt.optionalColumns')}}
                    <v-flex class="grey lighten-3 well">
                        <ul>
                            <li>{{$t('classMgmt.middleNameColumn')}}<span v-if="isSubscriptionRostered">*</span></li>
                            <li v-if="isClassCodeEnabledClass">{{$t('classMgmt.gradeLevelColumn')}}<span v-if="isSubscriptionRostered">*</span></li>
                            <li v-if="!isClassCodeEnabledClass">{{$t('classMgmt.languageColumn')}}</li>
                            <li>{{$t('classMgmt.passwordColumn')}}</li>
                            <li v-if="isAdmin">{{$t('classMgmt.genderColumn')}}</li>
                            <li v-if="isAdmin">{{$t('classMgmt.ethnicity')}}</li>
                            <li v-if="isAdmin">{{$t('classMgmt.limitedEnglishProficiencyColumn')}}</li>
                            <li v-if="isAdmin">{{$t('classMgmt.economicallyDisadvantagedColumn')}}</li>
                            <li v-if="isAdmin">{{$t('classMgmt.specialEdStatusColumn')}}</li>
                        </ul>
                    </v-flex>
                </v-flex>
                <v-flex v-if="isAdmin" xs12 md4 px-1>
                    {{$t('classMgmt.ethnicityCodes')}}
                    <v-flex class="grey lighten-3 well">
                        <ul>
                            <li>{{$t('classMgmt.ethnicity11')}}</li>
                            <li>{{$t('classMgmt.ethnicity12')}}</li>
                            <li>{{$t('classMgmt.ethnicity13')}}</li>
                            <li>{{$t('classMgmt.ethnicity14')}}</li>
                            <li>{{$t('classMgmt.ethnicity15')}}</li>
                            <li>{{$t('classMgmt.ethnicity16')}}</li>
                            <li>{{$t('classMgmt.ethnicity17')}}</li>
                        </ul>
                    </v-flex>
                </v-flex>
            </v-layout>
        </v-card-text>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'

	export default {
        name: 'FormattingModal',
        components: { UserManagmentModal },
        props: {
            isSubscriptionRostered: Boolean,
            isClassCodeEnabledClass: Boolean,
            value: Boolean,
            isAdmin: Boolean
        },
		computed : {
            platform(){
                 return this.$store.state.platform
            },
            size(){
                return this.isAdmin ? "820" : "500"
            },
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs', ['formattingModal'])
            },
        }
	}
</script>

<style lang="less" scoped>
    .well {
        margin: 10px 0;
        padding: 10px;
        border-radius: 4px;
        ul {
            margin: 0;
            padding: 0;
            li {
                margin: 0;
                padding: 0;
                list-style-type: none;
            }
        }
    }
</style>