<template>
	<div class="legal">
		<Headline headline="Accessibility Statement" :logo="currentEnvironment"></Headline>

		<div class="title">How Does ExploreLearning Address Accessibility Standards?</div>

		<p>At ExploreLearning, we believe all students can have success in math and science – and have fun along the way! This commitment inspires us to work continuously toward enhancing the accessibility of our products to serve the needs of learners with disabilities.</p>

		<p>ExploreLearning is taking concrete steps toward the goal of achieving alignment of our products and services with accessibility standards, including Section 508 and WCAG 2.0 and 2.1 A and AA criteria. Technologies and requirements are continually evolving, and compliance with accessibility standards is an ongoing process—not a simple, static, one-and-done exercise. As with all educational technology services and providers, some criteria in some accessibility standards may not be applicable to or currently supported within all of our educational products and services.</p>

		<p class="mb-1">Some of our actions to enhance accessibility include:</p>

		<ul>
			<li>Establishment of an ExploreLearning governance group which is responsible for fostering alignment with accessibility standards and best practices.</li>
            <li>Augmentations to existing product review and design roadmaps to amplify focus on accessibility.</li>
            <li>Engagement with respected industry partners to better understand and prioritize development needs.</li>
            <li>Ongoing testing and review of our curriculum products.</li>
		</ul>

        <p class="mb-1">Examples of recent accessibility improvements include:</p>

        <ul>
			<li>Adding text-to-speech features in Frax that allow students to hear on-screen text read aloud.</li>
			<li>Providing additional text-to-speech features on the Science4Us homepage to give students the option to hear homepage text or icons read aloud.</li>
            <li>Adding the option to disable sound and music with Frax games to allow students the option to limit audio.</li>
            <li>Enabling keyboard controls for Frax to support students that need to navigate without a mouse or touch device.</li>
			<li>Expanding keyboard controls in launched Gizmos to allow students to better navigate without a mouse or touch device.</li>
		</ul>

		<p>ExploreLearning's internal product design, development, and enhancement process incorporates considerations around accessibility throughout the product life cycle. We are committed to continued support of evolving WCAG standards and Section 508 of the Rehabilitation Act of 1973 as part of our product road maps.</p>

        <p>We are committed to continued support of evolving WCAG standards and Section 508 of the Rehabilitation Act of 1973 as part of our road map. ExploreLearning's internal product design, development, and enhancement process incorporates considerations around accessibility throughout the product lifecycle. As we implement product changes and enhancements, we take a careful approach so as to avoid unanticipated negative impacts to the effectiveness and functionality our customers rely on.</p>

        <p>We passionately support the ultimate goal of making all products as accessible as possible and look forward to sharing more information as additional enhancements and/or new features are implemented.</p>

		<p>
			For more information about our accessibility features in each product, check out our Help Center.
			<br>
			<a class="mr-3" href="https://help.explorelearning.com/en/articles/9331544-accessibility-features-in-gizmos-new">Gizmos</a> 
			<a class="mr-3" href="https://help.explorelearning.com/en/articles/9331551-accessibility-features-in-reflex-new">Reflex</a> 
			<a class="mr-3" href="https://help.explorelearning.com/en/articles/9331578-accessibility-features-in-frax-new">Frax</a> 
			<a href="https://help.explorelearning.com/en/articles/9331557-accessibility-features-in-science4us-new">Science4Us</a>
		</p>

        <p class="text-xs-right">Last Updated: May 30th, 2024</p>

	</div>
</template>

<script>
	import APIs from 'mixins/APIs'
	import Headline from 'components/layout/Headline'
	import ProductInfo from 'mixins/ProductInfo'
	export default {
		name: 'Terms',
		components: { Headline },
		mixins: [APIs, ProductInfo],
		computed: {
			friendlyURL(){
				let productID = this.getProductCode()
				if(productID){
					
					return this.productFriendURL(productID)
				} else {
					return 'ExploreLearning.com'
				}
			},
			productName(){
				let productID = this.getProductCode()
				if(productID){
					return this.productDisplayName(productID)
				} else {
					return 'ExploreLearning'
				}
			},
			supportEmail(){
				return 'Support@'+this.friendlyURL
			}
		}
	}
</script>

<style lang="less">
	.legal {
		.title {
			margin-bottom: 15px;
			border-bottom: solid 1px black;
			padding-left: 10px;
			padding-bottom: 5px;
		}
		p{
			padding: 0 20px;
		}
		.subheading {
			font-weight: bold;
			margin-bottom: 10px;
			padding-left: 10px;
		}
		ol,ul {
			margin-left: 35px;
			padding-right: 20px;
			margin-bottom: 10px;
			li {
				margin-bottom: 5px;
				ol {
					margin-top: 5px;
					list-style-type: upper-alpha;
				}
			}
		}
		.bold {
			font-weight: bold;
		}
		.underline {
			text-decoration: underline;
		}
	}
</style>
