<template>
	<v-app>
		<router-view></router-view>
		<v-snackbar v-model="cookieWarning" color="error" :timeout="warningTimer" multi-line>
			{{$t('error.cookieWarning')}}
			<v-btn dark flat @click="cookieWarning = false">
				{{$t('navigation.close')}}
			</v-btn>
		</v-snackbar>
	</v-app>
</template>

<script>
	import LoginDetector from 'mixins/LoginDetector'
	export default {
		name: 'app',
		mixins: ['LoginDetector'],
		data(){
			return {
				cookieWarning : false,
				warningTimer : 0
			}
		},
		methods : {
			checkCookies(){
				let cookieEnabled = navigator.cookieEnabled
				if(!cookieEnabled){
					document.cookie = 'testCookie'
					cookieEnabled = document.cookie.indexOf("testCookie")!=-1
				}
				return cookieEnabled || this.cookieFail()
			},
			cookieFail(){
				if(this.loggedIn){
					this.$store.commit('setDisableLogin', true)
					this.cookieWarning = true
				}
			}
		},
		created(){
			// Log Build Environment
			if(process.env.BUILD_ENV !== "prod"){
				console.log('Build Environment:', process.env.BUILD_ENV)
			}
			// Test for Cookies
			this.checkCookies()
		}
	}
</script>

<style lang="less">
	.center {
		margin-left: auto !important;
		margin-right: auto !important;
	}
	.sans-decoration {
		text-decoration: none !important;
	}
	.uppercase {
		text-transform: uppercase;
	}
</style>
