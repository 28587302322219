<template>
	<div>
        <div class="chooser">
            <div class="body">
                <div class="cols">
                    <router-link :to="backRoute" class="back"><i class="fas fa-arrow-left"></i></router-link>
                    <div class="inner card-inner">
                        <div class="header">
                            <span>
                                <el-logo text="Student Login"></el-logo>
                            </span>
                            <products :active="'reflex'"></products>
                        </div>
                        <h1 class="ul-title cols">
                            <span>Select your class:</span>
                            <span 
                                class="font-weight-light grey--text text--darken-3" 
                                v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}" 
                                content="Your Teacher"
                                ref="teacher"
                            >
                                {{teacherName}}
                            </span>
                        </h1>
                        <div v-if="classes.length" class="class-list">
                            <button v-for="c in classes" :key="c.classID" tabindex="0" class="class" @click=selectClass(c)>
                                <i class="fas fa-users"></i>
                                <span>{{c.className}}</span>
                            </button>
                        </div>
                        <div v-else class="text-xs-center mb-5">
                            <h1 class="ul-title">
                                <span>Sorry, we are unable to log you in right now...</span>
                            </h1>
                            <p>{{teacherName}} does not currently have any classes set up.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
    import elLogo from 'components/login/EL-Logo'
    import Products from 'components/login/Products'
    export default {
        name: 'SelectClass',
        metaInfo : {
            title : 'Class'
        },
        components: { elLogo, Products},
        data(){
            return {
                backRoute: {name: 'Login', params:{userType:'student'}, query: this.$route.query}
            }
        },
        computed: {
            classes: function(){
                return this.$store.state.studentLogin.classes
            },
            teacherName(){
                return this.$store.state.studentLogin.teacherName
            }
        },
        methods: {
            selectClass: function(c){
                this.$store.commit('setClass',c)
                this.$router.push({ name: 'ChooseStudent'})
            }
        },
        created: function(){
            if(!this.$store.state.studentLogin.responseData){
                this.$router.replace(this.backRoute)
            }
        },
        mounted: function(){
            //this is here to reset the tab order for keyboard users
            this.$nextTick(() => {
                this.$refs.teacher.focus();
                this.$refs.teacher.blur();
            });
        }
    }
</script>

<style lang="less" scoped>
    @color-gizmos-blue: #00A1E4;

    .card-inner {
        max-width: 90%;
        .cols span:nth-child(2) {
            max-width: 55%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .class-list {
        width: 100%;
        animation: fadein .3s linear;
    .class {
        display: flex;
        align-items: center;
        padding: .75em 1.25em !important;
        font-size: 1.1em;
        background: #efefef;
        color: @color-gizmos-blue;
        border-radius: 6px;
        font-weight: 700;
        text-decoration: none;
        margin-bottom: .75em;
        width: 100%;
        cursor: pointer;
        .fas {
            font-size: 2.1em;
            display: block;
            margin-right: .5em;
        }
        &:last-child {
            margin: 0;
        }
        &:hover {
            color: #fff;
            background: @color-gizmos-blue;
        }
    }

    .class:focus {
        box-shadow: 0 0 2px 2px #0072B4;
        transition: box-shadow .2s ease;
    }
}
</style>
