export default {
	data(){
		return {
			defaultEnvironment: process.env.defaultEnvironment,
			environments: process.env.environments
		}
	},
	computed: {
		// This one works better for components outside of the vue-router parent
		currentEnvironment(){
			if(this.environments.includes(this.requestedEnvironment)){
				return this.requestedEnvironment.toLowerCase()
			} else {
				return this.defaultEnvironment
			}
		},
		requestedEnvironment(){
			return this.$root.$route.params.environment
		},
		flow(){
  			return this.$route.params.flowOverride || this.$route.meta.flow
  		}
	},
	methods: {
		getEnvironment(){
			return this.currentEnvironment
		}
	}
}
