<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.importStudentHeader')"
        :size="importWidth.toString()"
        :persistent="uploadInProgress"
        id="ImportStudentModal"
        @closeDialogs="closeDialogs()"
    >
        <v-card-text class="import-students">
            <Messaging outline v-if="!hasFile"></Messaging>
            <dropzone
                class="app-import-dropzone"
                ref="dropzone"
                v-bind="dropzoneProps"
                v-on="{ ...dropzoneEvents }"
                :hasFile.sync="hasFile"
                :isValid.sync="isValid"
                :noChanges.sync ="noChanges"
                :isRostered="isSubscriptionRostered"
                @success="handleImportSuccess"
                @error="handleImportError"
            >
                <template #dropdown-button="{ expanded }">
                    <v-btn icon color="grey lighten-3" @click="fixTables()">
                        <v-icon v-if="expanded">keyboard_arrow_up</v-icon>
                        <v-icon v-else>keyboard_arrow_down</v-icon>
                    </v-btn>
                </template>
                <template #student-table="{ rows, columns, show }">
                    <v-data-table
                        v-if="show"
                        :headers="convertHeaders(columns)"
                        :items="rows"
                        :no-data-text="$t('classMgmt.noData')"
                        :rows-per-page-items="rowsPerPageItems('modal')"
                        :hide-actions="rows.length<rowsPerPageItems('modal')[0]"
                        class="import-student-table elevation-2 mb-3 roundedCorners"
                        dense
                    >
                        <template v-slot:items="student">
                            <tr>
                                <td>
                                    {{student.item.firstName||student.item.firstname}}
                                </td>
                                <td>
                                    {{student.item.middleName||student.item.middlename}}
                                </td>
                                <td>
                                    {{student.item.lastName||student.item.lastname}}
                                </td>
                                <td v-if="!isClassCodeEnabledStudentProp">
                                    {{student.item.userID}}
                                </td>
                                <td v-if="columns.language">
                                    {{student.item.language}}
                                </td>
                                <td>
                                    {{student.item.grade}}
                                </td>
                                <td v-if="(columns.password || columns.passwordClear) && isAdmin">
                                    {{student.item.password}}
                                </td>
                                <td v-if="columns.gender && isAdmin">
                                    {{student.item.gender}}
                                </td>
                                <td v-if="columns.ethnicity && isAdmin">
                                    {{student.item.ethnicity}}
                                </td>
                                <td v-if="columns.engProf && isAdmin">
                                    {{student.item.engProf}}
                                </td>
                                <td v-if="columns.disadv && isAdmin">
                                    {{student.item.disadv}}
                                </td>
                                <td v-if="columns.special && isAdmin">
                                    {{student.item.special}}
                                </td>
                                <td v-if="columns.institutionName">
                                    {{student.item.institutionName}}
                                </td>
                                <td v-if="columns.lastLoginOn">
                                    {{student.item.lastLoginOn||'Unknown'}}
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </template>
            </dropzone>
        </v-card-text>
        <v-card-actions style="clear:both">
            <v-menu offset-y top v-if="!isValid">
                <template v-slot:activator="{ on }">
                    <v-btn flat color="grey" v-on="on" id="ImportStudentModalHelpBtn">
                        <v-icon>help</v-icon>&nbsp;{{$t('classMgmt.instructionsAndSample')}}&nbsp;&#9662;
                    </v-btn>
                </template>
                <v-card>
                    <v-list>
                        <v-list-tile @click="openFormattingDialog()">
                            <v-list-tile-action>
                                <v-icon color="blue">table_chart</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content class="blue--text">
                                {{$t('classMgmt.formattingInstructions')}}
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile :href="sampleFileURL">
                            <v-list-tile-action>
                                <v-icon color="blue">get_app</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content class="blue--text">
                                {{$t('classMgmt.downloadSampleFile')}}
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-card>
            </v-menu>
            <v-btn v-if="hasFile && isValid && !noChanges" 
                :disabled="uploadInProgress" 
                id="ImportStudentModalCancelBtn"
                flat 
                color="error" 
                @click="hasFile=false"
            >
                <v-icon>cancel</v-icon>&nbsp;{{$t('classMgmt.cancel')}}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="hasFile && isValid && !noChanges" 
                :disabled="uploadInProgress" 
                id="ImportStudentModalImportBtn"
                color="primary" 
                class="elevation-0" 
                @click="startUpload()"
            >
                <v-flex v-if="uploadInProgress">
                    <v-progress-circular
                        indeterminate
                        color="grey"
                        small
                        style="height:20px"
                    ></v-progress-circular>
                </v-flex>
                <v-flex v-else>
                    <v-icon>check</v-icon>
                </v-flex>
                &nbsp;{{$t('classMgmt.importStudents')}}
            </v-btn>
            <v-btn v-if="hasFile && (!isValid || noChanges)" 
                id="ImportStudentModalRestartBtn"
                color="primary" 
                class="elevation-0" 
                @click="hasFile=false"
            >
                {{$t('classMgmt.tryAgain')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import APIs from 'mixins/APIs'
    import Constants from 'mixins/Constants'
    import { Dropzone } from '@explorelearning/student-import'
    import Messaging from 'components/layout/Messaging'
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import RoleDetector from 'mixins/RoleDetector'
    import { mapGetters, mapActions, mapState } from 'vuex'

	export default {
        name: 'ImportStudentModal',
        components: {
            Dropzone,
            Messaging,
            UserManagmentModal,
        },
        mixins: [APIs, Constants, RoleDetector],
        props: {
            classObj: Object,
            institutionID: Number,
            institutions: Array,
            isSubscriptionRostered: Boolean,
            productId: Number,
            rowsPerPageItems: Function,
            selectedClass: Object,
            selectedSchool: Object,
            students: Array,
            subscriptionId: Number,
            tenantID: Number,
            updateModel: Function,
            value: Boolean,
        },
        data() {
            return {
                hasFile: false,
                importWidth: 850,
                isValid: false,
                newStudents: [],
                noChanges: false,
                uploadInProgress: false,
            }
        },
		computed : {
            ...mapGetters('platformData/profile', ['isAdmin', 'profile']),
            ...mapState('platformData/app', ['frozenModel']),
            destinationType() {
                return (this.classObj) ? 'class' : 'school'
            },
            dropzoneEvents() {
                const self = this;
                return {
                    async addStudentsToLocalModel({ students }) {
                        await self.updateModel()
                    },
                    async addStudentsToClass({ studentIds = [] } = {}) {
                        // needs to make a backend call to add the students to the class, but doesn't need to return anything
                        let institutionId = self.institutionID
                        let classId = self.classObj.id
                        let skipAutoLicensing = self.selectedSchool.isClassCodeEnabled && !self.selectedClass.isClassCodeEnabled
                        await self.addStudentsToClass({institutionId,classId,studentIds, skipAutoLicensing})
                        if(self.newStudents.length){
                            self.$emit('trackAddStudents', 'import', 'new', self.newStudents.length)
                        }
                        if(studentIds.length - self.newStudents.length){
                            self.$emit('trackAddStudents', 'import', 'roster', studentIds.length - self.newStudents.length)
                        }
                        await self.updateModel()
                    },
                    async addStudentsToProduct({ studentIds = [] } = {}) {
                        // doesn't need to return anything, but async shouldn't resolve until students have been added
                        let institutionId = self.institutionID
                        let tenantId = self.tenantID
                        const subscriptionIds = [self.selectedSchool.subscriptions.find(sub => sub.productId === self.productId && sub.isActive && !sub.isExpired).id]
                        await self.platform.addUsersToSubscription({ tenantId, institutionId, subscriptionIds, loginIds: [studentIds] })
                        await self.updateModel()
                    }
                };
            },
            dropzoneProps() {
                const self = this;
                return {
                    modelStudents: self.frozenModel.users.students,
                    destinationInstitution: !self.isEmployee ? self.institutions.find(institute => {return institute.id == self.institutionID}) : {},
                    destinationClass: self.classObj,
                    fullOptions: self.isAdmin,
                    availableSpots: self.availableSpots,
                    isClassCodeEnabledStudentProp: self.isClassCodeEnabledStudentProp,
                    callbacks: {
                        async checkForExistingStudents({ ids = [] } = {}) {
                            // must return array of students
                            let tenantId = self.tenantID;
                            let existingStudents = await self.fetchStudentsById({ studentIds: ids, tenantId })
                            return { students: existingStudents };
                        },
                        async createStudents({ studentData = [] } = {}) {
                            // must return array of the created students
                            studentData.forEach(function(student){
                                student.gradeId = self.getGradeLevelID(student.grade)
                                student.languageId = self.getLanguageID(student.language)
                                student.sisUserId = student.userID
                            })
                            let institutionId = self.institutionID
                            let tenantId = self.tenantID
                            let students = await self.createStudents({institutionId, tenantId, students:studentData})
                            self.newStudents = students
                            await self.updateModel()
                            return { students };
                        },
                        async transferStudents({ students = [], destinationId = '' } = {}) {
                            // doesn't need to return anything, but async shouldn't resolve until transfer complete
                            await self.transferStudents({students:students, institutionId: destinationId})
                            self.$emit('trackAddStudents', 'import', 'transfer', students.length)
                            await self.updateModel()
                        },
                        async updateStudents({ studentData = [] } = {}) {
                            // doesn't need to return anything, but async shouldn't resolve until update complete
                            let institutionId = self.institutionID
                            let tenantId = self.tenantID
                            studentData.forEach(student => {
                                if(typeof student.reflexJson == 'object'){
                                    student.reflexJson = JSON.stringify(student.assignmentID)
                                }
                            });
                            await self.updateStudents({institutionId, tenantId, students:studentData})
                            await self.updateModel()
                        }
                    },
                    messages: {
                        dropzoneMessage: 'Click to select a .CSV file or drag and drop one here.',
                        dataErrorAlert: 'We found errors with the data in the file provided.',
                        existingStudentIDsAlert: 'The following students from your file match existing students according to their Student IDs, but the last names do not match.',
                        noStudentsAlert: 'This file does not contain any student data.',
                        rosteredDataAlert: 'This file would overwrite protected roster data.',
                        createNotAllowedAlert: 'You cannot create new student accounts in this institution. If you are attempting to update existing students, please edit this file to remove any non-existing students.',
                        rosteredTransferAlert: 'You cannot trasfer students controled by external rostering.',
                        studentCapReachedAlert: `The number of students to be added by this attempt will exceed the class’s limit of ${this.profile.studentsPerClassCap} students.`,
                        nothingToDoAlert: `No new students were found in the file provided. The students in the file have the exact same data as students already in the ${this.destinationType}.`
                    }
                }
            },
            platform(){
                 return this.$store.state.platform
            },
            availableSpots() {
                if (!this.isAdmin) {
                    return this.profile.studentsPerClassCap - this.classObj.studentIDs.length
                } else {
                    return Infinity
                }            
            },
            isClassCodeEnabledStudentProp() {
                return this.selectedClass
                    ? this.selectedSchool.isClassCodeEnabled && this.selectedClass.isClassCodeEnabled
                    : false
            },
            sampleFileURL() {
                return `${this.getStaticURL()}${this.isClassCodeEnabledStudentProp ? 'csv/ImportStudentsCCSample.csv' : 'csv/ImportStudentsToClassSample-new.csv'}`
            }
        },
        methods: {
            ...mapActions('platformData/students', ['updateStudents', 'createStudents', 'fetchStudentsById', 'transferStudents']),
            ...mapActions('platformData/classes', ['addStudentsToClass']),
            closeDialogs() {
                this.$emit('closeDialogs')
            },
            convertHeaders(oldHeaders){
                let newHeaders = []
                for(let header in oldHeaders){
                    let newHeader = {sortable:false}
                    newHeader.text = oldHeaders[header].name
                    newHeader.value =  header
                    newHeaders.push(newHeader)
                }
                return newHeaders
            },
            fixTables(){
                --this.importWidth
            },
            handleImportError(msg){
                console.log(msg)
                this.hasFile = false
                this.uploadInProgress = false
                this.$store.commit('setMessageError',this.$t('error.import'))
            },
            handleImportSuccess(){
                this.$emit('handleSuccess', 'Import Successful')
                this.$emit('closeDialogs')
            },
            openFormattingDialog(){
                this.$emit('openDialog', 'formattingModal', {}, false)
            },
            async startUpload(){
                this.uploadInProgress = true
                await this.$refs.dropzone.upload()
                this.uploadInProgress = false
            },
        },
        watch: {
            value(val) {
                if (val){
                    this.hasFile = false
                }
            }
        }
	}
</script>

<style lang="less">
    .dropzone {
        border-radius: 5px;
        border-width: 2px;
        .material-icons {
            font-size: 100px;
        }
    }
    .import-students {
        hr {
            display:none;
        }
        .student-import-dropzone__summary {
            padding-bottom: 10px;
            border-bottom: solid 2px #eee;
            >div {
                color: grey;
                h4 {
                    font-size: 20px;
                    font-weight: 400;
                    color: black;
                }
            }
            .student-import-dropzone__summary-header {
                width: 50%;
                float: left;
                clear: left;
            }
            .student-import-dropzone__summary-destination {
                text-align: right;
            }
            .student-import-dropzone__filename {
                >* {
                    float: left;
                }
                i {
                    display: block;
                }
                span {
                    display: block;
                    margin-top: 2px;
                    margin-left: 5px;
                }
            }
        }
        .student-import-dropzone__results {
            margin-top: 30px;
            clear: both;
            .student-import-dropzone__password-match-warning {
                margin-bottom: 30px
            }
        }
        .student-import-dropzone__message {
            clear: both;
            word-wrap: break-word;
            >* {
                float: left;
            }
            >i.material-icons {
                margin-right: 10px;
                margin-top: 4px;
                margin-bottom: 10px;
            }
            h4 {
                font-size: 18px;
                font-weight: 400;
            }
            >div {
                margin-bottom: 20px
            }
            >i.material-icons {
                padding: 10px;
                margin-right: 10px;
                border-radius: 50%;
                margin-top: 4px;
                margin-bottom: 10px;
            }
            .student-import-dropzone__results-text-subheader {
                color: grey;
            }
        }
        .student-import-dropzone__message--info, .student-import-dropzone__message--good {
            >i.material-icons {
                background-color: #4CAF50;
            }
            >.student-import-dropzone__results-toggle-container{
                float: right;
            }
        }
        .student-import-dropzone__message--warn {
            margin-top: 30px;
            >i.material-icons {
                background-color: #fb8c00;
                margin-top: 4px;
            }
            >div {
                max-width: 90%;
            }   
            >.student-import-dropzone__results-text {
                margin-bottom: 20px;
            }            
            h4 {
                margin-bottom: 10px;
            }
            >.student-import-dropzone__results-toggle-container{
                float: right;
            }
        }
        .student-import-dropzone__message--bad {
            >i.material-icons {
                color:#e26a6a;
                font-size: 40px;
                margin-top: 30px
            }
            >div {
                width: 90%;
                margin-top: 30px
            }
            h4 {
                margin-bottom: 10px;
            }
            ul {
                margin: 10px 0;
            }
            >:last-child{
                margin-bottom: 20px;
            }
        }
        .v-card__actions, .import-student-table {
            clear: both;
        }
    }
</style>
