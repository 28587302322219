import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// Imports For Use In File
import APIs from 'mixins/APIs'
import Forwarding from 'mixins/Forwarding'
import store from '../state/index.js'

// Universal Components
import ThemeSwitcher from 'components/ThemeSwitcher'
import Logout from 'components/Logout'

// Unified Login Components
import Login from 'components/login/Login'
import ChooseClass from 'components/login/ChooseClass'
import ChooseStudent from 'components/login/ChooseStudent'
import ChooseProduct from	'components/login/ChooseProduct'
import StudentPassword from	'components/login/StudentPassword'

// App Login Components (Old Login)
import AppUsername from 'components/app/TeacherUsername'
import AppClass from 'components/app/ChooseClass'
import AppStudent from 'components/app/ChooseStudent'
import AppPassword from 'components/app/StudentPassword'

// Password Recovery Components
import ForgotPassword from 'components/password/ForgotPassword'
import ChooseAccount from 'components/password/ChooseAccount'
import ResetPassword from 'components/password/ResetPassword'

// Free Trial Components
import Email from 'components/email/Email'
import TrialExisting from 'components/trial/TrialExisting'

// Registration Components
import RegCode from 'components/registration/RegCode'
import AccountInfo from 'components/registration/AccountInfo'
import SchoolInfo from 'components/registration/SchoolInfo'

// Student Self Enrollment Components
import ClassCode from 'components/join/ClassCode'

//Grant Components
import GrantInfo from 'components/grant/Info'
import Question from 'components/grant/Question'
import GrantTerms from 'components/grant/AcceptTerms'
import GrantComplete from 'components/grant/Complete'
import GrantCompleteClosed from 'components/grant/CompleteClosed'
import GrantActivation from 'components/grant/Activate'
import GrantCreated from 'components/grant/Created'

// E-Commerce Components
import ProductCatalog from 'components/ecommerce/ProductCatalog'
import ShoppingCart from 'components/ecommerce/ShoppingCart'
import BillingInfo from 'components/ecommerce/BillingInfo'
import Address from 'components/ecommerce/Address'
import CheckoutConfirm from 'components/ecommerce/CheckoutConfirm'

// Account Components
import Account from 'components/account/Account'

// Product Access
import Home from 'components/home/Home'
import OptIn from 'components/home/OptIn'

// Classroom Managment Components
import UserMgmt from 'components/classMgmt/UserMgmt'

// Contact Form Components
import Support from 'components/contact/Support'
import Quote from 'components/contact/Quote'
import ContactSuccess from 'components/contact/Success'

// Email Validation
import EmailValidation from 'components/email/Validation'

// Legal Compoents
import Terms from 'components/legal/Terms'
import Privacy from 'components/legal/Privacy'
import DoNotSellMyInformation from 'components/legal/DoNotSellMyInformation'
import Accessibility from 'components/legal/Accessibility'

// Employee Only Components
import AdminContainer from 'components/admin/AdminContainer'
import PreApprovedGrantCreated from 'components/admin/GrantCreated'

// Meta Object Helpers
const fullCard = true
const fullPage = true
const halfCard = true
const hideSupport = true
const logo = true
const elSkin = true
const returnNav = true
const hideFlowInTitle = true
const tabOverride = true
const hideManageUsersMenu = true
const hideLauncherMenu = true
const navElOrangeBg = true
const noCardFullWidth = true

const routes = [
	// Login Shortcuts
	{path: '/student', redirect: {name:'Login', params: {environment: 'el', userType:'student'}}},
	{path: '/teacher', redirect: {name:'Login', params: {environment: 'el', userType:'educator'}}},
	{path: '/educator', redirect: {name:'Login', params: {environment: 'el', userType:'educator'}}},
	{path: '/parent', redirect: {name:'Login', params: {environment: 'el', userType:'parent'}}},
	{path: '/employee', redirect: {name:'Login', params: {environment: 'el', userType:'employee'}}},
	// Pages That Only Logged Out Users Can Access
	{
		path: '/:environment',
		component: ThemeSwitcher,
		meta: { requireLogout: true },
		children: [
			// Login
			{path: '', redirect: {name:'Login'}},
			{path: 'login/teacher', redirect:'login/educator'}, // Legacy Educator Login URL
			{path: 'login/:userType?', name: 'Login', component: Login, meta: {elSkin, flow: 'login', cardWidth:500, tabOverride, navElOrangeBg }},
			// Student Login
			{path: 'student', name:'StudentLogin', redirect:'login/student'},
			{path: 'login/student/class', name: 'ChooseClass', component: ChooseClass, meta: {tabOverride, hideSupport, elSkin, flow: 'student', cardWidth:600}},
			{path: 'login/student/name', name: 'ChooseStudent', component: ChooseStudent, meta: {tabOverride, hideSupport, elSkin, flow: 'student', cardWidth:600}},
			{path: 'login/student/product', name: 'ChooseProduct', component: ChooseProduct, meta: {tabOverride , hideSupport, elSkin, flow: 'student', cardWidth:600}},
			{path: 'login/student/password', name: 'StudentPassword', component: StudentPassword, meta: {tabOverride, hideSupport, elSkin, flow: 'student', cardWidth:600}},
			// Student Login - App
			{path: 'app/login', name: 'AppLogin', component: AppUsername, meta: {allowedEnvironments:['reflex','frax'], fullPage, logo, flow: 'app'}},
			{path: 'app/login/class', name: 'AppClass', component: AppClass, meta: {allowedEnvironments:['reflex','frax'], fullPage, logo, flow: 'app'}},
			{path: 'app/login/name', name: 'AppStudent', component: AppStudent, meta: {allowedEnvironments:['reflex','frax'], fullPage, logo, flow: 'app'}},
			{path: 'app/login/password', name: 'AppPassword', component: AppPassword, meta: {allowedEnvironments:['reflex','frax'], fullPage, logo, flow: 'app'}},
			// Non-Student Login
			{path: 'teacher', redirect:'login/educator'},
			{path: 'educator', name:'TeacherLogin', redirect:'login/educator'},
			{path: 'parent', name: 'ParentLogin', redirect:'login/parent'},
			{path: 'employee', name: 'EmployeeLogin', redirect:'login/employee'},
			// Password Recovery
			{path: 'forgot', name: 'ForgotPassword', component: ForgotPassword, meta: {tabOverride, elSkin, halfCard, logo, flow:'passReset'}},
			{path: 'accounts/:resetKey', name: 'ChooseAccount', component: ChooseAccount, meta: {elSkin, halfCard, logo, flow:'passReset'}},
			{path: 'reset/:resetKey', name: 'ResetPassword', component: ResetPassword, meta:{elSkin, halfCard, logo, flow:'passReset'}},
			// Expired Password
			{path: 'expired', name: 'ExpiredPassword', component: ResetPassword, meta:{elSkin, halfCard, logo, flow:'passExp'}},
			// E-Commerce
			{path: 'checkout/accountinfo', name: 'CheckoutAccountInfo', component: AccountInfo, meta: {fullCard, flow:'checkout'}},
			// Grant
			{path: 'grant/accountinfo', name: 'GrantAccountInfo', component: AccountInfo, meta: {fullCard, flow: 'grant-act', allowedEnvironments: ['reflex','frax']}}
		]
	},
	// Pages That Only Logged In Users Can Access
	{
		path: '/:environment',
		component: ThemeSwitcher,
		meta: { requireLogin: true },
		children: [
			// Teacher Homepage
			{path: 'home', name: 'Home', component: Home, meta: {hideManageUsersMenu, hideLauncherMenu, tabOverride, elSkin, logo, flow: 'home', cardWidth:800, restrictedRoles:[4], noCardFullWidth }},
			{path: 'products', redirect: {name:'Home'}},
			{path: 'optin', name: 'OptIn', component: OptIn, meta: {elSkin, logo, fComp:'HomeFooter', flow: 'home', cardWidth:800, restrictedRoles:[4]}},
			// Trial
			{path: 'trial/existing/:regCode?', name: 'TrialRegExisting', component: TrialExisting, meta: {halfCard, logo, flow:'trial', restrictedEnvironments:['el']}},
			// Registration
			{path: 'registration/school', name: 'RegSchoolInfo', component: SchoolInfo, meta: {fullCard, flow:'reg'}},
			// Account - New Skin
			// {path: 'account', redirect: {name:'Account'}},
			// {path: 'profile', name: 'Account', component: Account, meta: {elSkin, tab:'profile'}},
			// {path: 'password', redirect: {name:'Password'}},
			// {path: 'security', name: 'Password', component: Account, meta: {elSkin, tab:'password'}},
			// {path: 'subscriptions', name: 'Subscriptions', component: Account, meta: {elSkin, tab:'subs'}},
			// {path: 'orderhistory', redirect: {name:'OrderHistory'}},
			// {path: 'purchases', name: 'OrderHistory', component: Account, meta: {elSkin, tab:'history'}},
			// Account - Old Skin
			{path: 'account', redirect: {name:'Account'}},
			{path: 'profile', name: 'Account', component: Account, meta: {fullCard, flow:'profile', tab:'profile'}},
			{path: 'password', redirect: {name:'Password'}},
			{path: 'security', name: 'Password', component: Account, meta: {fullCard, flow:'profile', tab:'security'}},
			{path: 'subscriptions', name: 'Subscriptions', component: Account, meta: {fullCard, flow:'profile', tab:'subscriptions'}},
			{path: 'orderhistory', redirect: {name:'OrderHistory'}},
			{path: 'purchases', name: 'OrderHistory', component: Account, meta: {fullCard, flow:'profile', tab:'orderHistory'}},
			// Ecommerce
			{path: 'secure/catalog', name: 'SecureProductCatalog', component: ProductCatalog, meta: {fullCard, secure: true, flow:'catalog'}},
			{path: 'checkout/cart', name: 'Cart', component: ShoppingCart, meta: {fullCard, flow:'cart'}},
			{path: 'checkout/school', name: 'CheckoutSchoolInfo', component: SchoolInfo, meta: {fullCard, flow:'checkout'}},
			{path: 'checkout/billing', name: 'BillingInfo', component: BillingInfo, meta:{fullCard, flow:'checkout'}},
			{path: 'checkout/address', name: 'Address', component: Address, meta:{fullCard,  flow:'checkout'}},
			{path: 'checkout/confirm', name: 'CheckoutConfirm', component: CheckoutConfirm, meta:{fullCard, flow:'checkout'}},
			// Grant
			{path: 'grant/created', name: 'GrantCreated', component: GrantCreated, meta: {halfCard, logo, flow:'grant-act', allowedEnvironments: ['reflex','frax']}},
			// Class Management
			{path: 'users', name: 'UserManagement', component: UserMgmt, meta: {hideManageUsersMenu, flow: 'class', elSkin, returnNav, hideFlowInTitle}},
			{path: 'classes', redirect:{name: 'UserManagement'}},
			// EMPLOYEE ONLY PAGES
			{
				path: 'admin',
				component: AdminContainer,
				meta: { employeeOnly: true },
				children: [
					{path: 'grant/school', name: 'PreApprovedSchoolInfo', component: SchoolInfo , meta: {fullCard, flow:'pre-approved', allowedEnvironments: ['reflex','frax']}},
					{path: 'grant/created', name: 'PreApprovedCreated', component: PreApprovedGrantCreated, meta: {halfCard, flow:'pre-approved', allowedEnvironments: ['reflex','frax']}},
					{path: 'grant', name: 'PreApprovedGrant', component: GrantInfo , meta: {fullCard, flow:'pre-approved', allowedEnvironments: ['reflex','frax']}}
				]
			}
		]
	},
	// Pages Both Logged In and Logged Out Users Can Access
	{
		path: '/:environment',
		component: ThemeSwitcher,
		meta : {},
		children: [
			// Free Trial
			{path: 'trial', name: 'TrialReg', component: Email, meta: {halfCard, logo, flow:'trial', restrictedEnvironments:['el']}},
			{path: 'free', name: 'FreeReg', component: Email, meta: {halfCard, logo, flow:'free', allowedEnvironments:['gizmos']}},
			// Registration
			{path: 'reg/:regCode', redirect:{name:'RegCode'}},
			{path: 'registration/regcode/:regCode?', name: 'RegCode', component: RegCode, meta: {halfCard, logo, flow:'reg'}},
			{path: 'registration/account', name: 'RegAccountInfo', component: AccountInfo, meta: {fullCard, flow:'reg'}},
			// Student Self Enrollment
			{path: 'join/:classCode?', name: 'ClassCode', component: ClassCode, meta: {halfCard, logo, flow:'join', allowedEnvironments: ['gizmos']}},
			// Parent Account
			{path: 'registration/parent', name: 'ParentReg', component: Email, meta: {halfCard, logo, flow:'parent', allowedEnvironments: ['reflex']}},
			// Support Form
			{path: 'support', name: 'Support', component: Support, meta: {halfCard, flow: 'support'}},
			{path: 'support/success', name: 'SupportSuccess', component: ContactSuccess, meta: {flow: 'support',halfCard}},
			// Admin Contact Info Form
			{path: 'contact', name: 'AdminContact', component: Support, meta: {halfCard, flow: 'admin-contact', allowedEnvironments: ['el']}},
			// Request a Quote Form
			{path: 'quote', name: 'Quote', component: Quote, meta: {halfCard, logo, flow:'quote'}},
			{path: 'quote/school', name:'QuoteSchoolInfo', component: SchoolInfo, meta: {fullCard, flow:'quote'}},
			{path: 'quote/success', name: 'QuoteSuccess', component: ContactSuccess, meta: {halfCard, flow:'quote'}},
			// Request a Demo Form
			{path: 'demo', name: 'Demo', component: Quote, meta: {halfCard, logo, flow:'demo'}},
			{path: 'demo/school', name:'DemoSchoolInfo', component: SchoolInfo, meta: {fullCard, flow:'demo'}},
			{path: 'demo/success', name: 'DemoSuccess', component: ContactSuccess, meta: {halfCard, flow:'demo'}},
			// Conference Contact Form
			{path: 'conference', name: 'Conference', component: Quote, meta: {halfCard, logo, fullPage, flow:'conference', allowedEnvironments:['el']}},
			{path: 'conference/school', name:'ConferenceSchoolInfo', component: SchoolInfo, meta: {fullCard, fullPage, flow:'conference', allowedEnvironments:['el']}},
			// Grant
			{path: 'grant/school', name:'GrantSchoolInfo', component: SchoolInfo, meta: {fullCard, flow:'grant', allowedEnvironments: ['reflex','frax']}},
			{path: 'grant/question/:questionID', name:'GrantQuestion', component: Question, meta: {fullCard, flow:'grant', allowedEnvironments: ['reflex','frax']}},
			{path: 'grant/terms', name:'GrantTerms', component: GrantTerms, meta: {fullCard, flow:'grant', allowedEnvironments: ['reflex','frax']}},
			{path: 'grant/complete', name:'GrantComplete', component: GrantComplete, meta: {halfCard, flow:'grant', logo, allowedEnvironments: ['reflex','frax']}},
			{path: 'grant/closed', name:'GrantCompleteClosed', component: GrantCompleteClosed, meta: {halfCard, logo, flow:'grant', allowedEnvironments: ['reflex','frax']}},
			{path: 'grant/activation/:grantCode', name:'GrantActivation', component: GrantActivation, meta: {halfCard, logo, flow: 'grant-act', allowedEnvironments: ['reflex','frax']}},
			{path: 'grant/:grantRegCode?', name: 'Grant', component: GrantInfo, meta: {fullCard, flow: 'grant', allowedEnvironments: ['reflex','frax']}},
			// Legal
			{path: 'terms', name: 'Terms', component: Terms, meta: {fullCard, flow: 'terms'}},
			{path: 'privacy', name: 'Privacy', component: Privacy, meta: {fullCard, flow: 'privacy'}},
			{path: 'donotsell', name: 'DoNotSellMyInformation', component: DoNotSellMyInformation, meta: {fullCard, flow:'dontsell'}},
			{path: 'accessibility', name: 'Accessibility', component: Accessibility, meta: {fullCard, flow: 'accessibility'}},
			// E-Commerce
			{path: 'catalog', name: 'UnsecureProductCatalog', component: ProductCatalog, meta: {fullCard, flow:'catalog', secure: false}},
			// Email Validation
			{path: 'validate/:regCode', name: 'EmailValidation', component: EmailValidation, meta: {halfCard, flow:'email'}}
		]
	},
	// Logout
	{path :  '/:environment/logout', name: 'Logout', component : Logout},
	// Unmatched URLs
	{path: '/:environment/*', redirect: {name:'Login'}},
	{path: '*', redirect: {name: process.env.fallbackRoutes[process.env.defaultEnvironment], params: {environment: process.env.defaultEnvironment}}}
]

const router = new Router({
	mode:  process.env.routerMode,
	base: process.env.rootPath + '/',
	routes
})

router.beforeEach((to, from, next) => {
	// Set Up Forwarding
	if(to.query.f){
		store.state.navigation.forwardingURL = to.query.f
	}

	// Create Mutable Route
	let nextRoute = {}

	// Pass UTM Query String Vars Along
	let forwardedQueryStringKeys = ['fbclid','gclid']
	for(let queryStringKey in from.query){
		if(queryStringKey.toLowerCase().indexOf('utm_')==0 || forwardedQueryStringKeys.includes(queryStringKey)){
			let fromQueryString = from.query[queryStringKey]
			if(Array.isArray(fromQueryString)){
				fromQueryString = fromQueryString[0]
			}
			if(to.query[queryStringKey]!==fromQueryString){
				nextRoute = {...to}
				nextRoute.query[queryStringKey]=fromQueryString
			}
		}
	}

	// Establish Fallback Routes
	const environments = process.env.environments
	const fallbackRoutes = process.env.fallbackRoutes

	// Get Environment
	var environment = ''
	if(to.params.environment){
		environment = to.params.environment.toLowerCase()
	}

	// Make Sure Environment In URL Exists
	const fraxEnvironment = 'frax'
	const gizEnvironment = 'gizmos'
	const s4uEnvironment = 's4us'
	const environmentTranslations = {
		'fractions' : fraxEnvironment,
		'frac' : fraxEnvironment,
		'gizmo' : gizEnvironment,
		'giz' : gizEnvironment,
		'science4us' : s4uEnvironment,
		's4u' : s4uEnvironment
	}
	if(environmentTranslations[environment]){
		nextRoute = {...to}
		nextRoute.params.environment = environmentTranslations[environment]
		next(nextRoute)
		return
	} else {
		if(!environments.includes(environment)){
			nextRoute.name = fallbackRoutes[process.env.defaultEnvironment]
			nextRoute.params = {environment: process.env.defaultEnvironment}
			next(nextRoute)
			return
		}
	}

	// Make Sure Route is Valid In Given Environment
	if(to.meta.restrictedEnvironments && to.meta.restrictedEnvironments.includes(environment)){
		next({name:fallbackRoutes[environment], params: {environment}})
		return
	} else if (to.meta.allowedEnvironments && !to.meta.allowedEnvironments.includes(environment)){
		next({name:fallbackRoutes[environment], params: {environment}})
		return
	}

	// Vars for Routing
	let userInfo = null
	let claimsEndpoint = APIs.methods.getClaimsAPI(environment)
	
	// User Must Be Logged Out Routes
	if(to.matched.some(record => record.meta.requireLogout)){
		Vue.axios.get(claimsEndpoint).then(response => {
			const referrerGizmoID = Forwarding.methods.extractGizmoId(false)
			if(to.query.gizmos && referrerGizmoID){
				Forwarding.methods.forward(process.env.gizmosReportingAppURL + '/launch-gizmo/' + referrerGizmoID)
			}
			userInfo = APIs.methods.decodeXclaims(response)
			if(userInfo){
				// Log Out Students
				if(userInfo.roleID == 2){
					let appUser = to.name=="AppLogin"
					next({ name: 'Logout' , params: {environment, appUser}})
					return
				} 
				// Detect Time4MathFacts Users and Redirect them
				for(let key in userInfo.productMap){
					if(userInfo.productMap[key].prdID == 1 && userInfo.productMap[key].srcID == 2){
						Forwarding.methods.forward(process.env.reflexReportingAppURL, {auto:1})
						return
					}
				}
				if(userInfo.roleID == 4){
					// Parent (Reflex Only)
					Forwarding.methods.forward(process.env.reflexReportingAppURL, {auto:1})
				} else {
					// Educator & Employees
					next({name:'Home', params: {environment:'el'}})
				}
			} else {
				// Not Logged In
				next(nextRoute)
			}
		}, error => {
			next(nextRoute)
		})
	// User Must Be Logged In Routes
	} else if(to.matched.some(record => record.meta.requireLogin)) {
		const userTypeMap = {
			1 : 'educator',
			2 : 'student',
			3 : 'educator',
			4 : 'parent',
			5 : 'educator',
			10 : 'educator',
			100 : 'employee',
			101 : 'employee',
			102 : 'employee',
			103 : 'employee',
			104 : 'employee',
			105 : 'employee',
		}
		let userType = 'educator';
		Vue.axios.get(claimsEndpoint).then(response => {
			userInfo = APIs.methods.decodeXclaims(response)
			let roleID = userInfo.roleID
			userType = userTypeMap[roleID] 
			store.state.jti = userInfo.jti
			if(userInfo){
				// Logout Students
				if(userInfo.roleID == 2){
					next({ name: 'Logout' , params: {environment}})
					return
				}
				// Detect Time4MathFacts Users and Redirect them
				for(let key in userInfo.productMap){
					if(userInfo.productMap[key].prdID == 1 && userInfo.productMap[key].srcID == 2){
						Forwarding.methods.forward(process.env.reflexReportingAppURL, {auto:1})
						return
					}
				}
				// Determine if User had Privileges to Reach Page
				if(to.matched.some(record => record.meta.restrictedRoles) || to.matched.some(record => record.meta.employeeOnly)) {
					// Verify Employee Privileges if Needed
					if(to.matched.some(record => record.meta.employeeOnly)){
						// See If User Employee
						if(userInfo.roleID >= 100){
							// Employee
							next(nextRoute)
						} else {
							// Not Employee
							next({name:'Home', query: {e:'notAdmin'}})
						}
					} else {
						// Check Restricted Roles
						if(to.meta.restrictedRoles.includes(userInfo.roleID)){
							if(userInfo.roleID==4){
								Forwarding.methods.forward(process.env.reflexReportingAppURL, {auto:1})
							} else {
								next({name:'Home', param:{enviorment}, query: {e:'notAdmin'}})
							}
						} else {
							next(nextRoute)
						}
					}
				} else {
					next(nextRoute)
				}
			} else {
				store.state.navigation.forwardingURL = to
				store.state.jti=null
				next({name:'Login', params: {environment, userType, flowOverride:to.meta.flow}, query})
			}
		}, error => {
			store.state.navigation.forwardingURL = to
			let query = {}
			if(error.response==undefined || error.response.status==404 || error.response.status==0){
				query.e = 404
			}
			store.state.jti=null
			next({name:'Login', params: {environment, userType, flowOverride:to.meta.flow}, query})
		})
	// User Can Be Logged In or Logged Out Routes
	} else {
		// See If User is Logged In
		Vue.axios.get(claimsEndpoint).then(response => {
			userInfo = APIs.methods.decodeXclaims(response)
			store.state.jti = userInfo.jti
			// Detect Time4MathFacts Users and Redirect them
			for(let key in userInfo.productMap){
				if(userInfo.productMap[key].prdID == 1 && userInfo.productMap[key].srcID == 2){
					Forwarding.methods.forward(process.env.reflexReportingAppURL, {auto:1})
					return
				}
			}
			next(nextRoute)
		}, error => {
			next(nextRoute)
		})
	}
})

router.afterEach((to, from) => {
	// Inform GTM Page Has Loaded
	var dataObject = {
		event : 'page_load',
		pageName : to.name,
		pagePath : to.path
	}
	if(typeof dataLayer != 'undefined'){
	  dataLayer.push(dataObject);
	}
})

export default router
