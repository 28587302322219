<template>
  <form @submit.prevent="advance">
  	<v-layout wrap>
    	<Headline :headline="headline" :align="headlineAlignment"></Headline>
		<Messaging outline></Messaging>
    </v-layout>
    <v-layout wrap>
    	<v-flex xs3 v-if="!isConference">
			<v-autocomplete
				v-model="titleID"
				v-bind:items="userTitles"
				item-value="id"
				item-text="label"
				:label="$t('form.title')">
			</v-autocomplete>
		</v-flex>
    	<v-flex :xs12="isConference" :xs9="!isConference">
			<v-text-field
				v-model="firstName"
				:label="$t('form.firstName')"
				v-validate="'required|max:30|noTags|noEscapes'"
				name="first name"
				:error-messages="errors.collect('first name')">
			</v-text-field>
		</v-flex>
		<v-flex xs12>
			<v-text-field
				v-model="lastName"
				:label="$t('form.lastName')"
				v-validate="'required|max:30|noTags|noEscapes'"
				name="last name"
				:error-messages="errors.collect('last name')">
	      	</v-text-field>
		</v-flex>
		<v-flex xs12>
			<v-text-field
				v-model="email"
				:label="$t('form.email')"
				v-validate="'required|email|max:100|is_not:user@explorelearning.com'"
				name="email"
				:error-messages="errors.collect('email')"
				ref="email">
	      	</v-text-field>
		</v-flex>
		<v-flex xs12 v-if="flow=='quote'">
			<v-text-field
				v-model="emailConfirm"
				:label="$t('form.emailConfirm')"
				v-validate="'required|email|confirmed:email'"
				name="email confirmation"
				:error-messages="errors.collect('email confirmation')">
	      </v-text-field>
		</v-flex>
		<v-flex xs8 v-if="!isConference">
			<v-text-field
				v-model="phone"
				:label="$t('form.phoneNum')"
				v-validate="'required|numeric'"
				name="phone number"
				:error-messages="errors.collect('phone number')">
			</v-text-field>
		</v-flex>
		<v-flex xs4 v-if="!isConference">
			<v-text-field
				v-model="phoneExt"
				:label="$t('form.ext')"
				v-validate="'noTags'"
				name="phone extension"
				:error-messages="errors.collect('phone extension')">
	      	</v-text-field>
		</v-flex>
		<v-flex v-if="isEL && !isConference">
			<v-select
				v-model="product"
				:items="availableProducts"
				item-text="displayName"
				item-value="environment"
				:label="$t('support.product')"
				v-validate="'required'"
				name="product"
				:error-messages="errors.collect('product')"
			>
			</v-select>
		</v-flex>
		<v-flex xs12 v-if="!isConference">
			<v-text-field
				v-model="contactTime"
				:label="$t('quote.contactTime')"
				v-validate="'required'"
				name="contact time"
				:error-messages="errors.collect('contact time')">
	      </v-text-field>
		</v-flex>
		<v-flex xs12 v-if="!isConference">
			<v-textarea
				v-model="comments"
				:label="$t('demo.msg')"
				:placeholder="placeholder"
				v-validate="'required'"
				name="details"
				:error-messages="errors.collect('details')">
			</v-textarea>
		</v-flex>
    	<v-flex class="text-xs-right">
	      <v-btn color="primary" large raised type="submit">
	        {{$t('navigation.next')}}
	    	</v-btn>
	    </v-flex>
  	</v-layout>
	<!-- Snakcbars / Toasts -->
	<v-snackbar
		v-model='successBar'
		color="success"
		auto-height
	>
		{{$t('conference.success')}}
		<v-btn dark flat @click="successBar = false">
			{{$t('navigation.close')}}
		</v-btn>
	</v-snackbar>
  </form>
</template>

<script>
	import Constants from 'mixins/Constants'
	import Headline from 'components/layout/Headline'
	import Messaging from 'components/layout/Messaging'
	import AutoFill from 'mixins/AutoFill'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import ProductInfo from 'mixins/ProductInfo'
	import { mapGetters } from 'vuex'
	export default {
		name: 'Quote',
		metaInfo : {
			title : 'Contact Information'
		},
		components: { Headline, Messaging },
		mixins : [Constants, AutoFill, EnvironmentDetector, ProductInfo],
		data () {
		return {
			titleID : '',
			firstName: '',
			lastName: '',
			email: '',
			emailConfirm: '',
			phone: '',
			phoneExt: '',
			product: null,
			contactTime: '',
			comments: '',
		}
		},
		computed: {
			...mapGetters('platformData/profile', ['profile']),
			headline(){
				if(this.flow=='quote'){
					return this.$t('quote.header')
				} else if(this.flow=="demo"){
					return this.$t('demo.header')
				} else if(this.flow=="conference"){
					return this.$t('conference.header')
				}
			},
			headlineAlignment(){
				if(this.flow === 'conference'){
					return 'center'
				} else {
					return 'left'
				}
			},
			isConference(){
				return this.flow=="conference"
			},
			isEL(){
				return this.currentEnvironment=='el'
			},
			placeholder(){
				if(this.flow=='quote'){
					return this.$t('demo.msgPlaceholder')
				} else if(this.flow=="demo"){
					return this.$t('demo.msgPlaceholderDemo')
				}
			},
			successBar(){
				return this.$route.params.success
			}
		},
		methods : {
			advance: async function(){
				var valid = await this.$validator.validateAll()
				if(valid){
					this.$store.commit('setTitleID',this.titleID)
					this.$store.commit('setServicesFormTitleID',this.titleID)
					this.$store.commit('setFirstName',this.firstName)
					this.$store.commit('setServicesFormFirstName',this.firstName)
					this.$store.commit('setLastName',this.lastName)
					this.$store.commit('setServicesFormLastName',this.lastName)
					this.$store.commit('setEmail',this.email)
					this.$store.commit('setServicesFormEmail',this.email)
					this.$store.commit('setPhone',this.phone)
					this.$store.commit('setServicesFormPhone',this.phone)
					this.$store.commit('setPhoneExt',this.phoneExt)
					this.$store.commit('setServicesFormPhoneExt',this.phoneExt)
					this.$store.commit('setContactTime',this.contactTime)
					this.$store.commit('setServicesFormContactTime',this.contactTime)
					this.$store.commit('setServicesFormComments', this.comments)
					this.$store.commit('setServicesFormProduct', this.product)
					this.$store.commit('setNavAccountType','teacher')
					if(this.flow=="quote"){
						this.$router.push({name:'QuoteSchoolInfo'})
					} else if(this.flow=="demo"){
						this.$router.push({name:'DemoSchoolInfo'})
					} else {
						this.$router.push({name:'ConferenceSchoolInfo'})
					}
				}
			}
		},
		async created(){
			if(this.profile.email && this.flow != 'conference'){
				this.emailConfirm = this.profile.email
				this.autoFill()
			}
			if(this.flow=='demo'){
				this.$store.commit('setMessageInfo', this.$t('info.demo'))
			}
		}
	}
</script>
